
import { Apple, CardText, EnvelopeAt, EyeSlash, Google, Instagram, Linkedin, Mailbox, PinAngleFill, Telephone } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

//@ts-ignore
import cgfy from './CGFY.png';

const Footer = () => {
    const navigate = useNavigate();

    //-----Pure JSX Functions-----//

    return <div className='border onjumprun-footer'>
        <div className='d-flex justify-content-evenly py-5'>
            <div>
                <h5 className='border-bottom mb-3 pb-2 pe-4'>CONTACT INFO</h5>
                <div className='align-items-center d-flex gap-2 pb-1'>
                    <PinAngleFill className='text-warning' />
                    <span>Bozeman, Montana, USA</span>
                </div>
                <div className='align-items-center d-flex gap-3 pb-1'>
                    <EnvelopeAt className='text-danger' />
                    <span>support@onjumprun.com</span>
                </div>
                <div className='align-items-center d-flex gap-3 pb-1'>
                    <Telephone className='text-pink' />
                    <span>406.426.1091</span>
                </div>
            </div>
            <div>
                <h5 className='border-bottom mb-3 pb-2 pe-4'>MOBILE APPS</h5>
                <div className='align-items-center d-flex gap-3 opacity-50 pb-1'>
                    <Apple className='text-warning' />
                    <span>App Store (coming soon)</span>
                </div>
                <div className='align-items-center d-flex gap-3 opacity-50 pb-1'>
                    <Google className='text-primary' />
                    <span>Google Play (coming soon)</span>
                </div>
            </div>
            <div className='cursor'>
                <h5 className='border-bottom mb-3 pb-2 pe-4'>SITE INFO</h5>
                <div className='align-items-center d-flex gap-3 pb-1' onClick={() => navigate('/contact')}>
                    <Mailbox className='text-success' />
                    <span>Contact Us</span>
                </div>
                <div className='align-items-center d-flex gap-3 pb-1' onClick={() => navigate('/privacypolicy')}>
                    <CardText className='opacity-50 text-pink' />
                    <span>Terms of Use</span>
                </div>
                <div className='align-items-center d-flex gap-3 pb-1' onClick={() => navigate('/privacypolicy')}>
                    <EyeSlash className='opacity-75 text-purple' />
                    <span>Privacy Policy</span>
                </div>
            </div>
            <div>
                <h5 className='border-bottom mb-3 pb-2 pe-4'>SOCIAL MEDIA</h5>
                <div className='align-items-center d-flex gap-3 opacity-50 pb-1'>
                    <Instagram className='opacity-50 text-primary' />
                    <span>Instagram (coming soon)</span>
                </div>
                <div className='align-items-center d-flex gap-3 opacity-50 pb-1'>
                    <Linkedin className='opacity-25 text-warning' />
                    <span>LinkedIn (coming soon)</span>
                </div>
            </div>
            <div className='position-relative' style={{ maxWidth: '10vw' }}>
                <a href='https://en.wikipedia.org/wiki/Freedom_of_speech' target='_blank'>
                    <img alt='Camp Go Fuck Yourself Logo' className='opacity-75 rounded w-100' src={cgfy} />
                </a>
            </div>
        </div>
    </div>;
}
export default Footer;