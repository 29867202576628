import { useContext, useEffect, useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { People, PersonAdd, PlusCircle } from 'react-bootstrap-icons';

import AddTandemReservation from './AddTandemReservation';
import { CountryEnum, ITandemStudentData } from '../models';
import OnJumprunContext from '../context';
import Utilities from '../utils';

const AddNewTandemReservationModal = ({ autoOpen, currentUser, didClickSave, dropzone, setAutoOpen, setSelectedTandemStudent }) => {
    const { darkMode } = useContext(OnJumprunContext);
    const originalReservation: ITandemStudentData = {
        amountPaid: 0,
        balance: 0,
        countryCode: CountryEnum.USA,
        date: new Date(),
        DD: 0,
        MM: 0, 
        YYYY: 0,
        dropzoneId: dropzone?.id, 
        email: '', 
        emergencyContact: '', 
        emergencyCountryCode: CountryEnum.USA,
        emergencyPhone: '',
        firstName: '',
        goggles: false, 
        groupId: '', 
        jumperType: 'Tandem',
        lastName: '',
        paymentOption: 'deposit',
        phone: '',
        stills: false,
        userId: currentUser?.uid,
        video: false,
        waiverDate: '',
        weight: ''
    };
    const utils = new Utilities();

    const [activeInput, setActiveInput] = useState('First name');
    const [autoSave, setAutoSave] = useState(false);
    const [clickedOn, setClickedOn] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [tandemReservation, setTandemReservation] = useState<ITandemStudentData>(originalReservation);

    useEffect(() => {
        if (clickedOn?.findIndex(f => f === activeInput) === -1) setClickedOn([...clickedOn, activeInput]);
    }, [activeInput]);

    useEffect(() => {
        if (autoOpen) setShow(true);
    }, [autoOpen]);

    useEffect(() => {
        if (autoSave && !disableSaveButton()) {
            setAutoSave(false);
            saveClicked();
        }
    }, [autoSave]);

    useEffect(() => {
        if (!show) {
            setSelectedTandemStudent(null);
            setTandemReservation(originalReservation);
            setClickedOn([]);
        }
    }, [show]);

    const cancelClicked = () => {
        setAutoOpen(false);
        setShow(false);
    }

    const didClickOpen = () => {
        setSelectedTandemStudent(null);
        setShow(true);
    }

    const disableSaveButton = () => {
        if (isNaN(tandemReservation?.weight)) return true;
        if (tandemReservation?.emergencyContact?.length <= 4 || utils?.stringHasNumber(tandemReservation?.emergencyContact)) return true;
        if (tandemReservation?.firstName?.length < 3 || tandemReservation?.lastName?.length < 3) return true;
        if (tandemReservation?.emergencyPhone === '' || tandemReservation?.emergencyPhone?.length !== 10) return true;
        if (utils?.checkIfYearIsInvalid(tandemReservation)) return true;
        return false;
    }

    const saveClicked = () => {
        didClickSave(tandemReservation);
        setAutoOpen(false);
        setShow(false);
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        const saveBtnDisabled = disableSaveButton();
        return <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title className='align-items-center d-flex gap-2 mb-0 underline'>
                    <span>Add new tandem student</span>
                    <PersonAdd className={`text-${darkMode ? 'warning' : 'primary'}`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddTandemReservation activeInput={activeInput}
                    clickedOn={clickedOn}
                    dropzone={dropzone}
                    hideRadios={true}
                    newJumperInfo={tandemReservation}
                    numJumpers={1}
                    setActiveInput={setActiveInput}
                    setNewJumperInfo={setTandemReservation} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='fw-light' onClick={() => cancelClicked()} variant={darkMode ? 'dark' : 'light'}>
                    Cancel
                </Button>
                <Button className='align-items-center d-flex fw-medium'
                    disabled={saveBtnDisabled}
                    onClick={() => saveClicked()}
                    variant={saveBtnDisabled ? 'outline-secondary' : darkMode ? 'light' : 'primary'}>
                    {saveBtnDisabled ? 'Missing information' : 'Save tandem information'}<People className='ms-1' />
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    return <div>
        <Button className='align-items-center d-flex fw-bold gap-2' 
            disabled={!dropzone?.id} 
            hidden={autoOpen}
            onClick={() => didClickOpen()}
            variant={darkMode ? 'outline-light' : 'primary'}>
            <span>Add tandem student</span> <PlusCircle />
        </Button>
        {modalJSX()}
    </div>;
}
export default AddNewTandemReservationModal;