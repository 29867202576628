import { useState } from 'react';

import { Button, Card, Collapse, Form } from 'react-bootstrap';
import { CaretDownSquareFill, CaretUpSquareFill, Clipboard2, Clipboard2Check, ListCheck } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { IDropzoneData } from '../models';

interface IOnlineReservationsPricingProps {
    changeDZAndGoToWaiver: (val: IDropzoneData) => void,
    dropzone: IDropzoneData,
    setDropzone: (val: IDropzoneData) => void,
}

const OnlineReservations = ({ changeDZAndGoToWaiver, dropzone, setDropzone }: IOnlineReservationsPricingProps) => {
    const dzLink = `onjumprun.com/${dropzone?.id}/booktandem`;

    const [copyReservationLink, setCopyReservationLink] = useState(false);
    const [copyWaiverLink, setCopyWaiverLink] = useState(false);
    const [show, setShow] = useState(dropzone?.waiver?.dateSigned ? false : true);

    const copyClicked = async (val?) => {
        val ? setCopyWaiverLink(true) : setCopyReservationLink(true);
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(val || dzLink);
        } else {
            document.execCommand('copy', true, val || dzLink);
        }
    }

    //-----Pure JSX Functions-----//

    return dropzone?.id && <Card className={['mb-2', dropzone?.waiver?.dateSigned ? '' : 'border-danger'].join(' ')}>
        <Card.Body className='p-0'>
            <div>
                <div className='align-items-center cursor d-flex justify-content-between p-3' onClick={() => dropzone?.id && setShow(!show)}>
                    <h5 className={['align-items-center d-flex fw-bold gap-2 mb-0', dropzone?.waiver?.dateSigned ? '' : 'text-danger'].join(' ')}>
                        <ListCheck /> Waiver & Reservations
                    </h5>
                    {show ? <CaretUpSquareFill className={dropzone?.waiver?.dateSigned ? '' : 'text-danger'} /> : 
                        <CaretDownSquareFill className={dropzone?.waiver?.dateSigned ? '' : 'shimmer text-danger'} />}
                </div>
                <Collapse in={show}>
                    <div>
                        <div className='px-4'>
                            <div className='align-items-center border-bottom d-flex gap-3 mt-3 pb-2'>
                                <Form.Label as='small' className='mb-0 no-word-wrap'>Dropzone Website:</Form.Label>
                                <Form.Control onChange={e => setDropzone({ ...dropzone, website: e?.target?.value })}
                                    placeholder='www.yourdz.com'
                                    value={dropzone?.website || ''} />
                            </div>
                            <div className='align-items-center d-flex gap-3 mt-2 pb-2'>
                                <Form.Label as='small' className='mb-0 no-word-wrap'>Max # of Tandems per hour:</Form.Label>
                                <Form.Control max={60}
                                    min={1}
                                    onChange={e => setDropzone({ ...dropzone, maxTandemsPerHour: e?.target?.value })}
                                    placeholder='4'
                                    value={dropzone?.maxTandemsPerHour || ''}
                                    type='number'
                                    style={{ maxWidth: '100px' }} />
                            </div>
                            {dropzone?.id && <div className='border-top d-flex justify-content-between'>
                                {dropzone?.waiver?.dateSigned ? <div>
                                    <div className='align-items-center d-flex flex-fill justify-content-between mt-3'>
                                        <small className='mb-0'>
                                            Dropzone waiver link:
                                            <Link target='_blank' to={`../${dropzone?.id}/signwaiver`}>
                                                <small className='fw-bold ps-2' style={{ fontSize: '10px' }}>{`onjumprun.com/${dropzone?.id}/signwaiver`}</small>
                                            </Link>
                                        </small>
                                        {copyWaiverLink ? <div className='ps-2'>
                                            <Clipboard2Check className='cursor text-success' style={{ height: '15px' }} />
                                            <small className='fw-bold underline' style={{ fontSize: '10px' }}>COPIED!</small>
                                        </div> : <div className='ps-2' onClick={() => copyClicked(`onjumprun.com/${dropzone?.id}/signwaiver`)}>
                                            <Clipboard2 className='cursor' style={{ height: '12px' }} />
                                            <small style={{ fontSize: '10px' }}>Copy to clipboard</small>
                                        </div>}
                                    </div>
                                    <div className='align-items-center d-flex flex-fill justify-content-between mb-4 mt-2'>
                                        <small className='mb-0'>
                                            Tandem reservation link:
                                            <Link target='_blank' to={`../${dropzone?.id}/booktandem`}>
                                                <small className='fw-bold ps-2' style={{ fontSize: '10px' }}>{dzLink}</small>
                                            </Link>
                                        </small>
                                        {copyReservationLink ? <div>
                                            <Clipboard2Check className='cursor text-success' style={{ height: '15px' }} />
                                            <small className='fw-bold underline' style={{ fontSize: '10px' }}>COPIED!</small>
                                        </div> : <div className='cursor' onClick={() => copyClicked()}>
                                            <Clipboard2 className='cursor' style={{ height: '12px' }} />
                                            <small style={{ fontSize: '10px' }}>Copy to clipboard</small>
                                        </div>}
                                    </div>
                                </div> : <div className='flex-fill pb-3 pt-2'>
                                    <Button className='w-100' onClick={() => changeDZAndGoToWaiver(dropzone)} variant='danger'>
                                        Complete Your Dropzone's Waiver
                                    </Button>
                                </div>}
                            </div>}
                        </div>
                    </div>
                </Collapse>
            </div>
        </Card.Body>
    </Card>;
}
export default OnlineReservations;