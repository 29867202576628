
import DaysAndHoursOfOperation from './DaysAndHoursOfLocation';
import FunJumperPricing from './FunJumperPricing';
import OnlineReservations from './OnlineReservations';
import SoloStudentPricing from './SoloStudentPricing';
import TandemPricing from './TandemPricing';

const DropzoneControls = ({ changeDZAndGoToWaiver, dropzone, setDropzone }) => {

    //-----Pure JSX Functions-----//

    return <div className='flex-fill mt-2'>
        <DaysAndHoursOfOperation dropzone={dropzone} setDropzone={setDropzone} />
        <FunJumperPricing dropzone={dropzone} setDropzone={setDropzone} />
        <SoloStudentPricing dropzone={dropzone} setDropzone={setDropzone} />
        <TandemPricing dropzone={dropzone} setDropzone={setDropzone} />
        <OnlineReservations changeDZAndGoToWaiver={changeDZAndGoToWaiver} dropzone={dropzone} setDropzone={setDropzone} />
    </div>
}
export default DropzoneControls;