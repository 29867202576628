import { useContext, useState } from 'react';

import { Card, Collapse, Form } from 'react-bootstrap';
import { CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';


import { IDropzoneData } from '../models';
import OnJumprunContext from '../context';
//@ts-ignore
import tandemIcon from './tandyIcon.png';
//@ts-ignore
import whiteTandemIcon from './whiteTandyIcon.png';

interface ITandemPricingProps {
    dropzone: IDropzoneData,
    setDropzone: (val: IDropzoneData) => void,
}

const TandemPricing = ({ dropzone, setDropzone }: ITandemPricingProps) => {
    const { darkMode } = useContext(OnJumprunContext);

    const [show, setShow] = useState(false);

    //-----Pure JSX Functions-----//

    return <Card className='mb-2'>
        <Card.Body className='p-0'>
            <div className='align-items-center cursor d-flex justify-content-between p-3' onClick={() => setShow(!show)}>
                <h5 className='align-items-center d-flex fw-bold gap-2 mb-0'>
                    <img src={darkMode ? whiteTandemIcon : tandemIcon} style={{ height: '2rem' }} />
                    Tandem Jump Pricing
                </h5>
                {show ? <CaretUpSquareFill /> : <CaretDownSquareFill />}
            </div>
            <Collapse in={show}>
                <div>
                    <div className='my-3'>
                        <div className='ps-3'>
                            <div className='align-items-center d-flex justify-content-between px-4'>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0'>Deposit Price</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: {
                                                    ...dropzone?.pricing?.tandems,
                                                    depositPrice: Number(e?.target?.value)
                                                }
                                            }
                                        })}
                                            style={{ width: '100px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.depositPrice || ''} />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0'>Tandem w/ No media</Form.Label>
                                    <div className='align-items-center d-flex gap-1 justify-content-end'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: {
                                                    ...dropzone?.pricing?.tandems,
                                                    tandemPrice: Number(e?.target?.value)
                                                }
                                            }
                                        })}
                                            style={{ width: '100px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.tandemPrice || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className='align-items-center d-flex justify-content-between px-4'>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0 no-word-wrap'>Tandem w/ Still Photos</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, withStills: e?.target?.value }
                                            }
                                        })}
                                            style={{ maxWidth: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.withStills || ''} />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0 no-word-wrap'>Tandem w/ Video</Form.Label>
                                    <div className='align-items-center d-flex gap-1 justify-content-end'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, withVideo: e?.target?.value }
                                            }
                                        })}
                                            style={{ maxWidth: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.withVideo || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className='align-items-center d-flex justify-content-between px-4'>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0 no-word-wrap'>Tandem w/ Stills & Video</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, withStillsAndVideo: e?.target?.value }
                                            }
                                        })} style={{ maxWidth: '95px' }} type='number' value={dropzone?.pricing?.tandems?.withStillsAndVideo || ''} />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0'>Tandem w/ DSP</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, withDSP: e?.target?.value }
                                            }
                                        })}
                                            style={{ maxWidth: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.withDSP || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className='align-items-center d-flex justify-content-between px-4'>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0'>Tandem w/ heavy passenger</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, withHeavyPassenger: e?.target?.value }
                                            }
                                        })}
                                            style={{ maxWidth: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.withHeavyPassenger || ''} />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-medium mb-0'>Goggles</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone, pricing: {
                                                ...dropzone?.pricing,
                                                tandems: { ...dropzone?.pricing?.tandems, goggles: e?.target?.value }
                                            }
                                        })} style={{ width: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.tandems?.goggles || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-center px-4'>
                                <Form.Group className='text-center'>
                                    <Form.Label as='small' className='fw-medium mb-0'>Max Weight (lbs)</Form.Label>
                                    <div className='d-flex justify-content-center'>
                                    <Form.Control onChange={e => setDropzone({
                                        ...dropzone, pricing: {
                                            ...dropzone?.pricing,
                                            tandems: { ...dropzone?.pricing?.tandems, maxWeight: e?.target?.value }
                                        }
                                    })}
                                        placeholder='250lbs'
                                        style={{ width: '95px' }}
                                        type='number'
                                        value={dropzone?.pricing?.tandems?.maxWeight || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </Card.Body>
    </Card>;
}
export default TandemPricing;