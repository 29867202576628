
import { Form } from 'react-bootstrap';

import ApiCalls from '../apiCalls';
import CountryDropdown from './CountryDropdown';

interface IPhoneProps {
    activeInput?: string,
    clickedOn?: any[],
    field: 'phone' | 'emergencyPhone',
    fontLight?: boolean,
    newJumperInfo: any,
    phoneIsTaken: boolean,
    setActiveInput?: (val: string) => void,
    setNewJumperInfo: (val: any) => void,
    setPhoneIsTaken: (val: any) => void
}

const PhoneNumber = ({ activeInput, clickedOn, field, fontLight, newJumperInfo, phoneIsTaken, setActiveInput, setNewJumperInfo, setPhoneIsTaken }: IPhoneProps) => {
    const apiCalls = new ApiCalls();

    const checkIfPhoneNumberIsTaken = async (e) => {
        const data = await apiCalls.checkIfPhoneNumberIsTaken(e);
        setPhoneIsTaken(data);
    }

    //-----Pure JSX Functions-----//

    const mainContentJSX = () => {
        const phone = newJumperInfo[field] || '';
        const phoneIsInvalid = (clickedOn?.findIndex(c => c === (field === 'emergencyPhone' ? 'Emergency Phone' : 'Phone')) > -1 && phone?.length !== 10);
        return <Form.Group>
            <Form.Label as='small'
                className={['align-items-center d-flex gap-2 justify-content-between mb-0', fontLight ? '' : 'fw-bold'].join(' ')}>
                <span className={['fw-bold', phoneIsTaken ? 'text-danger' : ''].join(' ')}>PHONE NUMBER</span>
                {phoneIsTaken && <span className='fw-bold text-danger underline' style={{ fontSize: '12px' }}>TAKEN</span>}
            </Form.Label>
            <div className='d-flex gap-1'>
                <CountryDropdown countryCode={field === 'emergencyPhone' ? newJumperInfo?.emergencyCountryCode : newJumperInfo?.countryCode} 
                    disable={false} 
                    didSelectCountry={e => setNewJumperInfo(field === 'emergencyPhone' ? { ...newJumperInfo, emergencyCountryCode: e } : { ...newJumperInfo, countryCode: e })} />
                <Form.Control className={phoneIsTaken ? 'text-danger' : ''}
                    isInvalid={phoneIsInvalid}
                    isValid={!phoneIsInvalid && clickedOn?.find(c => c === activeInput) && phone?.length === 10}
                    onBlur={(e) => checkIfPhoneNumberIsTaken(e?.target?.value)}
                    onChange={(e) => {
                        let copy = { ...newJumperInfo };
                        copy[field] = e?.target?.value;
                        setNewJumperInfo(copy);
                        phoneIsTaken && setPhoneIsTaken(false);
                    }}
                    onFocus={() => setActiveInput && setActiveInput(field === 'emergencyPhone' ? 'Emergency Phone' : 'Phone')}
                    type='number'
                    value={phone} />
            </div>
        </Form.Group>;
    }

    return mainContentJSX();
}
export default PhoneNumber;