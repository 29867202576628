import { useContext, useState } from 'react';

import { Card, Collapse, Form } from 'react-bootstrap';
import { CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';

import OnJumprunContext from '../context';
import { IDropzoneData } from '../models';
//@ts-ignore
import studentIcon from './studentIcon.png';
//@ts-ignore
import whiteStudentIcon from './whiteStudentIcon.png';

interface ISoloStudentPricingProps {
    dropzone: IDropzoneData,
    setDropzone: (val: IDropzoneData) => void,
}

const SoloStudentPricing = ({ dropzone, setDropzone }: ISoloStudentPricingProps) => {
    const { darkMode } = useContext(OnJumprunContext);

    const [show, setShow] = useState(false);

    //-----Pure JSX Functions-----//

    return <Card className='mb-2'>
        <Card.Body className='p-0'>
            <div className='align-items-center cursor d-flex justify-content-between p-3' onClick={() => setShow(!show)}>
                <h5 className='align-items-center d-flex fw-bold gap-2 mb-0'>
                    <img src={darkMode ? whiteStudentIcon : studentIcon} style={{ height: '1.3rem' }} />
                    Student Jump Pricing
                </h5>
                {show ? <CaretUpSquareFill /> : <CaretDownSquareFill />}
            </div>
            <Collapse in={show}>
                <div>
                    <div className='d-flex gap-5 justify-content-center pb-4 px-3'>
                        <Form.Group className='text-end'>
                            <Form.Label as='small' className='fw-medium mb-0'>AFF</Form.Label>
                            <div className='align-items-center d-flex gap-1'>
                                <span className='fw-medium opacity-25'>$</span>
                                <Form.Control onChange={e => setDropzone({
                                    ...dropzone,
                                    pricing: {
                                        ...dropzone?.pricing,
                                        students: { ...dropzone?.pricing?.students, aff: e?.target?.value }
                                    }
                                })}
                                    style={{ width: '95px' }} type='number' placeholder='288' value={dropzone?.pricing?.students?.aff || ''} />
                            </div>
                        </Form.Group>
                        <Form.Group className='text-end'>
                            <Form.Label as='small' className='fw-medium mb-0'>IAD</Form.Label>
                            <div className='align-items-center d-flex gap-1'>
                                <span className='fw-medium opacity-25'>$</span>
                                <Form.Control onChange={e => setDropzone({
                                    ...dropzone,
                                    pricing: {
                                        ...dropzone?.pricing,
                                        students: { ...dropzone?.pricing?.students, iad: e?.target?.value }
                                    }
                                })}
                                    style={{ width: '95px' }} type='number' placeholder='149' value={dropzone?.pricing?.students?.iad || ''} />
                            </div>
                        </Form.Group>
                        <Form.Group className='text-end'>
                            <Form.Label as='small' className='fw-medium mb-0'>Supervised Solo</Form.Label>
                            <div className='align-items-center d-flex gap-1'>
                                <span className='fw-medium opacity-25'>$</span>
                                <Form.Control onChange={e => setDropzone({
                                    ...dropzone,
                                    pricing: {
                                        ...dropzone?.pricing,
                                        students: { ...dropzone?.pricing?.students, supervisedSolo: e?.target?.value }
                                    }
                                })}
                                    style={{ width: '95px' }} type='number' placeholder='33' value={dropzone?.pricing?.students?.supervisedSolo || ''} />
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </Collapse>
        </Card.Body>
    </Card>;
}
export default SoloStudentPricing;