import React from 'react';

const OnJumprunContext = React.createContext({
    darkMode: false,
    setDarkMode: (val) => {},
    dropzone: null,
    setDropzone: (val) => {},
    showNotificationModal: null,
    activeTab: '',
    setActiveTab: (val) => {},
    setShowNotificationModal: (val) => {}
});
export default OnJumprunContext;