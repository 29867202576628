import moment from 'moment';

enum CountryEnum {
    AUSTRALIA = '+61',
    BELGIUM = '+32',
    CANADA = '+1',
    DENMARK = '+45',
    MEXICO = '+52',
    NETHERLANDS = '+31',
    NORWAY = '+47',
    SPAIN = '+34',
    SWEDEN = '+46',
    UK = '+44',
    USA = '+1'
}

class IAircraftData {
    active: boolean;
    aircraftType?: string;
    dateCreated?: Date;
    dropzoneId: string;
    id?: string;
    maxNumJumpers?: number;
    minNumJumpers: number;
    nickName: string;
    tailNumber?: string;
}

class IDropzoneData {
    dateCreated?: Date;
    employees?: IEmployeeData[];
    gogglesPrice?: number;
    hoursOfOperation: {
        closingDay: string,
        from: string,
        openingDay: string,
        to: string,
        Monday: boolean,
        Tuesday: boolean,
        Wednesday: boolean,
        Thursday: boolean,
        Friday: boolean,
        Saturday: boolean,
        Sunday: boolean
    };
    id?: string;
    location?: {
        addressArray: any;
        addressString: any;
        lat: any;
        long: any;
    } | any;
    lowercaseName?: string;
    maxTandemsPerHour: number | string;
    name?: string;
    ownerUID: string;
    pricing: {
        funJumpers: {
            coachJump: number | string;
            fullAltitude: number | string;
            hopNPop: number | string;
            withGearRental: number | string;
        };
        students: {
            aff: number | string;
            hopNPop: number | string;
            iad: number | string;
            supervisedSolo: number | string;
        };
        tandems: {
            depositPrice: number;
            goggles:  number | string;
            maxWeight:  number | string;
            tandemPrice:  number;
            withDSP:  number | string;
            withHeavyPassenger:  number | string;
            withStills:  number | string;
            withStillsAndVideo:  number | string;
            withVideo:  number | string;
        };
    }
    phone: string;
    website: string;
    waiver?: IDZOWaiverData;

    constructor() {
        this.dateCreated = new Date();
        this.employees = [];
        this.gogglesPrice = 0;
        this.id = null;
        this.maxTandemsPerHour = 4;
        this.name = '';

        this.hoursOfOperation = {
            closingDay: moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD'),
            from: '8am',
            openingDay: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
            to: '3pm',

            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: true,
            Saturday: true,
            Sunday: true
        }
        this.ownerUID = '';
        this.location = '';
        this.pricing = {
            tandems: {
                depositPrice: 80,
                goggles: 10, tandemPrice: 299,
                maxWeight: 250,
                withDSP: 379,
                withHeavyPassenger: 379,
                withStills: 349,
                withStillsAndVideo: 429,
                withVideo: 379
            },
            students: {
                aff: 249,
                hopNPop: 229,
                iad: 229,
                supervisedSolo: 129,
            },
            funJumpers: {
                coachJump: 65,
                fullAltitude: 33,
                hopNPop: 25,
                withGearRental: 75
            }
        };
        this.phone = '';
        this.waiver = {
            dateSigned: null,
            firstSignature: '',
            secondSignature: '',
            thirdSignature: '',
            fourthSignature: ''
        };
        this.website = '';
    }
}

class IDZOWaiverData {
    dateSigned: Date;
    firstSignature: string;
    secondSignature: string;
    thirdSignature: string;
    fourthSignature: string;
    constructor() {
        this.dateSigned = new Date();
        this.firstSignature = '';
        this.secondSignature = '';
        this.thirdSignature = '';
        this.fourthSignature = '';
    }
}

class IJumperData {
    countryCode: CountryEnum;
    DD: number;
    dropzoneId: string;
    email: string;
    emergencyContact: string;
    emergencyCountryCode: CountryEnum.USA;
    emergencyPhone: string;
    firstName: string;
    flagged?: boolean;
    id?: string;
    jumperType?: string;
    lastName: string;
    MM: number;
    phone?: string;
    uid: string;
    waiverDate: string;
    weight?: number;
    YYYY: number;
}

class ILoadData {
    aircraft: any;
    aircraftId: string;
    call: number;
    date?: Date;
    dateCreated?: Date;
    dropzoneId: string;
    fuelLoad: boolean;
    id?: string;
    loadSheet: (ISoloJumperData | ITandemStudentData)[];
    name: string;
    status: 'Departed' | 'Building';
}

class IPilotData {
    active: boolean;
    dateAdded: Date;
    dropzoneId: string
    licenseNumber: string;
    name: string;
    weight: number;
}

class ITandemStudentData {
    active?: true;
    amountPaid: number;
    balance: number;
    countryCode: CountryEnum;
    date: any;
    dateCreated?: Date;
    DD: number;
    dropzoneId: string;
    email: string;
    emailSent?: boolean;
    emergencyContact: string;
    emergencyCountryCode: CountryEnum.USA;
    emergencyPhone: string;
    firstName: string;
    groupId?: string;
    goggles?: boolean;
    id?: string;
    jumperType?: string;
    lastName: string;
    MM: number;
    paid?: boolean;
    paymentOption: null | 'deposit' | 'full';
    phone: string;
    stills?: boolean;
    userId?: number;
    video?: boolean;
    videos?: boolean;
    waiverDate?: string;
    weight: any;
    YYYY: number;
    constructor(dzId) {
        this.countryCode = CountryEnum.USA;
        this.dropzoneId = dzId;
        this.emergencyContact = '';
        this.emergencyCountryCode = CountryEnum.USA;
        this.emergencyPhone = '';
        this.firstName = '';
        this.id = '';
        this.lastName = '';
        this.phone = '';
        this.goggles = false;
        this.stills = false;
        this.video = false;
        this.amountPaid = 0;
        this.waiverDate = '';
        this.weight = 0;
    }
}

class ISoloJumperData extends IJumperData {
    licenses?: string;
    numberOfJumpsThisYear?: number;
    reserveCardCheckedBy?: string;
    reserveRepackDate?: any;
    totalJumps?: number;
    uspaExp?: any;
    uspaNumber?: number;
    wingLoading: string;
    yearsInSport?: string
    constructor(dzId) {
        super();
        this.countryCode = CountryEnum.USA;
        this.emergencyCountryCode = CountryEnum.USA;
        this.dropzoneId = dzId;
        this.emergencyContact = '';
        this.emergencyPhone = '';
        this.firstName = '';
        this.id = '';
        this.lastName = '';
        this.licenses = '';
        this.numberOfJumpsThisYear = 0;
        this.phone = '';
        this.reserveCardCheckedBy = '';
        this.reserveRepackDate = '';
        this.totalJumps = 0;
        this.uid = '';
        this.uspaExp = '';
        this.uspaNumber = 0;
        this.waiverDate = '';
        this.weight = 0;
        this.wingLoading = '';
        this.yearsInSport = '';
    }
}

class IEmployeeData extends ISoloJumperData {
    active: boolean;
    hasTandemRating?: boolean;
    isSkydiver: boolean;
    jobTitle: string;
    tandemExpirationDate?: any;
    tandemLicenseNumber?: string;
}

class ISignWaiverData {
    AAD?: string;
    addressLine1: string;
    addressLine2?: string;
    applicableLaw: string;
    acknowledgement: string;
    agreementNotToSue: string;
    assumptionOfRisk: string;
    binding: string;
    city: string;
    confirmEmail: string;
    container?: string;
    continuation: string;
    dropzoneId: string;
    dateSigned: any;
    DD: number;
    email: string;
    emergencyAddress: string;
    emergencyCity: string;
    emergencyContactName: string;
    emergencyContactPhone: number;
    emergencyState: string;
    emergencyZip: number;
    entire: string;
    equipment: string;
    fieldElevation: string;
    firstName: '';
    id?: string;
    indemnity: string;
    lastName: '';
    limitation: string;
    llc: string;
    mainCanopy?: string;
    MM: number;
    noRefunds: string;
    phone: '';
    photoVideo: string;
    physicalCondition: string;
    relationshipToYou: string;
    releaseOfLiability: string;
    reserveCanopy?: string;
    reserveRepackDate?: string;
    reserveWingLoading?: string;
    rightToRefuse: string;
    severability: string;
    state: string;
    weight: number;
    weightWithGear: number;
    wingLoading?: string;
    YYYY: number;
    zip: number;
    constructor(dzId: string) {
        this.dropzoneId = dzId;
        this.id = '';
        this.dateSigned = new Date();
        this.releaseOfLiability = '';
        this.llc = '';
        this.assumptionOfRisk = '';
        this.agreementNotToSue = '';
        this.indemnity = '';
        this.applicableLaw = '';
        this.binding = '';
        this.limitation = '';
        this.severability = '';
        this.continuation = '';
        this.acknowledgement = '';
        this.physicalCondition = '';
        this.entire = '';
        this.photoVideo = '';
        this.equipment = '';
        this.noRefunds = '';
        this.rightToRefuse = '';
        this.fieldElevation = '';
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.city = '';
        this.state = '';
        this.email = '';
        this.emergencyAddress = '';
        this.confirmEmail = '';
        this.emergencyContactName = '';
        this.relationshipToYou = '';
        this.mainCanopy = '';
        this.AAD = '';
        this.reserveRepackDate = '';
        this.reserveCanopy = '';
        this.reserveWingLoading = '';
        this.wingLoading = '';
        this.container = '';
    }
}

class ISoloPaymentData {
    active?: boolean;
    date: Date;
    dropzoneId: string;
    firstName: string;
    id?: string;
    lastName: string;
    originalAmount: number;
    phone: number;
    userId: string;
    submittedBy: string;
    withdrawels: IWithdrawelData[]
}

class IWithdrawelData {
    amountWithdrawn: number;
    loadId: string;
    paymentDate: Date;
    submittedBy: string;
}

export {
    CountryEnum,
    IAircraftData,
    IDropzoneData,
    IDZOWaiverData,
    IEmployeeData,
    IJumperData,
    ILoadData,
    IPilotData,
    ISignWaiverData,
    ISoloJumperData,
    ISoloPaymentData,
    ITandemStudentData,
    IWithdrawelData
};