import { useContext, useEffect, useState } from 'react';

import { AirplaneEngines, PlusCircle } from 'react-bootstrap-icons';
import { Button, Form, Modal } from 'react-bootstrap';

import OnJumprunContext from '../context';

const AddNewAircraftModal = ({ autoOpen, didSaveAircraft, didUpdateAircraftInfo, dropzone, selectedAircraft, setAutoOpen, setSelectedAircraft }) => {
    const { darkMode } = useContext(OnJumprunContext);

    const [aircraftType, setAircraftType] = useState('');
    const [maxNumJumpers, setMaxNumJumpers] = useState(0);
    const [minNumJumpers, setMinNumJumpers] = useState(0);
    const [nickName, setNickName] = useState('');
    const [show, setShow] = useState(false);
    const [tailNumber, setTailNumber] = useState('');

    useEffect(() => {
        if(autoOpen) {
            setAircraftType(selectedAircraft?.aircraftType || '');
            setMaxNumJumpers(selectedAircraft?.maxNumJumpers || '');
            setMinNumJumpers(selectedAircraft?.minNumJumpers || '');
            setNickName(selectedAircraft?.nickName || '');
            setTailNumber(selectedAircraft?.tailNumber || '');
            setShow(true);
        }
    }, [autoOpen]);

    useEffect(() => {
        if(!show) {
            setMaxNumJumpers(0);
            setAircraftType('');
            setMinNumJumpers(0);
            setTailNumber('');
            setNickName('');
            setSelectedAircraft(null);
        }
    }, [show]);

    const disableSaveButton = () => {
        if(aircraftType === '') return true;
        if(tailNumber === '') return true;
        return false
    }

    const saveClicked = (isActive) => {
        if(selectedAircraft?.id) {
            didUpdateAircraftInfo({ aircraftType, minNumJumpers, maxNumJumpers, tailNumber, id: selectedAircraft?.id, nickName, active: isActive !== undefined ? isActive : true });
        } else {
            didSaveAircraft({ aircraftType, minNumJumpers, maxNumJumpers, tailNumber, nickName });
        }
        setAutoOpen(false);
        setShow(false);
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        const saveBtnDisabled = disableSaveButton();
        return <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title className='align-items-center d-flex gap-2 mb-0'>
                    {selectedAircraft ? <span>Edit aircraft information</span> : <span>Add new aircraft</span>}
                    <AirplaneEngines />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='px-3'>
                    <div className='d-flex gap-3 pb-2'>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' autoFocus className='fw-medium mb-2'>Aircraft Type</Form.Label>
                                <Form.Control onChange={(e) => setAircraftType(e.target.value)} value={aircraftType} />
                            </Form.Group>
                        </div>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>Tail Number</Form.Label>
                                <Form.Control onChange={(e) => setTailNumber(e.target.value)} value={tailNumber} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='d-flex gap-2 pb-2'>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>Nickname</Form.Label>
                                <Form.Control onChange={(e) => setNickName(e.target.value)} placeholder='Optional...' value={nickName} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>Min # jumpers</Form.Label>
                                <Form.Control isInvalid={maxNumJumpers > 0 && minNumJumpers > maxNumJumpers} min='0' onChange={(e) => setMinNumJumpers(e.target.value)}
                                    style={{ width: '100px' }} type='number'  value={minNumJumpers} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>Max # jumpers</Form.Label>
                                <Form.Control isInvalid={maxNumJumpers > 0 && minNumJumpers > maxNumJumpers}
                                    min={minNumJumpers || 0}
                                    onChange={(e) => setMaxNumJumpers(e.target.value)}
                                    style={{ width: '100px' }} 
                                    type='number' 
                                    value={maxNumJumpers} />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className='p-2'>
                <div className='align-items-center d-flex gap-1 justify-content-between w-100'>
                    <div>
                        <Button hidden={!selectedAircraft?.id} onClick={() => saveClicked(false)} size='sm' variant='danger'>
                            Delete aircraft
                        </Button>
                    </div>
                    <div className='d-flex gap-1'>
                        <div>
                            <Button className='fw-light' onClick={() => setShow(false)} size='sm' variant={darkMode ? 'dark' : 'light'}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button className='align-items-center d-flex fw-medium' 
                                disabled={saveBtnDisabled}
                                onClick={() => saveClicked()}
                                size='sm'
                                variant={saveBtnDisabled ? 'danger' : darkMode ? 'light' : 'primary'}>
                                Save aircraft<PlusCircle className='ms-1' />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }

    return <>
        <Button className='align-items-center d-flex fw-bold gap-2'
            disabled={!dropzone?.id} onClick={() => setShow(true)}
            variant={darkMode ? 'outline-light' : 'primary'}>
            <span>Add aircraft</span>
            <PlusCircle />
        </Button>
        {modalJSX()}
    </>;
}
export default AddNewAircraftModal;