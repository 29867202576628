import { useContext, useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

import CountryDropdown from './CountryDropdown';
import { CountryEnum } from '../models'
import OnJumprunContext from '../context';
import Logo from './Logo';
//@ts-ignore
import whiteSoloIcon from './whiteSoloIcon.png';
import Footer from './Footer';

const LoginPage = ({ setCurrentUser }) => {
    const auth = getAuth();
    const { darkMode } = useContext(OnJumprunContext);
    const navigate = useNavigate();

    const [activeType, setActiveType] = useState(0);
    const [confirmationCode, setConfirmationCode] = useState<any>('');
    const [countryCode, setCountryCode] = useState<CountryEnum>(CountryEnum.USA);
    const [didSendConfirmationCode, setDidSendConfirmationCode] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [show, setShow] = useState(false);
    const [validPhone, setValidPhone] = useState<any>(false);

    auth?.onAuthStateChanged(u => {
        if (u) {
            setCurrentUser(u);
            navigate('/onjumprun');
        }
    });

    useEffect(() => {
        document?.documentElement?.setAttribute('data-bs-theme', 'dark');
    }, []);

    useEffect(() => {
        if (phone?.length === 10) setValidPhone(isNaN(phone.replace(' ', '') as any) ? false : true);
        if (show) setShow(false);
    }, [phone]);

    const confirmCode = (e) => {
        setIsLoading(true);
        e.preventDefault();
        (window as any)?.confirmationResult?.confirm(confirmationCode).then(() => null).catch(() => setIsLoading(false));
    }

    const doLogin = () => {
        if (didSendConfirmationCode) {
            setActiveType(1);
            return;
        }
        setIsLoading(true);
        setDidSendConfirmationCode(true);
        try {
            //@ts-ignore
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible', 'callback': (r) => { sendPhoneConfirmation(phone) },
                'expired-callback': () => { console.error('Login failed. Your dad doesnt love you wah wah wah.') }
            });
        } catch (e) {
            //console.log('error was', e)
        }
        sendPhoneConfirmation(phone);
    }

    const isntValidLoginCode = () => {
        let err = true;
        if ((confirmationCode.length < 6) || (confirmationCode.length === 6 && !isNaN(confirmationCode))) err = false;
        return err
    }

    const sendPhoneConfirmation = async (phone) => {
        if (didSendConfirmationCode) {
            setActiveType(1);
            return;
        }
        const appVerifier = (window as any).recaptchaVerifier;
        await signInWithPhoneNumber(auth, `${countryCode}${phone}`, appVerifier).then((confirmationResult) => {
            (window as any).confirmationResult = confirmationResult;
            setActiveType(1);
            setIsLoading(false);
            setError(null);
        }).catch(e => {
            setError(`Error logging in`)
            setIsLoading(false);
        });
    }

    //-----Pure JSX Functions-----//

    const generateContentJSX = () => {
        if (activeType === 1) {
            return <div>
                <Card>
                    <Card.Body>
                        <div className='d-flex gap-4 px-1'>
                            <div className='px-3'>
                                <Logo />
                            </div>
                            <Form onSubmit={confirmCode}>
                                <Form.Group className='mt-3'>
                                    <Form.Label className='mb-0'>
                                        {!isntValidLoginCode() ? 'Confirmation Code' : <span style={{ color: 'red' }}>That confirmation code is {confirmationCode.length === 6 ? 'garbage' : 'too long'}</span>}
                                    </Form.Label>
                                    <Form.Control autoFocus className='w-100' onChange={(e) => setConfirmationCode(e.target.value)} placeholder='X X X X X X' value={confirmationCode} />
                                </Form.Group>
                                <div className='d-flex gap-2 pt-2'>
                                    <Button className='flex-fill' onClick={() => setActiveType(0)} variant='outline-secondary'>Back</Button>
                                    <Button className='flex-fill fw-bold' disabled={isLoading || isntValidLoginCode() || confirmationCode.length !== 6} type='submit' variant={isntValidLoginCode() ? 'danger' : 'primary'}>
                                        {isntValidLoginCode() ? 'Invalid credentials...' : isLoading ? <Spinner animation='border' size='sm' /> : 'Login'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </div>;
        }
        return <div>
            <Card>
                <Card.Body>
                    <div className='px-2 py-1'>
                        <div className='align-items-center d-flex gap-4 justify-content-between ps-2'>
                            <Logo />
                            <div>
                                <div className='align-items-end d-flex gap-1'>
                                    <Form.Group>
                                        <Form.Label className='fw-medium opacity-75'>Register/Login</Form.Label>
                                        <div className='d-flex gap-1'>
                                            <CountryDropdown countryCode={countryCode}
                                                didSelectCountry={e => setCountryCode(e)}
                                                disable={isLoading} />
                                            <Form.Control autoFocus
                                                className='w-100'
                                                disabled={isLoading}
                                                isInvalid={phone?.length === 14 && !validPhone}
                                                onChange={(e) => setPhone(e?.target?.value)}
                                                onKeyUp={e => e?.key === 'Enter' && doLogin()}
                                                placeholder='Enter phone number'
                                                value={phone} />
                                        </div>
                                    </Form.Group>
                                </div>
                                {error && <div className='fadeIn text-end'>
                                    <span style={{ fontSize: '12px' }} className='fw-bold text-danger'>{error}</span>
                                </div>}
                                <div className='pt-2 d-flex gap-1'>
                                    <Button className='opacity-75'
                                        hidden={isLoading}
                                        onClick={() => navigate('/')}
                                        type='submit'
                                        variant='outline-secondary'>
                                        Back
                                    </Button>
                                    <Button className={['align-items-center d-flex flex-fill fw-bold justify-content-center ml-1', validPhone ? '' : 'opacity-50'].join(' ')}
                                        disabled={!validPhone}
                                        onClick={() => doLogin()}
                                        type='submit'
                                        variant={darkMode ? 'outline-danger' : validPhone ? 'primary' : 'outline-secondary'}>
                                        {isLoading ? <Spinner animation='border' size='sm' /> : 'Send login code'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>;
    }

    return <>
        <div className='align-items-center aTagNoDecoration border-bottom d-flex gap-4 justify-content-between px-4 py-2'>
            <div>
                <img className='cursor' onClick={() => navigate('/')} src={whiteSoloIcon} style={{ height: '36px' }} />
            </div>
            <div className='d-flex gap-4 pe-3'>
                <Link to='/'>
                    <small className='cursor fw-bold opacity-50 text-light whiteOnHover'>HOME</small>
                </Link>
                <Link to='/login'>
                    <small className='cursor fw-bold shimmer text-light underline'>USE THE APP</small>
                </Link>
                <Link to='/contact'>
                    <small className='cursor fw-bold opacity-50 text-light whiteOnHover'>CONTACT</small>
                </Link>
            </div>
        </div>
        <div>
            <div id='recaptcha-container' />
            <Row className='fadeIn' style={{ height: 'calc(97vh - 53px)' }}>
                <Col className='align-items-center d-flex h-100 justify-content-center'>
                    {generateContentJSX()}
                </Col>
            </Row>
            <Footer />
        </div>
    </>
};
export default LoginPage;
