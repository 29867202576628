import { useContext, useEffect, useRef, useState } from 'react';

import {
    AirplaneEngines, ArrowDownCircle, ArrowLeftSquareFill, ArrowRightSquareFill, BuildingAdd, Calendar3, CheckCircleFill, CurrencyDollar,
    EyeFill, FileLock, FlagFill, GearFill, Heart, HeartFill, LockFill, MoonStarsFill, PeopleFill, PlusCircle,
    RocketTakeoff, RocketTakeoffFill, SunFill, XCircleFill
} from 'react-bootstrap-icons';
import { Badge, Button, Card, Col, Collapse, Row, Spinner, Table, Tab, Tabs } from 'react-bootstrap';
import Calendar from './Calendar';
import { Timestamp } from 'firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Account from './Account';
import AddNewAircraftModal from './AddNewAircraft';
import AddNewJumperModal from './AddNewJumperModal';
import AddNewPilotModal from './AddNewPilot';
import AddNewTandemModal from './AddNewTandemReservationModal';
import ApiCalls from '../apiCalls';
import Dropzones from './Dropzones';
import Employees from './Employees';
import {
    CountryEnum,
    IAircraftData, IDropzoneData, IEmployeeData, ILoadData, IPilotData, ISignWaiverData,
    ISoloJumperData, ISoloPaymentData, ITandemStudentData, IWithdrawelData
} from '../models';
import Load from './Load';
import Logo from './Logo';
import Notification from './Notification';
import OnJumprunContext from '../context';
import SideBar from './SideBar';
import Utilities from '../utils';
import Waiver from './Waiver';

const HomePage = ({ auth, blockMobile, currentUser, darkModeClicked, didClickLogout, dropzones, reset, setCurrentUser, setRefetchDropzones, setToastMessage, toastMessage }) => {
    const apiCalls = new ApiCalls();
    const bottomRef: any = useRef();
    const { activeTab, darkMode, dropzone, setActiveTab, setDarkMode, setDropzone, setShowNotificationModal, showNotificationModal } = useContext(OnJumprunContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let subscriptionCancel: any = searchParams?.get('subscriptionCancel');
    let subscriptionNumMonths: any = searchParams?.get('subscriptionNumMonths');
    let subscriptionPurchased: any = searchParams.get('subscriptionPurchased');
    const today = new Date();
    const topRef: any = useRef();
    const utils = new Utilities();

    const [aircraft, setAircraft] = useState<any>([]);
    const [autoOpenAircraftModal, setAutoOpenAircraftModal] = useState(false);
    const [autoOpenJumperModal, setAutoOpenJumperModal] = useState(false);
    const [autoOpenPilotModal, setAutoOpenPilotModal] = useState(false);
    const [autoOpenTandemModal, setAutoOpenTandemModal] = useState(false);
    const [disable, setDisable] = useState(false);
    const [dropzoneWaivers, setDropzoneWaivers] = useState<ISignWaiverData[]>([]);
    const [employees, setEmployees] = useState<IEmployeeData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [jumpers, setJumpers] = useState<ISoloJumperData[]>([]);
    const [manifestedLoads, setManifestedLoads] = useState<ILoadData[]>([]);
    const [monthOnLeftCalendar, setMonthOnLeftCalendar] = useState(today.getMonth());
    const [newSubscription, setNewSubscription] = useState<any>(null);
    const [originalLoads, setOriginalLoads] = useState<any>([]);
    const [payments, setPayments] = useState<ISoloPaymentData[]>();
    const [pilots, setPilots] = useState<any>([]);
    const [refetchData, setRefetchData] = useState(false);
    const [refetchEmployees, setRefetchEmployees] = useState(false);
    const [refetchPayments, setRefetchPayments] = useState(false);
    const [reservations, setReservations] = useState<ITandemStudentData[]>([]);
    const [selectedAircraft, setSelectedAircraft] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedJumper, setSelectedJumper] = useState<ISoloJumperData>(new ISoloJumperData(dropzone?.id));
    const [selectedPilot, setSelectedPilot] = useState(false);
    const [selectedTandemStudent, setSelectedTandemStudent] = useState<ITandemStudentData>(new ITandemStudentData(dropzone?.id));
    const [showCalendar, setShowCalendar] = useState(false);
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [soloJumperType, setSoloJumperType] = useState<'licensed' | 'student' | 'inactive'>('licensed');
    const [tandemReservationType, setTandemReservationType] = useState<'upcoming' | 'completed' | 'walkin'>('upcoming');
    const [thisMonthsStartDate, setThisMonthsStartDate] = useState(today);
    const [yearOnLeftCalendar, setYearOnLeftCalendar] = useState(today.getFullYear());

    auth?.onAuthStateChanged(u => {
        if (!u) navigate('/');
        else if (u && !currentUser?.uid) setCurrentUser(u);
    });

    useEffect(() => {
        document?.documentElement?.setAttribute('data-bs-theme', blockMobile ? 'dark' : 'light');
        setDarkMode(blockMobile ? true : false);
    }, [blockMobile]);

    useEffect(() => {
        if (activeTab !== 'Daily Manifest') {
            setDisable(false);
            setShowCalendar(false);
        }
        if (activeTab !== 'Aircraft') setAutoOpenAircraftModal(false);
        if (activeTab === 'Daily Manifest' || activeTab === 'Solo Jumpers' || activeTab === 'Tandem Reservations' || activeTab === 'Dropzone Information' || activeTab === 'Aircraft') {
            setSelectedTandemStudent(null);
            setSelectedJumper(null);
        }
        if (activeTab === 'Tandem Reservations' && dropzone?.id) {
            getReservationsFromAPI();
            setTandemReservationType('upcoming');
        }
        if (activeTab === 'Solo Jumpers' && dropzone?.id) {
            getJumpersFromApi();
            setSoloJumperType('licensed');
        }
    }, [activeTab]);

    useEffect(() => {
        if (subscriptionCancel || subscriptionPurchased) setActiveTab('Account');
        if (currentUser) getSubscription();
    }, [currentUser]);

    useEffect(() => {
        if (disable && manifestedLoads?.length > 5) bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [disable, manifestedLoads]);

    useEffect(() => {
        if (dropzone?.id) getDropzoneData();
    }, [dropzone]);

    useEffect(() => {
        if (refetchData) {
            setRefetchData(false);
            setAutoOpenAircraftModal(false);
            setAutoOpenPilotModal(false);
            setSelectedAircraft(null);
            setSelectedJumper(null);
            getDropzoneData();
        }
    }, [refetchData]);

    useEffect(() => {
        if (refetchEmployees) {
            setRefetchEmployees(false);
            getEmployeesFromAPI();
        }
    }, [refetchEmployees]);

    useEffect(() => {
        if (refetchPayments) {
            setRefetchPayments(false);
            getPaymentsFromAPI();
        }
    }, [refetchPayments]);

    useEffect(() => {
        if (!selectedAircraft) setAutoOpenAircraftModal(false);
    }, [selectedAircraft]);

    useEffect(() => {
        if (selectedDate && dropzone) {
            getLoadsByDropzoneId();
            topRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!selectedPilot) setAutoOpenPilotModal(false);
    }, [selectedPilot]);

    useEffect(() => {
        setSelectedJumper(new ISoloJumperData(dropzone?.id));
    }, [soloJumperType]);

    useEffect(() => {
        if (reset) {
            setDropzone(new IDropzoneData());
            setActiveTab('Daily Manifest');
        }
    }, [reset]);

    const addJumperToLoad = async (load: any, newJumper, jumpType) => {
        let jumpPrice = dropzone?.pricing?.funJumpers[jumpType];
        if (newJumper?.jumperType === 'Student') jumpPrice = dropzone?.pricing?.students[jumpType];
        else if (newJumper?.jumperType === 'Tandem') jumpPrice = dropzone?.pricing?.tandems[jumpType];
        if (newJumper?.firstName !== 'Slot filler' && isNaN(jumpPrice)) return;
        
        const arr: ILoadData[] = [];
        manifestedLoads?.forEach(f => f?.name === load?.name ? arr.push({ ...f, loadSheet: load?.loadSheet?.length > 0 ? [...load?.loadSheet] : [] }) : arr.push(f));
        setManifestedLoads(arr);
        newJumper?.firstName !== 'Slot Filler' && await payForJump(load, newJumper, jumpPrice);
        await updateLoadSheetAndStatus(load, 'Building');
        setToastMessage({ message: 'Jumper added to load.', variant: 'success' });
        setRefetchData(true);
    }

    const calculateRowColor = (type) => {
        if (type === 'Student') return 'red-row';
        return '';
    }

    const changeDZAndGoToWaiver = (dz) => {
        setDropzone(dz);
        setActiveTab('Waiver');
    }

    const clickAddLoad = () => {
        setDisable(true);
        const newLoad: ILoadData = {
            aircraft,
            aircraftId: aircraft?.id,
            call: 0,
            date: null,
            dropzoneId: dropzone?.id,
            fuelLoad: false,
            loadSheet: [],
            name: `Load ${manifestedLoads?.length + 1}`,
            status: 'Building'
        }
        setManifestedLoads([...manifestedLoads, newLoad]);
    }

    const clickSaveLoad = (load, call, newAircraftData) => {
        setIsLoading(true);
        saveLoad(load, call, newAircraftData);
        setToastMessage({ message: 'Load added to manifest.', variant: 'success' });
        setRefetchData(true);
    }

    const deleteJumperOrTandem = async (load, j, i, instructorName, studentName) => {
        unpayForJump(load);
        const loadSheet: ILoadData[] = [];
        manifestedLoads?.forEach(f => {
            load?.name === f?.name && f?.loadSheet?.forEach((l, ii) => {
                if ((i !== ii) && (!instructorName || instructorName !== (l?.firstName + ' ' + l?.lastName)) && (!studentName || studentName !== (l?.firstName + ' ' + l?.lastName))) {
                    loadSheet.push(l as any);
                }
            });
        });
        await updateLoadSheetAndStatus({ ...load, loadSheet }, 'Building');
        setToastMessage({ message: `${(instructorName || studentName) ? 'Tandem removed from load' : `${j?.firstName} ${j?.lastName}`} removed from the load.`, variant: 'danger' });
        setRefetchData(true);
    }

    const deleteLoad = async (load) => {
        await updateLoadSheetAndStatus(load, 'Deleted');
        setToastMessage({ message: `${load?.name} deleted successfully.`, variant: 'danger' });
        setRefetchData(true);
    }

    const didUpdateAircraftInfo = async (data) => {
        await apiCalls.updateAircraft(data?.id, data);
        setToastMessage({ message: 'AIRCRAFT INFORMATION SAVED', variant: 'success' });
        setRefetchData(true);
    }

    const didUpdateJumperInfo = async (data) => {
        if (data?.jumperType === 'Tandem') {
            await apiCalls.updateTandemReservation(data);
            setTimeout(() => getReservationsFromAPI(), 222);
            setToastMessage({ message: 'TANDEM INFORMATION UPDATED', variant: 'success' });
        } else {
            const newJumperObject = {
                ...data,
                reserveRepackDate: data?.reserveRepackDate ? utils?.formatMMDDYYYY(data.reserveRepackDate) : '',
                uspaExp: data?.uspaExp ? utils?.formatMMDDYYYY(data.uspaExp) : '',
            };
            await apiCalls.updateJumper(data?.id, newJumperObject);
            setTimeout(() => getJumpersFromApi(), 222);
            if (data?.flagActivity) {
                setSelectedJumper(null);
                setToastMessage({ message: 'JUMPER INFORMATION UPDATED', variant: 'success' });
            } else {
                setSelectedJumper(newJumperObject);
                setToastMessage({ message: 'JUMPER INFORMATION UPDATED', variant: 'success' });
            }
        }
    }

    const didUpdatePilotInfo = async (data) => {
        await apiCalls.updatePilot(data?.id, data)
        setToastMessage({ message: 'PILOT INFORMATION UPDATED', variant: 'success' });
        setRefetchData(true);
    }

    const doModalAction = (action) => {
        if (action === 'Dropzone Information') {
            setActiveTab(action);
            return;
        }
        if (action === 'Add Aircraft') {
            setActiveTab('Aircraft');
            setTimeout(() => setAutoOpenAircraftModal(true), 500);
            return;
        }
        if (action === 'Waiver') setActiveTab('Waiver');
    }

    const getAircraftFromApi = async () => {
        const data = await apiCalls.getAircraftByDropzoneId(dropzone?.id || '');
        setAircraft(data);
    }

    const getDropzoneData = () => {
        getAircraftFromApi();
        getEmployeesFromAPI();
        getLoadsByDropzoneId();
        getPilotsFromApi();
        getPaymentsFromAPI();
        getJumpersFromApi();
        getReservationsFromAPI();
        getWaiversFromAPI();
    }

    const getEmployeesFromAPI = async () => {
        const data = await apiCalls.getEmployeesByDropzoneId(dropzone?.id);
        setEmployees(data);
    }

    const getJumpersFromApi = async () => {
        const data = await apiCalls.getJumpersByDropzoneId(dropzone?.id || '');
        setJumpers(data);
    }

    const getLoadsByDropzoneId = async () => {
        const loadData = await apiCalls.getLoadsByDropzoneId(dropzone?.id || '');
        setOriginalLoads(loadData);
        setManifestedLoads(loadData.filter(f => isSameDate(new Timestamp(f?.date?.seconds, f?.date?.nanoseconds).toDate())).filter(d => d?.status !== 'Deleted'));
        setIsLoading(false);
    }

    const getPaymentsFromAPI = async () => {
        const data = await apiCalls.getPaymentsByDropzoneId(dropzone?.id || '');
        setPayments(data);
    }

    const getPilotsFromApi = async () => {
        const data = await apiCalls.getPilotsByDropzoneId(dropzone?.id || '')
        setPilots(data);
    }

    const getReservationsFromAPI = async () => {
        const data = await apiCalls.getTandemReservationsByDropzoneId(dropzone?.id || '');
        setReservations(data);
    }

    const getSubscription = async () => {
        let data = await apiCalls.getSubscriptionByUID(currentUser.uid);
        data?.length > 0 && setNewSubscription(data[0]);
    }

    const getWaiversFromAPI = async () => {
        const data = await apiCalls.getDropzonesSignedWaivers(dropzone?.id || '');
        setDropzoneWaivers(data);
    }

    const goToLastMonth = () => {
        if (monthOnLeftCalendar === 0) setYearOnLeftCalendar(yearOnLeftCalendar - 1);
        setThisMonthsStartDate(new Date(monthOnLeftCalendar === 0 ? thisMonthsStartDate.getFullYear() - 1 : thisMonthsStartDate.getFullYear(), monthOnLeftCalendar === 0 ? 11 : monthOnLeftCalendar - 1, 1));
        setMonthOnLeftCalendar(monthOnLeftCalendar === 0 ? 11 : monthOnLeftCalendar - 1);
    }

    const goToNextMonth = () => {
        if (monthOnLeftCalendar === 11) setYearOnLeftCalendar(yearOnLeftCalendar + 1);
        setThisMonthsStartDate(new Date(monthOnLeftCalendar === 11 ? thisMonthsStartDate.getFullYear() + 1 : thisMonthsStartDate.getFullYear(), monthOnLeftCalendar === 11 ? 0 : monthOnLeftCalendar + 1, 1));
        setMonthOnLeftCalendar(monthOnLeftCalendar === 11 ? 0 : monthOnLeftCalendar + 1);
    }

    const isSameDate = (d) => {
        let isSame;
        try {
            isSame = d?.getFullYear() === selectedDate?.getFullYear() && d?.getMonth() === selectedDate?.getMonth() && d?.getDate() === selectedDate?.getDate();
        } catch (e) {
            isSame = false;
        }
        return isSame;
    }

    const markAsFuelLoad = async (load) => {
        setToastMessage({ message: load?.fuelLoad ? 'Unmarking fuel load.' : 'Load marked as fuel load.', variant: 'success' });
        await apiCalls.updateLoad(load?.id, { fuelLoad: !load?.fuelLoad });
        setRefetchData(true);
    }

    const payForJump = async (load: any, newJumper, price) => {
        let remainingAmountUnpaid = price;
        let updatePayments: ISoloPaymentData[] = [];
        let alreadyUsedDollars = 0;

        payments?.filter(j => j?.userId === newJumper?.id).sort((a, b) => a?.originalAmount > b?.originalAmount ? -1 : 1).forEach(p => {
            p?.withdrawels?.forEach(w => {
                alreadyUsedDollars = alreadyUsedDollars + Number(w?.amountWithdrawn || 0);
            });
            const remaining = p?.originalAmount - alreadyUsedDollars;
            if (remaining > 0) {
                if (remainingAmountUnpaid !== price && remaining >= remainingAmountUnpaid) {
                    const newWithdrawel: IWithdrawelData = {
                        amountWithdrawn: remainingAmountUnpaid,
                        loadId: load.id,
                        paymentDate: new Date(),
                        submittedBy: currentUser?.uid,
                    };
                    remainingAmountUnpaid = 0;
                    updatePayments.push({ ...p, withdrawels: p?.withdrawels?.length > 0 ? [...p.withdrawels, newWithdrawel] : [newWithdrawel] });
                    return;
                }
                if (remaining >= price) {
                    const newWithdrawel: IWithdrawelData = {
                        amountWithdrawn: price,
                        loadId: load.id,
                        paymentDate: new Date(),
                        submittedBy: currentUser?.uid
                    };
                    remainingAmountUnpaid = 0;
                    updatePayments.push({ ...p, withdrawels: p?.withdrawels?.length > 0 ? [...p.withdrawels, newWithdrawel] : [newWithdrawel] });
                    return;
                } else {
                    const newWithdrawel: IWithdrawelData = {
                        amountWithdrawn: remaining,
                        loadId: load.id,
                        paymentDate: new Date(),
                        submittedBy: currentUser?.uid
                    };
                    remainingAmountUnpaid = price - remaining;
                    updatePayments.push({ ...p, withdrawels: p?.withdrawels?.length > 0 ? [...p.withdrawels, newWithdrawel] : [newWithdrawel] });
                }
            }
        });

        if (remainingAmountUnpaid === 0) {
            updatePayments.forEach(async p => await apiCalls.updatePayment(p?.id, p));
            return true;
        }
        return false;
    }

    const unpayForJump = (load) => {
        let arr: ISoloPaymentData[] = [];
        payments?.forEach(p => {
            let arr1 = []
            p?.withdrawels?.forEach(w => w?.loadId !== load?.id && arr1.push(w));
            arr.push({ ...p, withdrawels: arr1 });
        })
        arr.forEach(async p => await apiCalls.updatePayment(p?.id, p));
        return true;
    }

    const saveAircraft = async (data) => {
        const newAircraftData: IAircraftData = {
            active: true,
            aircraftType: data?.aircraftType,
            dateCreated: new Date(),
            dropzoneId: dropzone.id,
            tailNumber: data?.tailNumber,
            minNumJumpers: data?.minNumJumpers,
            maxNumJumpers: data?.maxNumJumpers,
            nickName: data?.nickName
        }
        await apiCalls.addAircraft(newAircraftData);
        setToastMessage({ message: 'Aircraft information saved!', variant: 'success' });
        setRefetchData(true);
    }

    const saveDropzone = async (data: IDropzoneData) => {
        if (!data?.id) {
            await apiCalls.addDropzone({ ...data, ownerUID: currentUser?.uid, lowercaseName: String(data?.name).toLowerCase(), phone: currentUser?.phoneNumber });
            setToastMessage({ message: 'New DZ Saved! Add a waiver to this new location, then you can start sending loads!.', variant: 'success' });
        } else {
            await apiCalls.updateDropzone(data?.id, { ...data, lowercaseName: String(data?.name).toLowerCase() });
            setActiveTab('Settings');
        }
        setRefetchDropzones(true);
        topRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const saveLoad = (load, call, newAircraftData) => {
        if (!dropzone) return;
        const numberOfPreviousLoads = originalLoads?.filter(l => l?.aircraftId === newAircraftData?.id)?.length || 0;
        const newLoadData: ILoadData = {
            aircraft: newAircraftData?.tailNumber,
            aircraftId: newAircraftData?.id,
            call,
            date: selectedDate,
            dropzoneId: dropzone.id,
            fuelLoad: load?.fuelLoad,
            loadSheet: load?.loadSheet,
            name: `${newAircraftData?.nickName ?? newAircraftData?.aircraftType} Load ${numberOfPreviousLoads + 1}`,
            status: 'Building'
        };
        apiCalls.addLoad(newLoadData);
        setRefetchData(true);
    }

    const savePilot = async (data: IPilotData) => {
        await apiCalls.addPilot(data);
        setToastMessage({ message: 'Pilot information saved!', variant: 'success' });
        setRefetchData(true);
    }

    const saveSoloJumper = async () => {
        await apiCalls.addSoloJumper({
            ...selectedJumper,
            dropzoneId: dropzone?.id,
            email: '',
            flagged: false,
            reserveRepackDate: selectedJumper?.reserveRepackDate ? utils?.formatMMDDYYYY(selectedJumper?.reserveRepackDate) : '',
            uspaExp: selectedJumper?.uspaExp ? utils?.formatMMDDYYYY(selectedJumper?.uspaExp) : '',
        });
        setToastMessage({ message: 'New jumper created OnJumprun! Good job, well mail you a cookie.', variant: 'success' });
        setRefetchData(true);
        if(selectedJumper?.jumperType === 'Student') setSoloJumperType('student');
        if(selectedJumper?.jumperType === 'Sport Jumper') setSoloJumperType('licensed');
    }

    const saveTandemReservation = async (data) => {
        const resData: ITandemStudentData = {
            amountPaid: 0,
            balance: 0,
            countryCode: data?.countryCode,
            date: 'N/A',
            DD: data?.DD,
            dropzoneId: dropzone.id,
            email: data?.email,
            emergencyContact: data?.emergencyContact,
            emergencyCountryCode: CountryEnum.USA,
            emergencyPhone: data?.emergencyPhone,
            firstName: data?.firstName,
            groupId: '',
            jumperType: 'Tandem',
            lastName: data?.lastName,
            MM: data?.MM,
            paymentOption: 'deposit',
            phone: data?.phone,
            weight: data?.weight,
            YYYY: data?.YYYY,
        };
        await apiCalls.addTandemStudent(resData);
        setToastMessage({ message: 'Tandem saved successfully!', variant: 'success' });
        setTandemReservationType('walkin')
        setRefetchData(true);
    }

    const updateLoadCall = async (load, call) => {
        let date = new Date();
        await apiCalls.updateLoad(load?.id, { date: new Date(date.getTime() + call * 60000) });
        setToastMessage({ message: 'Load call updated!', variant: 'success' })
        setRefetchData(true);
    }

    const updateLoadStatus = async (load, status) => {
        await apiCalls.updateLoad(load?.id, { status });
        setToastMessage({ message: 'Load status updated!', variant: 'success' })
        setRefetchData(true);
    }

    const updateLoadSheetAndStatus = async (load, status) => {
        await apiCalls.updateLoad(load?.id, { loadSheet: load?.loadSheet || [], status, dropzoneId: dropzone?.id });
        return true;
    }

    //-----Pure JSX Functions-----//

    const aircraftJSX = () => {
        return <div className='mb-3 mt-3'>
            <Row>
                <Col xs='12' xl='6'>
                    <Card className={[darkMode ? 'border-light' : 'border-dark', 'mb-3 shadow-lg'].join(' ')}>
                        <Card.Body>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>Aircraft</th>
                                        <th>Tail #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aircraft.filter(a => a?.active).map((j, i) => <tr key={`aircraft-sheet-${i}`} onClick={() => setSelectedAircraft(j)}>
                                        <td onClick={() => setAutoOpenAircraftModal(true)}>{j?.aircraftType}</td>
                                        <td onClick={() => setAutoOpenAircraftModal(true)}>{j?.tailNumber}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className={[darkMode ? 'border-light' : 'border-dark', 'shadow-lg'].join(' ')}>
                        <Card.Body>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>Pilot Name</th>
                                        <th>License #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pilots?.filter(p => p?.active).map((j, i) => <tr key={`pilot-sheet-${i}`} onClick={() => setSelectedPilot(j)}>
                                        <td onClick={() => setAutoOpenPilotModal(true)}>{j?.name}</td>
                                        <td onClick={() => setAutoOpenPilotModal(true)}>{j?.licenseNumber}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>;
    }

    const calculateContentFromTabJSX = () => {
        switch (activeTab) {
            case 'Account': return <Account currentUser={currentUser} loads={originalLoads} newSubscription={newSubscription} setNewSubscription={setNewSubscription} subscriptionNumMonths={subscriptionNumMonths} />;
            case 'Aircraft': return aircraftJSX();
            case 'Employees': return <Employees dropzoneId={dropzone?.id}
                employees={employees}
                setShowEmployeeModal={setShowEmployeeModal}
                showEmployeeModal={showEmployeeModal}
                setToastMessage={setToastMessage}
                setRefetchEmployees={setRefetchEmployees} />;
            case 'Settings':
            case 'Dropzone Information': return <Dropzones changeDZAndGoToWaiver={changeDZAndGoToWaiver}
                didSaveNewDropzone={saveDropzone}
                dropzone={dropzone}
                dropzones={dropzones}
                setDropzone={setDropzone} />;
            case 'Solo Jumpers': return soloJumpersJSX();
            case 'Tandem Reservations': return reservationsJSX();
            case 'Waiver': return <Waiver didSaveNewDropzone={saveDropzone}
                dropzone={dropzone}
                isDZO={true}
                setDropzone={setDropzone}
                setToastMessage={setToastMessage} />;
            default: return dailyManifestJSX();
        }
    }


    const calculateIcon = (j) => {
        const css = ['opacity-75 p-2', selectedTandemStudent?.id === j?.id ? 'shimmer' : ''].join(' ');
        const found = dropzoneWaivers?.findIndex(w => w?.phone === j?.phone) > -1;
        return found ? <Badge bg='success' className={css}>Signed <CheckCircleFill /></Badge> : <Badge bg='danger' className={css}>Unsigned <XCircleFill /></Badge>;
    }

    const dailyManifestJSX = () => {
        return <>
            {loadsJSX()}
            {noLoadsJSX()}
            {loadingJSX()}
        </>;
    }

    const filterJumpersBasedOffTab = (j: any) => {
        if (j?.jumperType === 'Sport Jumper' && !j?.flagged) {
            return soloJumperType === 'licensed' ? true : false;
        } else if (j?.jumperType === 'Student' && !j?.flagged) {
            return soloJumperType === 'student' ? true : false;
        } else if (j?.jumperType === 'Tandem') {
            if (j?.date === 'N/A') {
                return tandemReservationType === 'walkin' ? true : false;
            } else {
                if (tandemReservationType === 'completed') return utils?.diffInMins(j?.date) < 0 && !j?.flagged ? true : false;
                return utils?.diffInMins(j?.date) >= 0 && !j?.flagged ? true : false;
            }
        } 
        return soloJumperType === 'inactive' ? true : false;
    }

    const getPageContentJSX = () => {
        const blocked = (dropzone?.waiver?.firstSignature === '' || dropzone?.name === '' || !dropzone?.id);
        const dateIsBeforeToday = selectedDate >= utils?.dateWithoutTime(today) ? false : true;
        const freeTrialEnded = (originalLoads?.length >= 5 && !newSubscription?.id);
        const disabled = (disable || isLoading || !dropzone?.id || dateIsBeforeToday || freeTrialEnded);
        const jumpsRemaining = originalLoads?.length >= 25 ? 0 : 25 - originalLoads?.length;
        const showFreeTrial = !isLoading && !newSubscription?.id && dropzone?.id;

        if (activeTab === 'Solo Jumpers') return <div className='mb-3'>
            <AddNewJumperModal autoOpen={autoOpenJumperModal}
                didClickSave={saveSoloJumper}
                dropzone={dropzone}
                selectedJumper={selectedJumper}
                setAutoOpen={setAutoOpenJumperModal}
                setSelectedJumper={setSelectedJumper} />
        </div>;
        if (activeTab === 'Daily Manifest') return !isLoading && <div className='d-flex justify-content-between gap-2 mb-4 me-2'>
            <div>
                <Button className='align-items-center d-flex fw-bold gap-2'
                    disabled={blocked || disabled}
                    onClick={() => clickAddLoad()}
                    variant={darkMode ? 'light' : 'dark'}>
                    {(!jumpsRemaining && !newSubscription?.id) ? 'No free loads left' : <>
                        BUILD A LOAD <PlusCircle />
                    </>}
                </Button>
                {showFreeTrial && !blocked && !toastMessage?.message && <div className='fixed-bottom d-flex justify-content-end p-3' style={{ left: 'unset' }}>
                    <Button onClick={() => setActiveTab('Account')}
                        variant='warning'>
                        {freeTrialEnded ? 'PURCHASE SUBSCRIPTION' : <>{jumpsRemaining} free load{jumpsRemaining === 1 ? '' : 's'} remaining</>}
                    </Button>
                </div>}
            </div>
        </div>;
        if (activeTab === 'Tandem Reservations') return <div className='mb-3'>
            <AddNewTandemModal autoOpen={autoOpenTandemModal}
                currentUser={currentUser}
                didClickSave={saveTandemReservation}
                dropzone={dropzone}
                setAutoOpen={setAutoOpenTandemModal}
                setSelectedTandemStudent={setSelectedTandemStudent} />
        </div>;
        if (activeTab === 'Employees') return <div className='align-items-center d-flex justify-content-between mb-3'>
            <div>
                <Button className='align-items-center d-flex gap-2' onClick={() => setShowEmployeeModal(true)} variant={darkMode ? 'outline-light' : 'outline-secondary'}>
                    <span>Add new employee</span> <PlusCircle />
                </Button>
            </div>
        </div>;
        if (activeTab === 'Aircraft') return <div className='align-items-center d-flex gap-2'>
            <div>
                <AddNewAircraftModal autoOpen={autoOpenAircraftModal}
                    didSaveAircraft={saveAircraft}
                    didUpdateAircraftInfo={didUpdateAircraftInfo}
                    dropzone={dropzone}
                    selectedAircraft={selectedAircraft}
                    setSelectedAircraft={setSelectedAircraft}
                    setAutoOpen={setAutoOpenAircraftModal} />
            </div>
            <div>
                <AddNewPilotModal autoOpen={autoOpenPilotModal}
                    didSavePilot={savePilot}
                    didUpdatePilotInfo={didUpdatePilotInfo}
                    dropzone={dropzone}
                    selectedPilot={selectedPilot}
                    setAutoOpen={setAutoOpenPilotModal}
                    setSelectedPilot={setSelectedPilot} />
            </div>
        </div>;
    }

    const getTitleIconJSX = () => {
        switch (activeTab) {
            case 'Account':
            case 'Dropzone Information':
            case 'Settings': return <BuildingAdd />;
            case 'Employees': return <PeopleFill className='text-warning' />;
            case 'Aircraft': return <AirplaneEngines style={{ color: darkMode ? 'pink' : 'purple' }} />;
            case 'Solo Jumpers': return <HeartFill style={{ color: 'red' }} />;
            case 'Tandem Reservations': return <img src={require(darkMode ? './whiteTandyIcon.png' : './tandyIcon.png')} style={{ width: '2.5vw' }} />;
            case 'Waiver': return <FileLock style={{ color: 'red' }} />;
            default: return <RocketTakeoffFill style={{ color: '#ff6700' }} />;
        }
    }

    const iconAndTitleJSX = (title, icon, content) => {
        return <div className='d-flex flex-fill justify-content-between'>
            <div className='align-items-center d-flex gap-3 mb-3'>
                <div>
                    <h2 className='align-items-center d-flex fw-bold gap-3 mb-0'>
                        {icon}{activeTab === 'Waiver' ? `${dropzone?.name} ${title}` : title}
                    </h2>
                    {title === 'Daily Manifest' && <Badge bg='warning'
                        className={['cursor', darkMode ? 'text-dark' : 'text-light'].join(' ')}
                        onClick={() => setShowCalendar(!showCalendar)} style={{ color: '#0e263c' }}>
                        {String(selectedDate?.toDateString()).toUpperCase()}
                    </Badge>}
                    {activeTab === 'Waiver' && dropzone?.waiver?.fourthSignature !== '' && <small className='align-items-center cursor d-flex gap-1'>
                        <a onClick={() => navigate(`/${dropzone?.id}/signwaiver`)}>
                            <small className='cursor fw-bold opacity-75' onClick={() => setShowCalendar(!showCalendar)} style={{ color: '#0e263c' }}>
                                <span className='ps-2 text-primary underline'>
                                    {`www.onjumprun.com/${dropzone?.id}/signwaiver`}
                                </span>
                            </small>
                        </a>
                    </small>}
                </div>
            </div>
            <div>{content}</div>
        </div>;
    }

    const loadingJSX = () => isLoading && <div className='align-items-center d-flex fadeInFast justify-content-center position-relative vh-75'>
        <div>
            <Spinner animation='border' variant='secondary' />
        </div>
    </div>;

    const loadsJSX = () => {
        return !isLoading && manifestedLoads?.map((l, i) => <Load aircraft={aircraft}
            activeTab={activeTab}
            didAddJumperToLoad={addJumperToLoad}
            didDeleteJumper={deleteJumperOrTandem}
            didDeleteLoad={deleteLoad}
            didSaveLoad={clickSaveLoad}
            didUpdateCall={updateLoadCall}
            disable={i + 1 === manifestedLoads?.length && disable}
            dropzone={dropzone}
            instructors={employees?.filter(e => e?.hasTandemRating)}
            jumpers={jumpers}
            key={`${dropzone.name}-load-${i}`}
            load={l}
            manifestedLoads={manifestedLoads}
            markAsFuelLoad={markAsFuelLoad}
            payments={payments}
            setDisable={setDisable}
            setManifestedLoads={setManifestedLoads}
            setSelectedJumper={setSelectedJumper}
            tandems={reservations}
            updateLoadStatus={updateLoadStatus} />);
    }

    const navJSX = () => {
        const needsWaiver = (dropzone?.waiver?.firstSignature?.length < 1 || dropzone?.waiver?.secondSignature?.length < 1  || dropzone?.waiver?.thirdSignature?.length < 1 || !dropzone?.waiver?.dateSigned);
        const showNotifications = { needsToAddDropzone: dropzones?.length === 0, needsWaiver };

        return <Col className='d-none d-md-block' md='4' xl='3'>
            <div className='border-right border-secondary h-100 onJumprunMenu pt-2 position-relative'>
                <div className='border-bottom border-secondary cursor mb-3 pb-2 pe-2 ps-3'>
                    <div className='align-items-center d-flex justify-content-between'>
                        <span onClick={() => { navigate('/onjumprun'); setActiveTab('Daily Manifest'); }}>
                            <Logo onlyText={true} />
                        </span>
                        <span className='align-items-center d-flex gap-2 justify-content-end'>
                            <Notification config={showNotifications}
                                doAction={doModalAction}
                                dropzones={dropzones}
                                isLoading={isLoading}
                                show={needsWaiver && !showNotifications?.needsToAddDropzone}
                                showModal={showNotificationModal}
                                setShowModal={setShowNotificationModal} />
                        </span>
                    </div>
                </div>
                <div>
                    <p className='align-items-center d-flex eye-wrap gap-2 justify-content-between mb-0 px-4 py-1'
                        onClick={() => { setActiveTab('Daily Manifest'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                        <span className='align-items-center d-flex gap-2'>
                            {activeTab === 'Daily Manifest' ? <RocketTakeoffFill className='pulse' style={{ color: '#ff6700' }} /> : <RocketTakeoff />}
                            <span className={activeTab === 'Daily Manifest' ? 'shimmerHover underline' : ''}>
                                Daily Manifest
                            </span>
                        </span>
                        <EyeFill className='eye' />
                    </p>
                    <p className='align-items-center d-flex eye-wrap gap-2 justify-content-between mb-0 px-4 py-1'
                        onClick={() => { setActiveTab('Tandem Reservations'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                        <span className='align-items-center d-flex gap-2'>
                            {activeTab === 'Tandem Reservations' ? <CurrencyDollar className='pulse' style={{ color: '#32CD32' }} /> : <CurrencyDollar />}
                            <span className={activeTab === 'Tandem Reservations' ? 'shimmerHover underline' : ''}>Tandem Reservations</span>
                        </span>
                        <EyeFill className='eye' />
                    </p>
                    <p className='align-items-center d-flex eye-wrap gap-2 justify-content-between mb-0 px-4 py-1'
                        onClick={() => { setActiveTab('Solo Jumpers'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                        <span className='align-items-center d-flex gap-2'>
                            {activeTab === 'Solo Jumpers' ? <HeartFill style={{ color: 'red' }} /> : <Heart />}
                            <span className={activeTab === 'Solo Jumpers' ? 'shimmerHover underline' : ''}>Solo Jumpers</span>
                        </span>
                        <EyeFill className='eye' />
                    </p>
                    <p className='align-items-center d-flex eye-wrap gap-2 justify-content-between mb-0 px-4 py-1'
                        onClick={() => { setActiveTab('Aircraft'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                        <span className='align-items-center d-flex gap-2'>
                            {activeTab === 'Aircraft' ? <AirplaneEngines style={{ color: darkMode ? 'pink' : '#c264ff' }} /> : <AirplaneEngines />}
                            <span className={activeTab === 'Aircraft' ? 'shimmerHover underline' : ''}>Aircraft</span>
                        </span>
                        <EyeFill className='eye' />
                    </p>
                    <p className='align-items-center d-flex eye-wrap gap-2 justify-content-between mb-0 px-4 py-1'
                        onClick={() => { setActiveTab('Dropzone Information'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                        <span className='align-items-center d-flex gap-2'>
                            <GearFill style={{ color: (activeTab === 'Dropzone Information' || activeTab === 'Employees' || activeTab === 'Waiver' || activeTab === 'Account') ? '#ff6700' : '#fff' }} />
                            <span className={activeTab === 'Settings' || activeTab === 'Dropzone Information' || activeTab === 'Waiver' ? 'shimmerHover' : ''}>
                                Settings
                            </span>
                        </span>
                        <EyeFill className='eye' />
                    </p>
                    <Collapse in={activeTab === 'Settings' || activeTab === 'Dropzone Information' || activeTab === 'Waiver' || activeTab === 'Employees' || activeTab === 'Account'}>
                        <div>
                            <p className='cursor mb-1 px-5 d-flex gap-2' onClick={() => { setActiveTab('Dropzone Information'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                                <span className={activeTab === 'Dropzone Information' ? 'fw-bold text-danger opacity-50' : 'opacity-25'}>&#x2022;</span> 
                                <span className={activeTab === 'Dropzone Information' ? 'text-warning' : 'text-secondary'}>
                                    {dropzones?.length > 1 ? 'Dropzone Locations' : 'Dropzone Information'}
                                </span>
                            </p>
                            <p className='cursor mb-1 px-5 d-flex gap-2' onClick={() => setActiveTab('Employees')}>
                                <span className={activeTab === 'Employees' ? 'fw-bold text-danger opacity-50' : 'opacity-25'}>&#x2022;</span> 
                                <span className={activeTab === 'Employees' ? 'text-warning' : 'text-secondary'}>Employees</span>
                            </p>
                            <p className='cursor d-flex gap-2 mb-1 px-5' onClick={() => { setActiveTab('Waiver'); topRef?.current?.scrollIntoView({ behavior: 'smooth' }); }}>
                                <span className={activeTab === 'Waiver' ? 'fw-bold text-danger opacity-50' : 'opacity-25'}>&#x2022;</span>
                                <span className={activeTab === 'Waiver' ? 'text-warning' : 'text-secondary'}>Waiver</span>
                            </p>
                            <p className='cursor d-flex gap-2 mb-1 px-5' onClick={() => setActiveTab('Account')}>
                                <span className={activeTab === 'Account' ? 'fw-bold text-danger opacity-50' : 'opacity-25'}>&#x2022;</span> 
                                <span className={activeTab === 'Account' ? 'text-warning' : 'text-secondary'}>Account</span>
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className='border-top d-none d-md-block p-3 absolute-bottom w-100'>
                    <Collapse in={showCalendar}>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <h6 className='fw-bold'>{`${utils?.getMonthString(monthOnLeftCalendar)} ${yearOnLeftCalendar}`}</h6>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <ArrowLeftSquareFill className='cursor orangeOnHover text-warning' onClick={() => goToLastMonth()} style={{ height: '24px', width: '24px' }} />
                                    <ArrowRightSquareFill className='cursor greenOnHover' onClick={() => goToNextMonth()} style={{ height: '24px', width: '24px' }} />
                                </div>
                            </div>
                            <Calendar activeTab={activeTab}
                                selectedDate={selectedDate}
                                setActiveTab={setActiveTab}
                                setSelectedDate={setSelectedDate}
                                thisMonthsStartDate={thisMonthsStartDate} />
                        </div>
                    </Collapse>
                    <div className='align-items-center d-flex justify-content-between px-2'>
                        <div className='cursor d-flex gap-3'>
                            <LockFill className='text-danger' onClick={() => didClickLogout()} />
                            {darkMode ? <SunFill className='pulse' onClick={() => darkModeClicked()} style={{ color: '#F6F1D5' }} /> :
                                <MoonStarsFill className='pulse' onClick={() => darkModeClicked()} style={{ color: ' #F6F1D5' }} />}
                        </div>
                        <div className='align-items-center d-flex'>
                            <Button className='align-items-center d-flex fw-bold'
                                onClick={() => {
                                    if(showCalendar) {
                                        setSelectedDate(today);
                                        setMonthOnLeftCalendar(today.getMonth());
                                        setYearOnLeftCalendar(today.getFullYear())
                                        setThisMonthsStartDate(today);
                                    }
                                    setShowCalendar(!showCalendar); 
                                }}
                                size='sm'
                                variant='outline-warning'>
                                {showCalendar ? <>
                                    <ArrowDownCircle className='cursor me-2' />
                                    Hide
                                </> : <>
                                    <Calendar3 className='cursor me-2' onClick={() => setShowCalendar(!showCalendar)} />
                                    {selectedDate?.toLocaleDateString()}
                                </>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Col>;
    }

    const noLoadsJSX = () => {
        const blocked = dropzone?.id && (dropzone?.waiver?.firstSignature === '' || dropzone?.name === '') && !isLoading;
        return manifestedLoads?.length === 0 && !isLoading && <div className='align-items-center d-flex vh-75 justify-content-center position-relative'>
            {(blocked || !dropzone?.id) ? <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                <Card className='fadeInFast'>
                    <Card.Body>
                        {blocked && <>
                            <h4 className='fw-medium underline'>Finish Your Dropzone</h4>
                            <small >Before you can start sending any loads you need to setup your<br />dropzone's waiver, and add at least one aircraft.  </small>
                        </>}
                        {!dropzone?.id && <>
                            <h4 className='fw-medium underline'>Setup Your Account</h4>
                            <p className='fw-light mb-0'>
                                Get started by adding your first Dropzone!
                            </p>
                        </>}
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-end px-2'>
                        {blocked && <Button className='border-danger fw-bold shimmer underline' onClick={() => setShowNotificationModal(true)} variant='outline-danger'>
                            <span className='d-flex align-items-center gap-2 underline'> <Spinner animation='grow' size='sm' /> Finish setting up DZ</span>
                        </Button>}
                        {!dropzone?.id && <Button className='fw-bold shimmer underline'
                            onClick={() => setActiveTab('Settings')}
                            variant='danger'>
                            <span className='align-items-center d-flex gap-2 justify-content-between w-100'>
                                Add Your Dropzone
                                <img alt='OnJumprun Mini Icon'
                                    className='cursor'
                                    src={require('./whiteSoloIcon.png')}
                                    style={{ height: '1.3rem' }} />
                            </span>
                        </Button>}
                    </Card.Footer>
                </Card>
            </div> : <h5 className='fw-light mb-0'>
                {`no loads on ${selectedDate?.toDateString()}`}
            </h5>}
        </div>;
    }

    const reservationsJSX = () => {
        const reservationsByTab = (reservations.filter((r: any) => filterJumpersBasedOffTab(r as any)));
        return <>
            <Tabs activeKey={tandemReservationType} className='fw-medium' onSelect={(k) => setTandemReservationType(k as any)}>
                <Tab eventKey='upcoming' title='Online Bookings' />
                <Tab eventKey='walkin' title='Walk In' />
                <Tab eventKey='completed' title='Completed' />
            </Tabs>
            <Card className={['shadow-sm', darkMode ? 'border-light' : 'border-secondary'].join(' ')}>
                <Card.Body className='p-0'>
                    <Table className='mb-0' responsive striped>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>DOB</th>
                                <th>Weight</th>
                                <th className='text-end'>Waiver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservationsByTab?.length > 0 ? reservationsByTab.map((j: any, i) => <tr className={selectedTandemStudent?.firstName === j?.firstName && selectedTandemStudent?.lastName === j?.lastName ? 'gray-row' : ''} key={`tandem-sheet-${i}`}>
                                <td className={['ps-3 uppercase', j?.flagged ? 'opacity-50 text-danger' : ''].join(' ')} onClick={() => setSelectedTandemStudent({ ...j, jumperType: 'Tandem' })}>
                                    {j?.firstName} {j?.lastName}
                                </td>
                                <td className={j?.flagged ? 'opacity-25 text-danger' : ''} onClick={() => setSelectedTandemStudent({ ...j, jumperType: 'Tandem' })}>
                                    {j?.phone}
                                </td>
                                <td className={j?.flagged ? 'opacity-25 text-danger' : ''} onClick={() => setSelectedTandemStudent({ ...j, jumperType: 'Tandem' })}>
                                    {`${j.MM}/${j?.DD}/${j?.YYYY}`}
                                </td>
                                <td className={j?.flagged ? 'opacity-25 text-danger' : ''} onClick={() => setSelectedTandemStudent({ ...j, jumperType: 'Tandem' })}>
                                    {j?.weight}<span className='opacity-25 ps-1'>lbs</span>
                                </td>
                                <td className='text-end' onClick={() => setSelectedTandemStudent({ ...j, jumperType: 'Tandem' })}>
                                    {j?.flagged ? <FlagFill className='me-2 text-danger' /> : calculateIcon(j)}
                                </td>
                            </tr>) : <tr>
                                <td className='py-5 text-center fw-light' colSpan={5}>no tandems found</td>
                            </tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>;
    }

    const soloJumpersJSX = () => {
        const filteredJumpers = (jumpers.filter(j => filterJumpersBasedOffTab(j)));
        return <>
            <Tabs activeKey={soloJumperType} className='fw-medium' onSelect={(a) => setSoloJumperType(a as any)}>
                <Tab eventKey='licensed' title='Licensed Jumpers' />
                <Tab eventKey='student' title='Student Jumpers' />
                <Tab eventKey='inactive' title='Inactive' />
            </Tabs>
            <Card className={['shadow-sm', darkMode ? 'border-light' : 'border-secondary'].join(' ')}>
                <Card.Body className='p-0'>
                    <Table className='mb-0' responsive striped>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Wing Loading</th>
                                <th>Reserve Card</th>
                                <th className='text-end'>Waiver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredJumpers?.length > 0 ? filteredJumpers.map((j, i) => <tr className={calculateRowColor(j?.jumperType)} key={`jumpers-sheet-${i}`}>
                                <td onClick={() => setSelectedJumper(j)}>
                                    <span className='fw-bold uppercase'>{j?.firstName} {j?.lastName}</span>
                                </td>
                                <td onClick={() => setSelectedJumper(j)}>{j?.phone}</td>
                                <td onClick={() => setSelectedJumper(j)}>{j?.wingLoading}</td>
                                {j?.jumperType === 'Sport Jumper' ? <td onClick={() => setSelectedJumper(j)}>{j?.reserveCardCheckedBy ? <span>
                                    <span className='text-success'>CHECKED BY:</span>
                                    <span className='opacity-25 ps-1'>{j?.reserveCardCheckedBy}</span>
                                </span> : <span className='text-danger' onClick={() => setSelectedJumper(j)}>NOT CHECKED</span>}</td> : <td onClick={() => setSelectedJumper(j)}>...</td>}
                                <td className='fw-medium text-end' onClick={() => setSelectedJumper(j)}>
                                    {calculateIcon(j)}
                                </td>
                            </tr>) : <tr>
                                <td className='fw-light py-5 text-center' colSpan={6}>no jumpers found</td>
                            </tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>;
    }

    return <div className={darkMode ? 'darkMode' : ''} style={{ overflowX: 'hidden' }}>
        <Row className='position-relative vh-100'>
            {navJSX()}
            <Col>
                <div ref={topRef} />
                <div className='pe-3 ps-2 pt-4'>
                    <div className='d-flex justify-content-between'>
                        {iconAndTitleJSX(activeTab === 'Dropzone Information' && dropzones?.length > 1 ? 'Dropzone Locations' : activeTab, getTitleIconJSX(), getPageContentJSX())}
                    </div>
                    <div className='d-flex h-100'>
                        <div className='flex-fill'>
                            {calculateContentFromTabJSX()}
                            <div ref={bottomRef} />
                        </div>
                        <SideBar currentUser={currentUser}
                            dropzone={dropzone}
                            didUpdateJumperInfo={didUpdateJumperInfo}
                            payments={payments}
                            selectedJumper={selectedJumper}
                            selectedTandemStudent={selectedTandemStudent}
                            setRefetchPayments={setRefetchPayments}
                            setSelectedJumper={setSelectedJumper}
                            setSelectedTandemStudent={setSelectedTandemStudent} />
                    </div>
                </div>
            </Col>
        </Row>
    </div>;
}
export default HomePage;