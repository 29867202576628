import { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import DropzoneLocations from './DropzoneLocations';

const Dropzones = ({ changeDZAndGoToWaiver, didSaveNewDropzone, dropzone, dropzones, setDropzone }) => {
    const [dropzoneBeingEdited, setDropzoneBeingEdited] = useState(null);

    //-----Pure JSX Functions-----//

    return <Row className='mb-4'>
        <Col className='pt-3' xs='12'>
            <DropzoneLocations changeDZAndGoToWaiver={changeDZAndGoToWaiver} 
                didSaveNewDropzone={didSaveNewDropzone}
                dropzone={dropzone}
                dropzoneBeingEdited={dropzoneBeingEdited}
                dropzones={dropzones}
                setDropzoneBeingEdited={setDropzoneBeingEdited}
                setDropzone={setDropzone} />
        </Col>
    </Row>;
}
export default Dropzones;