import { useContext, useState } from 'react';

import { Collapse, Card, Form } from 'react-bootstrap';
import { CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';

import { IDropzoneData } from '../models';
//@ts-ignore
import soloIcon from './soloIcon.png';
import OnJumprunContext from '../context';
//@ts-ignore
import whiteSoloIcon from './whiteSoloIcon.png';

interface IFunJumperPricing {
    dropzone: IDropzoneData,
    setDropzone: (val: IDropzoneData) => void
}

const FunJumperPricing = ({ dropzone, setDropzone }: IFunJumperPricing) => {
    const { darkMode } = useContext(OnJumprunContext);
    
    const [show, setShow] = useState(false);

    //-----Pure JSX Functions-----//

    return <Card className='mb-2'>
        <Card.Body className='p-0'>
            <div>
                <div className='align-items-center cursor d-flex justify-content-between p-3' onClick={() => setShow(!show)}>
                    <h5 className='align-items-center d-flex fw-bold gap-2 mb-0'>
                        <img src={darkMode ? whiteSoloIcon : soloIcon} style={{ height: '2rem' }} />
                        Fun Jumper Pricing
                    </h5>
                    {show ? <CaretUpSquareFill /> : <CaretDownSquareFill />}
                </div>
                <Collapse in={show}>
                    <div>
                        <div className='d-flex gap-5 justify-content-center'>
                            <Form.Group className='text-end'>
                                <Form.Label as='small' className='fw-medium mb-0'>Hop & Pop</Form.Label>
                                <div className='align-items-center d-flex gap-1'>
                                    <span className='fw-medium opacity-25'>$</span>
                                    <Form.Control onChange={e => setDropzone({
                                        ...dropzone,
                                        pricing: {
                                            ...dropzone?.pricing,
                                            funJumpers: { ...dropzone?.pricing?.funJumpers, hopNPop: e?.target?.value }
                                        }
                                    })}
                                        placeholder='25'
                                        style={{ width: '95px' }}
                                        type='number'
                                        value={dropzone?.pricing?.funJumpers?.hopNPop || ''} />
                                </div>
                            </Form.Group>
                            <div>
                                <Form.Group className='text-end'>
                                    <Form.Label as='small' className='fw-medium mb-0'>Full altitude</Form.Label>
                                    <div className='align-items-center d-flex justify-content-end gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone,
                                            pricing: {
                                                ...dropzone?.pricing,
                                                funJumpers: { ...dropzone?.pricing?.funJumpers, fullAltitude: e?.target?.value }
                                            }
                                        })}
                                            placeholder='33'
                                            style={{ width: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.funJumpers?.fullAltitude || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='d-flex gap-5 justify-content-center pb-4 px-3'>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-0 text-end'>Jump w/ Rental Rig</Form.Label>
                                <div className='align-items-center d-flex gap-1'>
                                    <span className='fw-medium opacity-25'>$</span>
                                    <Form.Control onChange={e => setDropzone({
                                        ...dropzone,
                                        pricing: {
                                            ...dropzone?.pricing,
                                            funJumpers: { ...dropzone?.pricing?.funJumpers, withGearRental: e?.target?.value }
                                        }
                                    })}
                                        placeholder='149'
                                        style={{ width: '95px' }}
                                        type='number'
                                        value={dropzone?.pricing?.funJumpers?.withGearRental || ''} />
                                </div>
                            </Form.Group>
                            <div>
                                <Form.Group className='text-end'>
                                    <Form.Label as='small' className='fw-medium mb-0'>Jump w/ Coach</Form.Label>
                                    <div className='align-items-center d-flex gap-1'>
                                        <span className='fw-medium opacity-25'>$</span>
                                        <Form.Control onChange={e => setDropzone({
                                            ...dropzone,
                                            pricing: {
                                                ...dropzone?.pricing,
                                                funJumpers: { ...dropzone?.pricing?.funJumpers, coachJump: e?.target?.value }
                                            }
                                        })}
                                            placeholder='149'
                                            style={{ width: '95px' }}
                                            type='number'
                                            value={dropzone?.pricing?.funJumpers?.coachJump || ''} />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Card.Body>
    </Card>;
}
export default FunJumperPricing;