import { useEffect, useState } from 'react';

import ApiCalls from '../apiCalls';
import { ArrowLeftSquareFill, ArrowRightSquareFill, CheckCircleFill } from 'react-bootstrap-icons';
import { Button, Card, Collapse, Form } from 'react-bootstrap';
import { Calendar } from 'react-calendar';
import moment from 'moment/moment';
import { useParams, useSearchParams } from 'react-router-dom';

import AddTandemReservation from './AddTandemReservation';
import { CountryEnum, IDropzoneData, ISoloPaymentData, ITandemStudentData } from '../models';
import Payments from './Payments';
import Utilities from '../utils';
import '../styles/blackCalendar.css';

const BookTandem = () => {
    const apiCalls = new ApiCalls();
    const { dropzoneId } = useParams();
    const [searchParams] = useSearchParams();
    const today = new Date();
    const utils = new Utilities();
    
    let additionalJumpersFromUrl: any = searchParams?.get('additionalJumpers');
    let amountFromUrl: any = searchParams?.get('amount');
    let amOrPM: any = searchParams?.get('amOrPM');
    let cancelled: any = searchParams?.get('cancelled');
    let currentStep: any = searchParams?.get('currentStep');
    let dateFromUrl: any = searchParams?.get('date');
    let DDFromUrl: any = searchParams?.get('DD');
    let emailFromUrl: any = searchParams?.get('email');
    let emergencyNameFromURL: any = searchParams?.get('eName');
    let emergencyPhoneFromURL: any = searchParams?.get('ePhone');
    let fullNameFromUrl: any = searchParams?.get('firstName');
    let idFromUrl: any = searchParams?.get('id');
    let lastNameFromUrl: any = searchParams?.get('lastName');
    let MMFromUrl: any = searchParams?.get('MM');
    let numParticipantsFromUrl: any = searchParams?.get('numParticipants');
    let paidFromUrl: any = searchParams?.get('paid');
    let phoneFromUrl: any = searchParams?.get('phone');
    let weightFromUrl: any = searchParams?.get('weight');
    let YYYYFromUrl: any = searchParams?.get('YYYY');

    if(additionalJumpersFromUrl) additionalJumpersFromUrl = JSON.parse(additionalJumpersFromUrl);
    if(amountFromUrl) amountFromUrl = Number(amountFromUrl);
    if(currentStep) currentStep = Number(currentStep);
    if(dateFromUrl) dateFromUrl = new Date(dateFromUrl);
    if(weightFromUrl) weightFromUrl = Number(weightFromUrl);

    const [additionalJumpers, setAdditionalJumpers] = useState<any>(additionalJumpersFromUrl?.length > 0 ? additionalJumpersFromUrl :[]);
    const [currentMonth, setCurrentMonth] = useState(today);
    const [dropzone, setDropzone] = useState<IDropzoneData>(null);
    const originalStudentObj: ITandemStudentData = {
        amountPaid: amountFromUrl ?? 0,
        balance: 0,
        countryCode: CountryEnum.USA,
        date: dateFromUrl ? new Date(dateFromUrl) : today,
        dateCreated: today,
        DD: DDFromUrl ?? 1,
        dropzoneId: dropzoneId,
        firstName: fullNameFromUrl ?? '',
        email: emailFromUrl ?? '',
        emailSent: false,
        emergencyContact: emergencyNameFromURL ?? '',
        emergencyCountryCode: CountryEnum.USA,
        emergencyPhone: emergencyPhoneFromURL ?? '',
        groupId: '',
        id: idFromUrl ?? '',
        jumperType: 'Tandem',
        lastName: lastNameFromUrl ?? '',
        MM: MMFromUrl ?? 1, 
        paid: paidFromUrl || false,
        paymentOption: 'deposit',
        phone: phoneFromUrl ?? '',
        weight: weightFromUrl ?? '',
        YYYY: YYYYFromUrl ?? (today.getFullYear() - 18)
    };
    const [newReservation, setNewReservation] = useState<ITandemStudentData>(originalStudentObj);
    const [numJumpers, setNumJumpers] = useState(numParticipantsFromUrl ?? 1);
    const [reservations, setReservations] = useState<ITandemStudentData[]>([]);
    const [selectedDate, setSelectedDate] = useState(dateFromUrl ?? null);
    let mins = dateFromUrl?.getMinutes();
    if(mins === 0) mins = '00';
    const [selectedTime, setSelectedTime] = useState<any>(dateFromUrl ? `${dateFromUrl.getHours()}:${mins} ${amOrPM}` : null)
    const [step, setStep] = useState<any>(currentStep > 1 && currentStep < 5 ? currentStep : 1);
    const [timeSlots, setTimeSlots] = useState([]);

    useEffect(() => {
        getDropzoneDataFromAPI();
    }, []);

    useEffect(() => {
        updateSelectedTimeAfterBackClickFromStripe();
    }, [ , step]);

    useEffect(() => {
        if(newReservation?.paid && !newReservation?.emailSent && emailFromUrl && dropzone && !cancelled) {
            updateReservation();
            setStep(5);
        }
    }, [dropzone, newReservation]);

    useEffect(() => {
        if(numJumpers > 1 && numJumpers < 25) setAdditionalJumpers(Array(Number(numJumpers - 1)).fill({ firstName: '', lastName: '' }));
    }, [numJumpers]);

    useEffect(() => {
        if(selectedDate) {
            getReservationsByDay();
            getTimeslotDataFromUtils();
        }
    }, [selectedDate]);

    useEffect(() => {
        if(selectedTime) setSelectedDate(utils?.appendTimeToDate(selectedDate, selectedTime));
    }, [selectedTime]);

    useEffect(() => {
        if(step === 3) getTimeslotDataFromUtils();
    }, [step]);

    const calculateColors = () => {
        switch (step) {
            case 5: return 'lastStep';
            case 4: return 'addJumperStep';
            case 3:
            case 2: return 'calendarAndTimeslotStep';
            default: return 'zeroStep';
        }
    }

    const checkIfDropzoneClosedOnThisDay = (e) => {
        const dayOfWeek = e?.date?.getDay();
        if(dayOfWeek >= 0 && dayOfWeek <= 6) {
            if(dayOfWeek === 0 && dropzone?.hoursOfOperation?.Sunday) return false;
            if(dayOfWeek === 1 && dropzone?.hoursOfOperation?.Monday) return false;
            if(dayOfWeek === 2 && dropzone?.hoursOfOperation?.Tuesday) return false;
            if(dayOfWeek === 3 && dropzone?.hoursOfOperation?.Wednesday) return false;
            if(dayOfWeek === 4 && dropzone?.hoursOfOperation?.Thursday) return false;
            if(dayOfWeek === 5 && dropzone?.hoursOfOperation?.Friday) return false;
            if(dayOfWeek === 6 && dropzone?.hoursOfOperation?.Saturday) return false;
            return true;
        }
        return false;
    }

    const checkIfNextStepButtonDisabled = () => {
        if(step === 1 && (numJumpers < 1 || isNaN(numJumpers) || numJumpers > 25)) return true;
        if (step === 2 && !selectedDate) return true;
        if (step === 3 && !selectedTime) return true;
        if (step === 4) {
            if(newReservation?.email === '' || newReservation?.phone?.length !== 10) return true;
            if(newReservation?.emergencyContact === '' || newReservation?.emergencyPhone?.length !== 10) return true;
            const date = new Date(newReservation.MM + '/' + newReservation.DD + '/' + newReservation.YYYY);
            const diffInYears = (utils.diffInMinsFromDate(date) / 525600) * -1;
            if (diffInYears < 18) return true;
            if(additionalJumpers?.length > 0 && additionalJumpers?.findIndex(a => a?.firstName !== '' && a?.lastName) === -1) return true;
        }
        return false;
    }

    const getDropzoneDataFromAPI = async () => {
        const data = await apiCalls.getDropzoneByDropzoneId(dropzoneId);
        setDropzone(data as IDropzoneData);
    }

    const getReservationsByDay = async () => {
        const data = await apiCalls.getTandemReservationsByDate(utils?.dateWithoutTime(selectedDate));
        setReservations(data);
    }

    const getTimeslotDataFromUtils = async () => {
        setTimeSlots(await utils?.getAvailableTandemTimeslots(
            utils?.appendTimeToDate(selectedDate, dropzone?.hoursOfOperation?.from),
            dropzone?.hoursOfOperation?.from,
            dropzone?.hoursOfOperation?.to,
            Number(dropzone?.maxTandemsPerHour),
            numJumpers,
            reservations
        ));
    }

    const goToLastMonth = () => {
        let d = new Date(currentMonth);
        d.setMonth(d.getMonth() - 1)
        setCurrentMonth(d);
    }

    const goToNextMonth = () => {
        let d = new Date(currentMonth);
        d.setMonth(d.getMonth() + 1)
        setCurrentMonth(d);
    }

    const nextStepClicked = () => {
        if(step === 2 && !selectedDate) return;
        setStep(step + 1);
    }

    const savePayment = async () => {
        const newPaymentData: ISoloPaymentData = {
            date: new Date(),
            dropzoneId: dropzone?.id,
            firstName: newReservation?.firstName,
            lastName: newReservation?.lastName,
            originalAmount: newReservation?.amountPaid || 0,
            phone: newReservation?.phone as any,
            submittedBy: '',
            userId: '',
            withdrawels: []
        };
        await apiCalls.addPayment(newPaymentData);
    }

    const saveReservation = async () => {
        const dateTime = utils.appendTimeToDate(selectedDate, selectedTime);
        const reservationId: any = await apiCalls.addTandemStudent({ ...newReservation, date: dateTime });
        additionalJumpers?.forEach(async a => {
            await apiCalls.addTandemStudent({ ...originalStudentObj, phone: newReservation?.phone, firstName: a?.firstName, lastName: a?.lastName, date: dateTime });
        })
        setNewReservation({ ...newReservation, id: reservationId });
    }

    const updateReservation = async () => {
        setNewReservation({ ...newReservation, emailSent: true });
        await apiCalls.updateTandemReservation({ ...newReservation, emailSent: true });
        await apiCalls.sendConfirmationEmail(newReservation, dropzone, numJumpers);
        savePayment();
    }

    const updateAdditionalJumpersFirstName = (val, i) => {
        const arr = [];
        additionalJumpers.forEach((a, idx) => {
            if(idx === i) arr.push({ ...a, firstName: val });
            else arr.push(a);
        });
        setAdditionalJumpers(arr);
    }

    const updateAdditionalJumpersLastName = (val, i) => {
        const arr = [];
        additionalJumpers.forEach((a, idx) => arr.push(idx === i ? { ...a, lastName: val } : a));
        setAdditionalJumpers(arr);
    }

    const updateSelectedTimeAfterBackClickFromStripe = () => {
        if (step === 4) {
            if(amOrPM) {
                const newTime = `${selectedDate?.getHours()}:${selectedDate?.getMinutes()} ${amOrPM}`;
                setNewReservation({ ...newReservation, date: utils.appendTimeToDate(selectedDate, newTime) });
            } else {
                setNewReservation({ ...newReservation, date: utils.appendTimeToDate(selectedDate, selectedTime) });
            }
        }
    }

    //-----Pure JSX Functions-----//

    const additionalJumpersJSX = () => {
        return <div>
            <div className='d-flex gap-3'>
                <small className='fw-bold p-2'>Additional Jumpers: </small>
                <div className='flex-fill'>
                    {additionalJumpers?.map((j, i) => {
                        return <div className='d-flex gap-2 pb-2'>
                            <div>
                                <Form.Control onChange={e => updateAdditionalJumpersFirstName(e?.target?.value, i)}
                                    placeholder={`Jumper ${i + 2} name`}
                                    value={j?.firstName || ''} />
                            </div>
                            <div>
                                <Form.Control onChange={e => updateAdditionalJumpersLastName(e?.target?.value, i)}
                                    placeholder={`Jumper ${i + 2} name`}
                                    value={j?.lastName || ''} />
                            </div>
                        </div>;
                    })}
                </div>
            </div>
        </div>;
    }

    const availableTimeSlotsJSX = () => {
        const show = (step === 3 && timeSlots?.length > 0);
        return <div>
            <div>
                <div className='m-4'>
                    <h6 className='fw-bold text-center underline' hidden={!show}>
                        Available Time Slots
                    </h6>
                    <Collapse in={show}>
                        <div>
                            <div style={{ maxHeight: '27vh', overflowX: 'unset', overflowY: 'scroll' }}>
                                {timeSlots?.map((t: any, idx) => slotJSX(moment(t?.date).format('h:mm a'), idx))}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>;
    }

    const buttonTitleJSX = () => {
        if(numJumpers > 25) return '25 Jumpers Max per Reservation';
        if (step === 4) return 'Save Jumper';
        if(step === 3 || step === 2) return 'Select Timeslot';
        return 'Next Step';
    }

    const calendarJSX = () => {
        return <div>
            <div className='d-flex justify-content-between m-2'>
                <div>
                    <ArrowLeftSquareFill className='cursor' onClick={() => goToLastMonth()} />
                </div>
                <h6 className='fw-bold mb-0'>
                    {utils?.getMonthString(currentMonth?.getMonth())} {currentMonth?.getFullYear()}
                </h6>
                <div className='d-flex justify-content-end'>
                    <ArrowRightSquareFill className='cursor' onClick={() => goToNextMonth()} />
                </div>
            </div>
            <Calendar activeStartDate={currentMonth}
                calendarType='Hebrew'
                className='hide-scrollbar mb-3'
                minDate={today}
                maxDate={new Date(dropzone?.hoursOfOperation?.closingDay)}
                onChange={(e: any) => { setSelectedDate(e); setStep(3); setSelectedTime(null); }}
                showNeighboringMonth={true}
                tileDisabled={(e) => checkIfDropzoneClosedOnThisDay(e)}
                value={selectedDate}
                view='month' />
        </div>;
    }

    const confirmationJSX = () => {
        return <div>
            <h4 className='fw-medium text-center'>You're Going Skydiving!</h4>
            <p className='text-center'>We just sent an email to {newReservation?.email} with all the details<br/>about your upcoming jump.</p>
            <p className='text-center'>If you have any questions contact <b className='text-primary'>support@onjumprun.com</b></p>
            <div className='align-items-center border-top d-flex gap-3 justify-content-center pt-4'>
                <h1 className='mb-0'>${newReservation?.amountPaid}</h1>
                <div>
                    <h5 className='fw-light mb-2'>
                        {newReservation?.paymentOption === 'deposit' ? 'Deposit Paid' : 'Paid in Full'}
                        <CheckCircleFill className='ms-2 text-success' />
                    </h5>
                    <h5 className='fw-light mb-0'>
                        Date: {new Date(newReservation?.date).toDateString()}
                        <CheckCircleFill className='ms-2 text-success' />
                    </h5>
                </div>
            </div>
        </div>;
    }

    const contentJSX = () => {
        switch (step) {
            case 5: return confirmationJSX();
            case 4: return <div className='px-3'>
                <AddTandemReservation hideRadios={false}
                    dropzone={dropzone}
                    newJumperInfo={newReservation}
                    numJumpers={numJumpers}
                    setNewJumperInfo={setNewReservation} />
                {numJumpers > 1 && additionalJumpersJSX()}
            </div>;
            case 3:
            case 2: return <div>
                {calendarJSX()}
                <div>
                    {availableTimeSlotsJSX()}
                </div>
            </div>;
            default: return <div>
                <Form.Group className='align-items-center d-flex gap-2 justify-content-center py-3'>
                    <Form.Label as='small' className='fw-medium mb-0'>Number of Jumpers: </Form.Label>
                    <Form.Control isInvalid={numJumpers <= 0 || isNaN(numJumpers) || numJumpers > 25}
                        max={25}
                        min={1}
                        onChange={(e: any) => setNumJumpers(e?.target?.value)}
                        placeholder='1'
                        style={{ width: '111px' }}
                        type='number'
                        value={numJumpers} />
                </Form.Group>
            </div>;
        }
    }

    const sliderBarJSX = () => {
        return <div className='p-3 pb-5'>
            <div className={['d-flex justify-content-between sliderBar w-100', calculateColors()].join(' ')}>
                <div className='position-relative'>
                    <div className='sliderBarButton' style={{ backgroundColor: step >= 1 ? '#0276ab' : 'lightgray' }} />
                    <h6 className='mt-3 opacity-50' style={{ fontSize: '12px' }}># of people</h6>
                </div>
                <div className='position-relative'>
                    <div className='sliderBarButton' style={{ backgroundColor: step >= 2 ? '#0276ab' : 'lightgray' }} />
                    <h6 className='mt-3 opacity-50' style={{ fontSize: '12px' }}>Pick a date</h6>
                </div>
                <div className='position-relative'>
                    <div className='sliderBarButton' style={{ backgroundColor: step >= 4 ? '#0276ab' : 'lightgray' }} />
                    <h6 className='mt-3 opacity-50' style={{ fontSize: '12px' }}>Add jumpers</h6>
                </div>
                <div className='position-relative'>
                    <div className='sliderBarButton' style={{ backgroundColor: step >= 5 ? '#0276ab' : 'lightgray' }} />
                    <h6 className='mt-3 opacity-50' style={{ fontSize: '12px' }}>Payment</h6>
                </div>
                <div className='position-relative'>
                    <div className='sliderBarButton' style={{ backgroundColor: step >= 5 ? '#0276ab' : 'lightgray' }} />
                    <h6 className='mt-3 opacity-50' style={{ fontSize: '12px' }}>Finish</h6>
                </div>
            </div>
        </div>;
    }

    const slotJSX = (input, idx) => {
        const taken = false;
        return <div key={`skydive-timeslot-${idx}`}>
            <Button className={['mb-1 w-100', taken ? 'align-items-center d-flex opacity-50' : ''].join(' ')} 
                disabled={taken} 
                onClick={() => setSelectedTime(input)} 
                variant={selectedTime === input ? 'primary' : taken ? 'outline-secondary' : 'light'}>
                {taken ? <>
                    <span style={{ width: '33.33%'}}></span>
                    <span style={{ width: '33.33%'}}>{input}</span>
                    <span className='fw-bold text-danger text-end underline' style={{ fontSize: '12px', width: '33.33%' }}>RESERVED</span>
                </>: input}
            </Button>
        </div>
    }

    return <div>
        <div className='fixed-center'>
            <Card>
                <Card.Header className='fw-medium'>
                    {step === 5 ? 'Woot Woot!' : `Let's Go Skydiving!`}
                </Card.Header>
                <Card.Body>
                    {step <= 4 && sliderBarJSX()}
                    {contentJSX()}
                </Card.Body>
                <Card.Footer className='d-flex justify-content-end p-2'>
                    <div className='d-flex gap-1'>
                        <Button hidden={step === 1 || step === 5}
                            onClick={() => step > 1 && setStep(step - 1)}
                            size='sm'
                            variant='light'>
                            Back
                        </Button>
                        {step === 4 ? <Payments additionalJumpers={additionalJumpers}
                            amount={newReservation?.paymentOption === 'full' ? numJumpers * dropzone?.pricing?.tandems?.tandemPrice : numJumpers * dropzone?.pricing?.tandems?.depositPrice}
                            disable={checkIfNextStepButtonDisabled()}
                            dropzoneId={dropzoneId}
                            newReservation={newReservation}
                            numParticipants={numJumpers}
                            saveReservation={saveReservation}
                            selectedTime={selectedTime} /> :
                            <Button disabled={checkIfNextStepButtonDisabled()}
                                hidden={step === 5}
                                onClick={() => nextStepClicked()}
                                size='sm'
                                variant={checkIfNextStepButtonDisabled() ? 'danger' : 'primary'}>
                                {buttonTitleJSX()}
                            </Button>}
                    </div>
                </Card.Footer>
            </Card>
        </div>
    </div>
}
export default BookTandem;