
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

interface ILogoProps {
    isLoading?: boolean,
    isMobile?: boolean,
    onlyText?: boolean, 
    xl?: boolean
}

const Logo = ({ isLoading, isMobile, onlyText, xl }: ILogoProps) => {

    //-----Pure JSX Functions-----//

    const dzoAppTextJSX = () => {
        return !onlyText && <h6 className={['mb-0 shimmer opacity-50 text-light', xl ? 'fs-5 pt-2 text-center w-100' : ''].join(' ')} style={{ fontWeight: '200' }}>
            The DZO App
            {isLoading && <Spinner animation='border' className='ms-2' size='sm' variant='warning' />}
        </h6>;
    }

    const imgJSX = () => {
        return !onlyText && <div className='text-center'>
            <img src='/logo.png' height={xl ? '169': '78'} style={{ opacity: '0.5' }} />
        </div>;
    }

    const textJSX = () => {
        return <h1 className={['mb-0', xl ? 'fw-light text-center' : 'onJumprun-h5'].join(' ')}>
            <span className='fw-light swellMe'>on</span>
            <span className={xl ? 'fw-light' : 'fw-medium'}>Jumprun</span>
        </h1>;
    }

    return <div>
        {isMobile ? <div className='text-light'>
            {imgJSX()}
            {textJSX()}
            {dzoAppTextJSX()}
        </div> : <div className='aTagNoDecoration'>
            <Link to='/'>
                <div className='text-light'>
                    {imgJSX()}
                    {textJSX()}
                    {dzoAppTextJSX()}
                </div>
            </Link>
        </div>}
    </div>;
}
export default Logo;