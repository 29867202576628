import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Logo from './Logo';

const PrivacyPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document?.documentElement?.setAttribute('data-bs-theme', 'dark');
    }, []);

    //-----Pure JSX Functions-----//

    return <div className='p-5'>
        <div className='fixed-center px-4' style={{ width: '59%' }}>
            <div>
                <h4 className='mb-4 text-center underline'>OnJumprun Privacy Policy</h4>
                <p className='opacity-75 text-center'>
                    <span className='fw-bold underline'>Data Sharing: </span> This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and 
                    tells You about Your privacy rights and how the law protects You. We DO NOT USE Your Personal data in any capacity. Your information will never be
                    shared outside of our web/mobile applications, nor will it ever be resold to any third party.<br /><br />
                </p>
                <p className='opacity-75 text-center'>
                    <span className='fw-bold underline'>Security: </span> The security of Your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
                    While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.<br /><br />
                </p>
                <p className='opacity-75 text-center'>
                    <span className='fw-bold underline'>Payments: </span> We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information
                    is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
                    effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.<br /><br />
                </p>
                <p className='opacity-75 text-center'>
                    <span className='fw-bold underline'>User Age Limitations:</span> Our Service does not address anyone under the age of 18.  If You are a parent or guardian and You are aware that Your child
                    has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information
                    from Our servers. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.
                </p>
                <p className='opacity-75 text-center'>
                    <span className='fw-bold underline'>Privacy Changes:</span> We may update our Privacy Policy from time to time.
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
            </div>
            <div className='d-flex justify-content-center mb-5 mt-2'>
                <Logo />
            </div>
        </div>
        <div className='d-flex fixed-bottom justify-content-end p-4'>
            <span className='cursor fw-bold underline' onClick={() => navigate('/')}>HOME</span>
        </div>
    </div>
}
export default PrivacyPage;