import { useContext, useEffect, useState } from 'react';

import { BackspaceFill, CheckCircleFill, ClockFill, FuelPumpFill, Trash3Fill, XCircleFill } from 'react-bootstrap-icons';
import { Badge, Button, Card, Collapse, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';
import moment from 'moment';

import ApiCalls from '../apiCalls';
import HoverXCircle from './HoverXCircle';
import { ILoadData } from '../models';
import OnJumprunContext from '../context';
//@ts-ignore
import soloIcon from './soloIcon.png';
//@ts-ignore
import tandyIcon from './tandyIcon.png';
import Utilities from '../utils';
//@ts-ignore
import whiteSoloIcon from './whiteSoloIcon.png';
//@ts-ignore
import whiteTandemIcon from './whiteTandyIcon.png';

const Load = ({
    activeTab,
    aircraft,
    didAddJumperToLoad,
    didDeleteJumper,
    didDeleteLoad,
    didSaveLoad,
    didUpdateCall,
    disable,
    dropzone,
    instructors,
    jumpers,
    load,
    manifestedLoads,
    markAsFuelLoad,
    payments,
    setDisable,
    setManifestedLoads,
    setSelectedJumper,
    tandems,
    updateLoadStatus }) => {

    const apiCalls = new ApiCalls();
    const { darkMode } = useContext(OnJumprunContext);
    const utils = new Utilities();

    const [call, setCall] = useState<any>(disable ? '' : utils?.diffInMins(load?.date));
    const [instructor, setInstructor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [jumpersWithWaiver, setJumpersWithWaiver] = useState([]);
    const [jumpType, setJumpType] = useState(null);
    const [loadSheet, setLoadSheet] = useState<ILoadData[]>(jumpers);
    const [newJumper, setNewJumper] = useState<any>(null);
    const [removingJumperModalData, setRemovingJumperModalData] = useState<any>(false);
    const [selectAircraft, setSelectAircraft] = useState(null);
    const [showAddJumper, setShowAddJumper] = useState(false);
    const [showAddTandem, setShowAddTandem] = useState(false);
    const [showChangeCall, setShowChangeCall] = useState(disable ? true : false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    useEffect(() => {
        return () => {
            cancelChangeLoadOrNewLoadClicked();
        }
    }, [activeTab]);

    useEffect(() => {
        getJumpersWaiverFromAPI();
    }, []);

    useEffect(() => {
        setIsLoading(false);
    }, [load?.status]);

    useEffect(() => {
        if (!showAddJumper && newJumper?.firstName && newJumper?.lastName) {
            setTimeout(() => setLoadSheet([...loadSheet, jumpers?.find(j => j?.firstName === newJumper?.firstName && j?.lastName === newJumper?.lastName)]), 420);
        }
        if (showAddJumper) setJumpType('fullAltitude');
    }, [showAddJumper]);

    useEffect(() => {
        if (showAddTandem) setJumpType(null);
    }, [showAddTandem]);

    const addJumperClicked = () => {
        const studentJumper = ((jumpType === 'aff' || jumpType === 'iad' || jumpType === 'coachJump') && instructor);
        const jumpersOnLoad = studentJumper ?
            [
                { ...newJumper, jumpType, instructorName: instructor?.firstName ? `${instructor?.firstName} ${instructor?.lastName}` : '' },
                { ...instructor, jumpType: jumpType === 'aff' ? 'AFF-I' : jumpType === 'iad' ? 'IAD' : 'TI' }
            ] :
            [
                { ...newJumper, jumpType }
            ];
        setShowAddJumper(false);
        didAddJumperToLoad({ ...load, loadSheet: load?.loadSheet?.length > 0 ? [...load?.loadSheet, ...jumpersOnLoad] : [...jumpersOnLoad] }, newJumper, jumpType);
        setNewJumper(null);
        setInstructor(null);
        setJumpType(null);
    }

    const addTandemClicked = () => {
        if (!newJumper || !instructor) return;
        const jumpersOnLoad = [
            { ...newJumper, jumpType: 'Tandem Student', instructorName: instructor?.firstName + ' ' + instructor.lastName }, 
            { ...instructor, jumpType: 'TI' }
        ];
        didAddJumperToLoad({ ...load, loadSheet: load?.loadSheet?.length > 0 ? [...load.loadSheet, ...jumpersOnLoad] : jumpersOnLoad }, { ...newJumper, jumperType: 'Tandem Student' }, jumpType);
        setShowAddTandem(false);
        setNewJumper(null);
        setInstructor(null);
        setJumpType(null);
    }

    const calculateBadgeContent = () => {
        if (load?.status === 'Departed') return 'DEPARTED!';
        const loadCall = utils?.diffInMins(load?.date);
        if (loadCall === 0 || call === 1) return 'NOW CALL!';
        return `${loadCall} minute call`;
    }

    const calculateColorBasedOffHowLongCallIsFromNow = () => {
        if (load?.status === 'Departed') return 'secondary';
        const diff = utils?.diffInMins(load?.date);
        if (diff <= 0) return 'secondary';
        if (call <= 5) return 'danger';
        if (call <= 10) return 'warning';
        if (call <= 20) return 'primary';
        return 'success';
    }

    const cancelAddJumperClicked = () => {
        setShowAddJumper(!showAddJumper);
        setJumpType(null);
        setNewJumper(null);
        setInstructor(null);
    }

    const cancelAddTandemClicked = () => {
        setShowAddTandem(false);
        setInstructor(null);
        setNewJumper(null);
        setJumpType(null);
    }

    const cancelChangeLoadOrNewLoadClicked = () => {
        if (disable) {
            deleteLoadClicked();
            return;
        }
        setShowChangeCall(false);
        setSelectAircraft(null);
        setCall(0);
    }

    const convertJumpTypeToString = (getPrice?) => {
        if(jumpType === 'tandemPrice') return getPrice ? dropzone?.pricing?.tandems?.tandemPrice : 'Tandem - No Media';
        if(jumpType === 'withDSP') return getPrice ? dropzone?.pricing?.tandems?.withDSP :  'Tandem - Working DSP';
        if(jumpType === 'withStills') return getPrice ? dropzone?.pricing?.tandems?.withStills :  'Tandem - Still Photos';
        if(jumpType === 'withStillsAndVideo') return getPrice ? dropzone?.pricing?.tandems?.withStillsAndVideo :  'Tandem - Stills & Video';
        if(jumpType === 'withVideo') return getPrice ? dropzone?.pricing?.tandems?.withVideo :  'Tandem - Video';
        if(jumpType === 'fullAltitude') return getPrice ? dropzone?.pricing?.funJumpers?.fullAltitude : 'Solo - Full Altitude';
        if(jumpType === 'hopNPop') return getPrice ? dropzone?.pricing?.funJumpers?.hopNPop : 'Solo - Hop & Pop';
        if(jumpType === 'withGearRental') return getPrice ? dropzone?.pricing?.funJumpers?.withGearRental : 'Sport - Renting Gear';
        if(jumpType === 'coachJump') return getPrice ? dropzone?.pricing?.funJumpers?.coachJump : 'Coach Jump';
        if(jumpType === 'aff') return getPrice ? dropzone?.pricing?.students?.add : 'AFF';
        return dropzone?.pricing?.tandems?.tandemPrice;
    }

    const convertJumpTypeToTitle = (type) => {
        if(type === 'aff') return 'AFF';
        if(type === 'coachJump') return 'Coach Jump';
        if(type === 'fullAltitude') return 'Solo - Full Altitude';
        if(type === 'hopNPop') return 'Solo - Hop & Pop';
        if(type === 'withGearRental') return 'Solo - Renting Gear';
        return type;
    }

    const deleteJumperClicked = (j, i, k, s) => {
        didDeleteJumper(load, j, i, k, s);
        setRemovingJumperModalData(false);
    }

    const deleteLoadClicked = () => {
        if (disable) {
            const idx = manifestedLoads?.findIndex(f => f?.name === load?.name);
            setManifestedLoads(manifestedLoads?.length === 1 ? [] : [...manifestedLoads.slice(0, idx), ...manifestedLoads.slice(idx + 1, manifestedLoads?.length)]);
        }
        setShowConfirmDeleteModal(false);
        !disable && didDeleteLoad(load);
        setDisable(false);
    }

    const getJumpersWaiverFromAPI = async () => {
        const arr = [];
        jumpers?.filter(f => f?.jumperType !== 'Tandem').forEach(async j => {
            const jumpersWaiver = await apiCalls.getSignedWaiverByDropzoneIdAndPhone(dropzone?.id, j?.phone || '');
            if(jumpersWaiver?.length > 0) arr.push({ ...j, waiverId: jumpersWaiver[0]?.id });
            else arr.push(j);
        });
        setJumpersWithWaiver(arr);
    }

    const saveLoadClicked = () => {
        if (disable) didSaveLoad(load, call, selectAircraft);
        else showChangeCall && didUpdateCall({ ...load, call: load?.call ? load.call : 0 }, call);
        setShowChangeCall(!showChangeCall);
        setDisable(false);
    }

    const updateStatusClicked = (newStatus) => {
        setIsLoading(true);
        updateLoadStatus(load, newStatus);
    }

    //-----Pure JSX Functions-----//

    const addJumperToLoadJSX = () => {
        const disableSave = (!jumpType && !newJumper && newJumper?.firstName !== 'Slot filler') || (jumpType === 'coachJump' && !instructor);
        const index = isNaN(load?.loadSheet?.length + 1) ? 1 : load?.loadSheet?.length + 1;
        return <Collapse in={showAddJumper}>
            <div>
                <div className='d-flex gap-3 justify-content-between p-3'>
                    <div className='d-flex gap-2 justify-content-between w-100'>
                        <div className='d-flex gap-2'>
                            <div className='fw-bold ps-2 pe-5'>
                                <u>{index}.</u>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle className={['px-3', !jumpType && !newJumper?.firstName ? 'shimmer' : ''].join(' ')} size='sm'
                                        variant={darkMode ? newJumper?.firstName ? 'primary' : 'danger' : newJumper?.firstName ? 'primary' : 'outline-danger'}>
                                        {newJumper?.firstName ? `${newJumper?.firstName} ${newJumper?.lastName}` : 'Select jumper'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                                        {jumpersWithWaiver?.map((j, i) => {
                                            const isOnLoad = load?.loadSheet?.findIndex(f => f?.phone === j?.phone) > -1;
                                            const rowIcon = calcJumperRowIconJSX(j);
                                            return <Dropdown.Item disabled={!rowIcon?.valid} key={`onjumprun-dropdown-${i}`}
                                                onClick={() => !isOnLoad && setNewJumper(j)}>
                                                <div className='align-items-center d-flex gap-3 justify-content-between'>
                                                    <span className={[isOnLoad ? 'opacity-25' : ''].join(' ')}>{j?.firstName} {j?.lastName}</span>
                                                    {isOnLoad ? <span className='fw-bold text-danger' style={{ fontSize: '12px' }}>(ON LOAD)</span> : rowIcon?.html}
                                                </div>
                                            </Dropdown.Item>
                                        })}
                                        {!showAddTandem && <Dropdown.Item onClick={() => setNewJumper({ ...newJumper, jumperType: 'Sport Jumper', firstName: 'Slot filler', lastName: '' })}>
                                            Slot filler
                                        </Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {newJumper?.jumperType === 'Student' && <div>
                                {instructorsJSX()}
                            </div>}
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle className={['px-3', !jumpType && newJumper?.firstName ? 'shimmer' : ''].join(' ')} 
                                        hidden={newJumper?.firstName === 'Slot filler'} size='sm' variant={jumpType ? 'primary' : 'outline-danger'}>
                                        {jumpType || 'Type of jump'}
                                    </Dropdown.Toggle>
                                    {newJumper?.jumperType === 'Sport Jumper' && <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType(`hopNPop`)}>
                                            Hop & Pop <span className='opacity-50'>${dropzone?.pricing?.funJumpers?.hopNPop}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('fullAltitude')}>
                                            Full Altitude <span className='opacity-50'>${dropzone?.pricing?.funJumpers?.fullAltitude}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('withGearRental')}>
                                            Gear Rental <span className='opacity-50'>${dropzone?.pricing?.funJumpers?.withGearRental}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('coachJump')}>
                                            Coach Jump <span className='opacity-50'>${dropzone?.pricing?.funJumpers?.coachJump}</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>}
                                    {newJumper?.jumperType === 'Student' && <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType(`aff`)}>
                                            AFF <span className='opacity-50'>${dropzone?.pricing?.students?.aff}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('iad')}>
                                            IAD <span className='opacity-50'>${dropzone?.pricing?.students?.iad}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('supervisedSolo')}>
                                            Supervised Solo <span className='opacity-50'>${dropzone?.pricing?.students?.supervisedSolo}</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>}
                                </Dropdown>
                            </div>
                            {jumpType === 'coachJump' && <div>
                                {instructorsJSX()}
                            </div>}
                        </div>
                        <div>
                            <Button className={['align-items-center d-flex gap-2', disableSave ? 'opacity-50' : 'fw-bold'].join(' ')} disabled={disableSave}
                                onClick={() => addJumperClicked()} variant={darkMode ? 'light' : 'primary'} size='sm'>
                                Add jumper to load
                                {!disableSave && <CheckCircleFill className='text-light pulseFont' />}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>;
    }

    const addTandemToLoadJSX = () => {
        const disableSaveBtn = !jumpType || !instructor;
        return <Collapse in={showAddTandem}>
            <div>
                <div className='d-flex gap-3 justify-content-between p-3'>
                    <div className='d-flex gap-2 justify-content-between w-100'>
                        <div className='align-items-center d-flex gap-2'>
                            <div className='fw-bold pe-4 ps-2'>
                                {(isNaN(load?.loadSheet?.length) ? 0 : load.loadSheet.length) + 1}.
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle size='sm' variant={darkMode ? newJumper ? 'light' : 'outline-danger' : newJumper ? 'primary' : 'outline-danger'}>
                                        {newJumper?.firstName ? <span><span className='fw-bold'>Passenger</span>: {`${newJumper?.firstName} ${newJumper?.lastName}`}</span> : 'Select tandem student'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                                        {tandems?.map((j, i) => {
                                            const isOnLoad = load?.loadSheet?.findIndex(f => f?.phone === j?.phone) > -1;
                                            const rowIcon = calcJumperRowIconJSX(j);
                                            return <Dropdown.Item disabled={!rowIcon?.valid} key={`tandem-list-${i}`} onClick={() => !isOnLoad && setNewJumper(j)}>
                                                <div className='align-items-center d-flex gap-3 justify-content-between'>
                                                    <span className={[isOnLoad ? 'opacity-25' : ''].join(' ')}>{j?.firstName} {j.lastName}</span>
                                                    {isOnLoad ? <span className='fw-bold text-danger' style={{ fontSize: '12px' }}>(ON LOAD)</span> : rowIcon?.html}
                                                </div>
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                {instructorsJSX()}
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle size='sm' variant={darkMode ? jumpType ? 'light' : 'outline-danger' : jumpType ? 'primary' : 'outline-danger'}>
                                        {jumpType ? convertJumpTypeToString() : 'Type of jump'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                                        {dropzone?.pricing?.tandems?.tandemPrice > 0 && <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType(`tandemPrice`)}>
                                            Tandem - No Media <span className='fw-medium underline'>${dropzone.pricing.tandems.tandemPrice}</span>
                                        </Dropdown.Item>}
                                        {dropzone?.pricing?.tandems?.withStills > 0 && <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('withStills')}>
                                            Tandem - Stills Only <span className='fw-medium underline'>${dropzone.pricing.tandems.withStills}</span>
                                        </Dropdown.Item>}
                                        {dropzone?.pricing?.tandems?.withVideo > 0 && <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('withVideo')}>
                                            Tandem - Video Only <span className='fw-medium underline'>${dropzone.pricing.tandems.withVideo}</span>
                                        </Dropdown.Item>}
                                        {dropzone?.pricing?.tandems?.withStillsAndVideo > 0 && <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('withStillsAndVideo')}>
                                            Tandem - Stills & Video <span className='fw-medium underline'>${dropzone.pricing.tandems.withStillsAndVideo}</span>
                                        </Dropdown.Item>}
                                        {dropzone?.pricing?.tandems?.withDSP && <Dropdown.Item className='d-flex gap-2 justify-content-between' onClick={() => setJumpType('withDSP')}>
                                            Tandem - Working DSP <span className='fw-medium underline'>${dropzone.pricing.tandems.withDSP}</span>
                                        </Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div>
                            <Button className={['align-items-center d-flex gap-2', disableSaveBtn ? '' : 'fw-bold'].join(' ')}
                                size='sm' disabled={!newJumper || !instructor || !jumpType}
                                onClick={() => addTandemClicked()}
                                variant={!newJumper || !instructor ? 'danger' : 'primary'}>
                                Add tandem to load
                                {!disableSaveBtn && <CheckCircleFill className='text-light pulseFont' />}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>;
    }

    const calcJumperRowIconJSX = (j) => {
        const balance = utils?.calculateJumperBalance(payments.filter(p => j?.phone == p?.phone), true);
        if(!j?.waiverId) return {
            html: <span className='fw-bold text-danger underline' style={{ fontSize: '12px' }}>WAIVER NOT SIGNED</span>,
            valid: false
        };
        if(j?.jumperType === 'Sport Jumper' && (moment(new Date()).diff(j?.reserveRepackDate, 'days') < 0 || moment(new Date()).diff(j?.reserveRepackDate, 'days') >= 180)) {
            return {
                html: <span className='fw-bold text-danger underline' style={{ fontSize: '12px' }}>RESERVE EXPIRED</span>,
                valid: false
            };
        }
        if(j?.jumperType === 'Sport Jumper' && moment(new Date()).diff(j?.uspaExp, 'days') > 0) {
            return {
                html: <span className='fw-bold text-danger underline' style={{ fontSize: '12px' }}>USPA EXPIRED</span>,
                valid: false
            };
        }
        if (balance < convertJumpTypeToString(true)) return {
            html: <span className='fw-bold text-danger underline' style={{ fontSize: '12px' }}>INSUFFICIENT FUNDS</span>,
            valid: false
        };
        return { html: <CheckCircleFill className='opacity-75 shimmer text-success' />, valid: true };
    }

    const calculateRowColorJSX = (type) => {
        if (type === 'Student' || type === 'AFF/I' || type === 'TI' || type === 'Tandem Student') return 'red-row';
        if (type === 'Supervised Solo') return 'green-row';
        return '';
    }

    const cancelMarkAsFuelAndDepartedButtonsJSX = () => {
        return <Collapse in={!showAddJumper && !showAddTandem && !showChangeCall}>
            <div>
                <div className='d-flex gap-2 justify-content-end p-3'>
                    {load?.status !== 'Departed' && <div>
                        <Button className='align-items-center border-bottom d-flex fw-bold gap-2 opacity-75'
                            hidden={!load?.id || load?.status === 'Departed'}
                            onClick={() => setShowConfirmDeleteModal(true)}
                            size='sm'
                            variant={darkMode ? 'dark' : 'light'}>
                            Cancel Load <Trash3Fill className='text-danger' />
                        </Button>
                    </div>}
                    <div>
                        {load?.status !== 'Departed' && load?.id && <Button className='align-items-center border-bottom d-flex fw-bold gap-2 opacity-75'
                            onClick={() => markAsFuelLoad(load)}
                            size='sm'
                            variant={load?.fuelLoad ? darkMode ? 'light' : 'dark' : darkMode ? 'dark' : 'light'}>
                            {load?.fuelLoad ? 'UNDO FUEL LOAD' : 'Mark as Fuel Load'}
                            {!load?.fuelLoad ? <FuelPumpFill /> : <CheckCircleFill className='text-primary' />}
                        </Button>}
                    </div>
                    {load?.id && <div className='d-flex'>
                        <Collapse dimension='width' in={load?.status === 'Departed'}>
                            <div>
                                <Button className='align-items-center d-flex fw-bold gap-1 opacity-75'
                                    disabled={isLoading}
                                    onClick={() => updateStatusClicked('Building')}
                                    size='sm' variant={darkMode ? 'outline-light' : 'outline-dark'}>
                                    UNDO DEPARTED STATUS {isLoading ? <Spinner animation='border' size='sm' /> :
                                        <BackspaceFill className={darkMode ? 'text-light' : 'text-danger'} />}
                                </Button>
                            </div>
                        </Collapse>
                        <Collapse dimension='width' in={load?.status !== 'Departed'}>
                            <div>
                                <Button className='align-items-center border-bottom d-flex fw-bold gap-2 opacity-75'
                                    disabled={isLoading}
                                    onClick={() => updateStatusClicked('Departed')}
                                    size='sm' variant={darkMode ? 'dark' : 'light'}>
                                    Mark Load as Departed {isLoading ? <Spinner animation='border' size='sm' /> :
                                        <CheckCircleFill className={darkMode ? 'text-light' : 'text-success'} />}
                                </Button>
                            </div>
                        </Collapse>
                    </div>}
                </div>
            </div>
        </Collapse>;
    }

    const deleteJumperModalJSX = () => {
        return <Modal centered show={removingJumperModalData}>
            <Modal.Header>
                <Modal.Title>Remove jumper from load</Modal.Title>
            </Modal.Header>
            <Modal.Body>Removing this jumper from this load will automatically refund their account for the jump price. Are you sure you want to do this?</Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant='outline-secondary' onClick={() => setRemovingJumperModalData(false)}>
                    Cancel
                </Button>
                <Button onClick={() => deleteJumperClicked(
                    removingJumperModalData.j,
                    removingJumperModalData.i,
                    removingJumperModalData.instructorName,
                    removingJumperModalData.studentName
                )} size='sm' variant='danger'>
                    Remove jumper from load
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const deleteLoadModalJSX = () => {
        const canDelete = load?.loadSheet?.length > 0 ? false : true;
        return <Modal centered show={showConfirmDeleteModal}>
            <Modal.Header>
                <Modal.Title>Cancel Load {load?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={canDelete ? '' : 'fw-bold text-center text-danger'}>
                {canDelete ? 'Are you sure you want to do this? It will automagically refund all jumpers on the load. You cannot undo this.' : 
                `You can't delete this load until you remove everyone on the loadsheet.`}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowConfirmDeleteModal(false)} size='sm' variant={canDelete ? 'light' : 'danger'}>
                    Back
                </Button>
                {canDelete && <Button className='align-items-center d-flex fw-bold gap-1 underline'
                    onClick={deleteLoadClicked}
                    size='sm'
                    variant='danger'>
                    Cancel Load<XCircleFill />
                </Button>}
            </Modal.Footer>
        </Modal>;
    }

    const instructorsJSX = () => {
        return <Dropdown>
            <Dropdown.Toggle size='sm' variant={darkMode ? newJumper ? 'light' : 'outline-danger' : instructor ? 'primary' : 'outline-danger'}>
                {instructor?.firstName ? <span>
                    <span className='fw-bold'>Instructor</span>: {`${instructor?.firstName} ${instructor?.lastName}`}
                </span> : 'Select instructor'}
            </Dropdown.Toggle>
            <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                {instructors?.map((j, i) => {
                    const isOnLoad = load?.loadSheet?.findIndex(f => f?.firstName === j?.firstName && f?.lastName === j?.lastName) > -1;
                    return <Dropdown.Item key={`onjumprun-dropdown-${i}`} onClick={() => !isOnLoad && setInstructor({ ...j, studentName: newJumper?.firstName + ' ' + newJumper?.lastName })}>
                        <div className='align-items-center d-flex gap-2 justify-content-between'>
                            <span className={[isOnLoad ? 'opacity-25' : ''].join(' ')}>{j?.firstName} {j?.lastName}</span>
                            {isOnLoad && <span className='fw-bold text-danger' style={{ fontSize: '12px' }}>(ON LOAD)</span>}
                        </div>
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>;
    }

    const loadNameAndButtonsJSX = () => {
        const notEditingLoad = (!showChangeCall && !showAddJumper && !showAddTandem);
        return <div className='align-items-center d-flex justify-content-between'>
            <div className={load?.status !== 'Departed' ? 'd-flex gap-3 w-100' : ''}>
                <Collapse dimension='width' in={showChangeCall}>
                    <div>
                        <div className='align-items-center d-flex gap-2'>
                            <h6 className='fw-bold mb-0 no-word-wrap'>
                                {disable ? <span>
                                    {call > 0 ? <><CheckCircleFill className='me-1 text-primary' /> Load call:</> :
                                        <span className='text-danger'>Set load call:</span>}
                                </span> : <>
                                    {showChangeCall ? <span className='text-danger'>Update call <span className='fw-light'>(currently {utils?.diffInMins(load?.date)} min)</span>: </span> : 'New Call: '}
                                </>}
                            </h6>
                            <Form.Control autoFocus
                                className='me-1'
                                defaultValue=''
                                min={0}
                                onChange={(e: any) => {
                                    e?.preventDefault();
                                    setCall(e?.target?.value);
                                }} size='sm'
                                style={{ width: '80px' }}
                                type='number' />
                        </div>
                    </div>
                </Collapse>
                <h5 className={['align-items-center d-flex mb-0', load?.status === 'Departed' ? 'justify-content-between' : ''].join(' ')}>
                    {!load?.date ? selectAircraftJSX() : <>
                        {!showChangeCall && <span className='me-2'>{load?.name}</span>}
                        <Collapse dimension='width' in={notEditingLoad}>
                            <div>
                                <Badge bg={calculateColorBasedOffHowLongCallIsFromNow()}
                                    className={['px-3', load?.status === 'Departed' ? 'opacity-50' : ''].join(' ')} 
                                    pill>
                                    {calculateBadgeContent()}
                                </Badge>
                            </div>
                        </Collapse>
                        <Collapse dimension='width' in={load?.fuelLoad && notEditingLoad}>
                            <div>
                                <span className='no-word-wrap shimmer text-danger'>
                                    <FuelPumpFill className='mx-2' />
                                    <u>FUEL LOAD</u>
                                </span>
                            </div>
                        </Collapse>
                    </>}
                </h5>
            </div>
            <div>
                <Collapse dimension='width' in={load?.status !== 'Departed'}>
                    <div>
                        <div className='d-flex'>
                            <Collapse dimension='width' in={showAddTandem}>
                                <div>
                                    <Button className='ms-1'
                                        onClick={() => cancelAddTandemClicked()}
                                        size='sm'
                                        variant='danger'>
                                        Cancel add tandem
                                    </Button>
                                </div>
                            </Collapse>
                            <Collapse dimension='width' in={!showAddJumper && !showAddTandem}>
                                <div className={['d-flex gap-1', notEditingLoad ? 'border-right' : ''].join(' ')}>
                                    <Button className='fw-light'
                                        hidden={!disable && !showChangeCall}
                                        onClick={() => cancelChangeLoadOrNewLoadClicked()}
                                        size='sm'
                                        variant={darkMode ? 'dark' : 'light'}>
                                        Cancel
                                    </Button>
                                    <Button className='fw-bold me-1'
                                        onClick={() => saveLoadClicked()} size='sm'
                                        style={{ backgroundColor: !darkMode && !showChangeCall && '#d3d4d5' }}
                                        disabled={disable && (!call || !selectAircraft)} hidden={showAddJumper || showAddTandem}
                                        variant={showChangeCall ? disable ? (selectAircraft && call > 0 ? 'success' : 'danger') : 'primary' : darkMode ? 'dark' : 'light'}>
                                        {showChangeCall ? disable ? 'Save new load' : 'Update call' : 'Change call'}
                                        {!showChangeCall && <ClockFill className='ms-2' />}
                                    </Button>
                                </div>
                            </Collapse>
                            <Collapse dimension='width' in={!showChangeCall && !showAddTandem}>
                                <div>
                                    <div className={['d-flex gap-1', notEditingLoad ? 'border-right' : ''].join(' ')}>
                                        <div className='d-flex gap-1'>
                                            <Button className={['me-1', showAddJumper ? '' : 'fw-bold', 'ms-1'].join(' ')}
                                                onClick={() => cancelAddJumperClicked()}
                                                size='sm'
                                                style={{ backgroundColor: !darkMode && !showAddJumper && '#d3d4d5' }}
                                                variant={darkMode ? 'dark' : showAddJumper ? 'danger' : 'light'}>
                                                {showAddJumper ? 'Cancel adding jumper' : 'Add solo jumper'}
                                                {!showAddJumper && <img className='ms-2' 
                                                    src={darkMode ? whiteSoloIcon : soloIcon} 
                                                    style={{ height: '20px', width: '20px', }} />}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse dimension='width' in={notEditingLoad}>
                                <div>
                                    <Button className='fw-bold ms-1'
                                        onClick={() => setShowAddTandem(true)}
                                        size='sm' style={{ backgroundColor: !darkMode && !showAddTandem && '#d3d4d5' }}
                                        variant={showAddTandem ? 'outline-danger' : darkMode ? 'dark' : 'light'}>
                                        Add tandem {!showAddTandem && <img className='ms-1' src={darkMode ? whiteTandemIcon : tandyIcon} style={{ height: '20px', width: '20px', }} />}
                                    </Button>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>;
    }

    const loadsheetTableJSX = () => {
        const rowClickable = load?.status !== 'Departed';
        return <div>
            <Table striped={load?.loadSheet?.length > 0} responsive className='mb-0' style={{ borderCollapse: 'inherit', borderSpacing: '0px' }}>
                <thead>
                    <tr>
                        <th className='ps-4'>Jumper Name</th>
                        <th>Jump Type</th>
                        <th>Wing Loading</th>
                        <th className='pe-4 text-end'>Actions</th>
                    </tr>
                </thead>
                <tbody className={!rowClickable ? 'opacity-25' : ''} style={{ borderRadius: '5px !important' }}>
                    {load?.loadSheet?.length > 0 ? load.loadSheet.map((loadJumper, i) => {
                        return <tr className={[calculateRowColorJSX(loadJumper?.jumpType)].join(' ')} key={`load-sheet-${i}`}>
                            <td className={[(i + 1) === load?.loadSheet?.length ? 'onJumprun-row-left' : '', 'fw-bold ps-4'].join(' ')}
                                onClick={() => rowClickable && setSelectedJumper(loadJumper)}>{loadJumper?.firstName} {loadJumper?.lastName}</td>
                            <td onClick={() => rowClickable && setSelectedJumper(loadJumper)}>{convertJumpTypeToTitle(loadJumper?.jumpType)}</td>
                            <td onClick={() => rowClickable && setSelectedJumper(loadJumper)}>{loadJumper?.wingLoading}</td>
                            <td className={[(i + 1) === load?.loadSheet?.length ? 'onJumprun-row-right' : '', 'pe-4'].join(' ')}>
                                {rowClickable && <div className='delete-row-hover d-flex justify-content-end pt-1'
                                    onClick={() => rowClickable && setRemovingJumperModalData({ j: loadJumper, i: i, instructorName: loadJumper?.instructorName || null, studentName: loadJumper?.studentName || null })}>
                                    <HoverXCircle />
                                </div>}
                            </td>
                        </tr>
                    }) : <tr>
                        <th colSpan={4}>
                            <p className='my-4 text-center fw-light'>No jumpers on this load yet</p>
                        </th>
                    </tr>}
                </tbody>
            </Table>
            {addJumperToLoadJSX()}
            {addTandemToLoadJSX()}
            {cancelMarkAsFuelAndDepartedButtonsJSX()}
        </div>;
    }

    const selectAircraftJSX = () => {
        return <div className='align-items-center d-flex gap-1'>
            <h6 className='fw-bold mb-0 no-word-wrap'>
                {selectAircraft ? <span><CheckCircleFill className='me-1 text-primary' />Aircraft:</span> : 
                    <span className='text-danger'>Select Aircraft:</span>}
            </h6>
            <Dropdown>
                <Dropdown.Toggle className='px-4' size='sm' variant={darkMode ? 'outline-light' : selectAircraft?.aircraftType ? 'outline-primary' : 'outline-danger'}>
                    {(selectAircraft?.nickName || selectAircraft?.aircraftType) || 'Select aircraft'}
                </Dropdown.Toggle>
                <Dropdown.Menu variant={darkMode ? 'dark' : 'light'}>
                    {aircraft?.map((j, i) => <Dropdown.Item key={`aircraft-dropdown-${i}`} onClick={() => setSelectAircraft(j)}>
                        <div className='d-flex gap-3 justify-content-between'>
                            <span>{j?.nickName}</span>
                            <span className='fw-light'>{j?.tailNumber}</span>
                        </div>
                    </Dropdown.Item>)}
                    <Dropdown.Item key='aircraft-dropdown-other' onClick={() => setSelectAircraft({ aircraftType: 'Other', tailNumber: '', minNumJumpers: 0, maxNumJumpers: 100, id: 1 })}>
                        Other
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>;
    }

    return <div className='pb-3'>
        <div>
            <Card className='border-secondary shadow-lg' style={{ borderRadius: '8px' }}>
                <Card.Header className='pe-2'>
                    {loadNameAndButtonsJSX()}
                </Card.Header>
                <Card.Body className='p-0'>
                    {loadsheetTableJSX()}
                </Card.Body>
            </Card>
            {deleteLoadModalJSX()}
            {deleteJumperModalJSX()}
        </div>
    </div>;
}
export default Load;