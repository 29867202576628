import { useEffect, useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ApiCalls from '../apiCalls';
import Footer from './Footer';
import Header from './Header';
import Logo from './Logo';

const ContactPage = ({ setToastMessage }) => {
    const apiCalls = new ApiCalls();
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        document?.documentElement?.setAttribute('data-bs-theme', 'dark');
    }, []);

    const cancelClicked = () => {
        setMessage('');
        setName('');
        navigate('/');
    }

    const saveContactFormSubmission = async () => {
        if (name === '' || message === '') return;
        await apiCalls.addContactForm({ message, name });
        setToastMessage({ message: 'Message sent! One of our team members will be reaching out shortly.', variant: 'primary' });
        setTimeout(() => cancelClicked(), 555);
    }

    //-----Pure JSX Functions-----//

    const formJSX = () => {
        const disableSave = (name === '' || message === '');
        return <div className='align-items-center d-flex fadeInSlow justify-content-center' style={{ height: 'calc(97vh - 53px)' }}>
            <div style={{ width: '41vw' }}>
                <h4 className='mb-3 text-center text-light underline'>Contact Support</h4>
                <p className='fw-medium mb-4 text-center text-secondary' style={{ fontSize: '14px' }}>
                    We pinky promise we'll respond within 24 hours!<br />Unless we go in... Then you're on your own.
                </p>
                <div className='pb-2'>
                    <Form.Control onChange={e => setName(e?.target?.value)} placeholder='Your name & email..' value={name} />
                </div>
                <div className='pb-1'>
                    <Form.Control as='textarea'
                        onChange={e => setMessage(e?.target?.value)}
                        placeholder='Enter your message here...'
                        rows={6}
                        value={message} />
                </div>
                <div className='d-flex justify-content-end mb-5'>
                    <div>
                        <Button className={(name !== '' || message !== '') ? '' : 'opacity-50'} onClick={() => cancelClicked()} variant='dark'>Cancel</Button>
                    </div>
                    <div>
                        <Button className={disableSave ? 'opacity-50' : ''}
                            onClick={() => saveContactFormSubmission()}
                            variant={disableSave ? 'outline-secondary' : 'secondary'}>
                            Submit Form
                        </Button>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <Logo />
                </div>
            </div>
        </div>;
    }

    return <>
        <div>
            <Header activePage='CONTACT' />
            {formJSX()}
        </div>
        <Footer />
    </>;
}
export default ContactPage;