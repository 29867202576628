import { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';

import { IDropzoneData, ITandemStudentData } from '../models';
import MMDDYYYY from './MMDDYYYY';
import PhoneNumber from './PhoneNumber';
import Utilities from '../utils';

interface IAddTandemReservation {
    activeInput?: string,
    clickedOn?: any[],
    dropzone: IDropzoneData,
    hideRadios: boolean,
    newJumperInfo: ITandemStudentData,
    numJumpers: number,
    setActiveInput?: (val: string) => void,
    setNewJumperInfo: (val: any) => void
}

const AddTandemReservation = ({ activeInput, clickedOn, dropzone, hideRadios, newJumperInfo, numJumpers, setActiveInput, setNewJumperInfo }: IAddTandemReservation) => {
    const utils = new Utilities();

    const [phoneTaken, setPhoneTaken] = useState(false);

    useEffect(() => {
        return () => {
            setActiveInput('First name');
        }
    }, []);

    //-----Pure JSX Functions-----//

    const mainContentJSX = () => {
        const emailInvalid = (activeInput === 'Email' && newJumperInfo?.email?.length < 10) || (newJumperInfo?.email?.length <= 10 && clickedOn?.find(f => f === 'Email'));
        const emergencyContactInvalid = ((activeInput === 'Emergency Contact' && newJumperInfo?.emergencyContact?.length <= 4)) || (clickedOn?.find(f => f === 'Emergency Contact') && newJumperInfo?.emergencyContact?.length < 4) || utils?.stringHasNumber(newJumperInfo?.emergencyContact);
        const firstNameInvalid = (activeInput === 'First name' && newJumperInfo?.firstName?.length < 3) || utils?.stringHasNumber(newJumperInfo?.firstName) || (newJumperInfo?.firstName?.length < 3 && clickedOn?.find(f => f === 'First name'));
        const isFat = (newJumperInfo?.weight < 50 || newJumperInfo?.weight > 300);
        const lastNameInvalid = (activeInput === 'Last name' && newJumperInfo?.lastName?.length < 4) || utils?.stringHasNumber(newJumperInfo?.lastName) || (newJumperInfo?.lastName?.length < 4 && clickedOn?.find(f => f === 'Last name'));
        const weightInvalid = activeInput === 'Weight' && (isFat) || (isFat && clickedOn?.find(f => f === 'Weight'));

        return <div>
            <div className='px-2'>
                <div className='d-flex gap-2'>
                    <Form.Group className='flex-fill mb-2'>
                        <Form.Label as='small' className='fw-bold mb-0'>
                            FIRST NAME
                        </Form.Label>
                        <Form.Control autoFocus
                            isInvalid={firstNameInvalid}
                            isValid={!firstNameInvalid && clickedOn?.find(f => f === 'First name')}
                            onChange={(e) => setNewJumperInfo({ ...newJumperInfo, firstName: e?.target?.value })}
                            onFocus={() => setActiveInput('First name')}
                            type='text'
                            value={newJumperInfo.firstName} />
                    </Form.Group>
                    <Form.Group className='flex-fill mb-2'>
                        <Form.Label as='small' className='fw-bold mb-0'>
                            LAST NAME
                        </Form.Label>
                        <Form.Control isInvalid={lastNameInvalid}
                            isValid={!lastNameInvalid && clickedOn?.find(f => f === 'Last name')}
                            onChange={(e) => setNewJumperInfo({ ...newJumperInfo, lastName: e?.target?.value })}
                            onFocus={() => setActiveInput('Last name')}
                            type='text'
                            value={newJumperInfo.lastName} />
                    </Form.Group>
                </div>
                <div className='align-items-center d-flex gap-2 mb-2'>
                    <Form.Group>
                        <Form.Label
                            as='small'
                            className={['fw-bold mb-2', newJumperInfo?.weight > 300 ? 'text-danger' : ''].join(' ')}>
                            WEIGHT <span className='opacity-50' style={{ fontSize: '9px' }}>({newJumperInfo?.weight > 300 ? 'TOO HEAVY' : 'LBS'})</span>
                        </Form.Label>
                        <Form.Control isInvalid={weightInvalid}
                            isValid={!weightInvalid && clickedOn?.find(f => f === 'Weight')}
                            max='300'
                            min='50'
                            onChange={(e) => setNewJumperInfo({ ...newJumperInfo, weight: e?.target?.value })}
                            onFocus={() => setActiveInput('Weight')}
                            placeholder='lbs'
                            value={newJumperInfo?.weight}
                            style={{ width: '120px' }}
                            type='number' />
                    </Form.Group>
                    <Form.Group className='flex-fill'>
                        <Form.Label
                            as='small'
                            className='fw-bold mb-0'>
                            EMAIL
                        </Form.Label>
                        <Form.Control isInvalid={emailInvalid}
                            isValid={!emailInvalid && clickedOn?.find(f => f === 'Email')}
                            onChange={(e) => setNewJumperInfo({ ...newJumperInfo, email: e?.target?.value })}
                            onFocus={() => setActiveInput('Email')}
                            type='text'
                            value={newJumperInfo?.email} />
                    </Form.Group>
                </div>
                <div className='mb-2'>
                    <PhoneNumber activeInput={activeInput}
                        clickedOn={clickedOn}
                        field='phone'
                        newJumperInfo={newJumperInfo}
                        phoneIsTaken={phoneTaken}
                        setActiveInput={setActiveInput}
                        setNewJumperInfo={setNewJumperInfo}
                        setPhoneIsTaken={setPhoneTaken} />
                </div>
                <div>
                    <MMDDYYYY bold={true}
                        clickedOn={clickedOn}
                        newReservation={newJumperInfo}
                        setActiveInput={setActiveInput}
                        setNewReservation={setNewJumperInfo} />
                </div>
                <div className='align-items-center d-flex gap-2'>
                    <Form.Group className='flex-fill'>
                        <div>
                            <Form.Label as='small' className='fw-bold mb-1'>EMERGENCY CONTACT</Form.Label>
                        </div>
                        <Form.Control isInvalid={emergencyContactInvalid}
                            isValid={!emergencyContactInvalid && clickedOn?.find(f => f === 'Emergency Contact')}
                            onChange={(e) => setNewJumperInfo({ ...newJumperInfo, emergencyContact: e?.target?.value })}
                            onFocus={() => setActiveInput('Emergency Contact')}
                            value={newJumperInfo?.emergencyContact} />
                    </Form.Group>
                    <PhoneNumber activeInput={activeInput}
                        clickedOn={clickedOn}
                        field='emergencyPhone'
                        newJumperInfo={newJumperInfo}
                        phoneIsTaken={phoneTaken}
                        setActiveInput={setActiveInput}
                        setNewJumperInfo={setNewJumperInfo}
                        setPhoneIsTaken={setPhoneTaken} />
                </div>
                {!hideRadios && <>
                    <div>
                        <Form.Label as='small' className='fw-bold mb-2'>Payment option</Form.Label>
                    </div>
                    <div className='d-flex gap-4 justify-content-center mb-4'>
                        <Form.Group>
                            <Form.Check checked={newJumperInfo?.paymentOption === 'deposit' || newJumperInfo?.amountPaid === (numJumpers * dropzone?.pricing?.tandems?.depositPrice)}
                                className='fw-medium'
                                label={`Deposit only $${numJumpers * dropzone?.pricing?.tandems?.depositPrice}`}
                                onChange={(e) => setNewJumperInfo({ ...newJumperInfo, paymentOption: 'deposit', amountPaid: numJumpers * dropzone?.pricing?.tandems?.depositPrice })}
                                onFocus={() => setActiveInput('Payment option')}
                                type='radio' />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check checked={newJumperInfo?.paymentOption === 'full' || newJumperInfo?.amountPaid === (dropzone?.pricing?.tandems?.tandemPrice * numJumpers)}
                                className='fw-medium'
                                label={`Pay in Full $${dropzone?.pricing?.tandems?.tandemPrice * numJumpers}`}
                                onChange={(e) => setNewJumperInfo({ ...newJumperInfo, paymentOption: 'full', amountPaid: dropzone?.pricing?.tandems?.tandemPrice * numJumpers })}
                                onFocus={() => setActiveInput('Payment option')}
                                type='radio' />
                        </Form.Group>
                    </div>
                </>}
            </div>
        </div>;
    }
    return mainContentJSX();
}
export default AddTandemReservation;