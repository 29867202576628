
import moment from 'moment/moment';
import { Timestamp } from 'firebase/firestore';

import { ISoloJumperData, ISoloPaymentData, ITandemStudentData } from './models';

class Utilities {
    appendTimeToDate(date: Date, time: string, addHrs?: boolean) {
        var isAMPM = 'am'
        var origHourVal: any = time?.split('a');
        var minVal: any = 0;
        let hourVal;
        if(origHourVal?.length > 1) {
            hourVal = origHourVal[0];
        } else {
            isAMPM = 'pm'
            hourVal= time?.split('p')[0];
        }
        if(!hourVal) return new Date(date);
        const checkingForColon = hourVal?.split(':');
        if(checkingForColon?.length > 1) {
            hourVal = checkingForColon[0];
            minVal = checkingForColon[1];
        }

        let dateTime = new Date(date);
        dateTime.setHours(isAMPM === 'pm' && addHrs ? Number(hourVal) + 12 : hourVal);
        dateTime.setMinutes(minVal);
        dateTime.setSeconds(0);
        return dateTime;
    }

    calculateJumperBalance(pmts: ISoloPaymentData[], showAsNum?) {
        let bal = 0;
        pmts?.forEach(p => {
            let count = 0;
            p?.withdrawels?.forEach(w => count = count + Number(w?.amountWithdrawn));
            bal = bal + Number(p?.originalAmount - count);
        });
        return showAsNum ? bal : `$${bal}.00`;
    }

    checkIfYearIsInvalid(data: ITandemStudentData | ISoloJumperData) {
        const diff = this.diffInMinsFromDate(new Date(`${data?.MM}-${data?.DD}-${data?.YYYY}`))
        const age = (diff / 525600) * -1;
        if (data?.YYYY < 1900) return true;
        if (age > 18) return false;
        return true;
    }

    dateWithoutTime (date: Date) {
        const d = new Date(date);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    diffInMins (date: Timestamp) {
        const d1 = new Timestamp(date?.seconds, date?.nanoseconds).toDate().getTime();
        const d2 = new Date().getTime();
        const diffInMinutes = Math.round((d1 - d2) / 60000);
        return isNaN(diffInMinutes) ? 0 : diffInMinutes; 
    }

    diffInMinsFromDate (date: Date) {
        const d1 = date?.getTime();
        const d2 = new Date()?.getTime();
        const val = Math.round((d1 - d2) / 60000);
        return isNaN(val) ? 0 : val; 
    }

    formatMMDDYYYY(date: string) {
        return moment(date).format('MM/DD/YYYY');
    }

    async getAvailableTandemTimeslots(date: Date, startTime: string, endTime: string, maxTandemsPerHour: number, numPartcipants: number, reservations: ITandemStudentData[]) {
        const end = this.appendTimeToDate(date, endTime, true);
        const start = this.appendTimeToDate(date, startTime, true);
        let keepIterating = true;
        var trackerDate = start;
        const dateWithoutTime = this.dateWithoutTime(date);
        const availableSlots: any = [{ date, time: start?.getTime(), dateWithoutTime }];
        do {
            trackerDate = moment(trackerDate).add(15, 'm').toDate();
            availableSlots.push({ date: trackerDate, dateWithoutTime: this.dateWithoutTime(trackerDate) });
            if(trackerDate.getTime() >= end.getTime()) keepIterating = false
        } while(keepIterating);
        return availableSlots;
    }

    getMonthString(num: number) {
        switch(num) {
            case 11: return 'December';
            case 10: return 'November';
            case 9: return 'October';
            case 8: return 'September';
            case 7: return 'August';
            case 6: return 'July';
            case 5: return 'June';
            case 4: return 'May';
            case 3: return 'April';
            case 2: return 'March';
            case 1: return 'February';
            default: return 'January';
        }
    }

    stringHasNumber(s) {
        return /\d/.test(s);
    }
}
export default Utilities;