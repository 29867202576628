import { useEffect, useState } from 'react';

import { Button, Card, Collapse, Form, Modal } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';

import ApiCalls from '../apiCalls';
import { ISoloPaymentData } from '../models';

const JumperBalance = ({ balance, currentUser, dropzone, jumper, setRefetchPayments }) => {
    const apiCalls = new ApiCalls();

    const [amount, setAmount] = useState<any>('');
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        if(!showEdit) setAmount('');
    }, [showEdit]);

    const saveClicked = async () => {
        setShow(false);
        setShowEdit(false);
        const newPaymentData: ISoloPaymentData = {
            active: true,
            date: new Date(),
            dropzoneId: dropzone?.id,
            firstName: jumper?.firstName, 
            lastName: jumper?.lastName,
            originalAmount: amount,
            userId: jumper?.id || '',
            phone: jumper?.phone,
            submittedBy: currentUser?.uid,
            withdrawels: []
        };
        await apiCalls.addPayment(newPaymentData);
        setRefetchPayments(true);
    }

    //-----Pure JSX Functions-----//

    const addToBalanceJSX = () => {
        const block = amount <= 0 || amount > 10000;
        return <div className='px-4 py-5 rounded'>
            <Collapse in={!showEdit}>
                <div>
                    <div className='align-items-center d-flex gap-2 justify-content-between'>
                        <h6 className='flex-fill fw-medium mb-0'>Current Balance <span className='opacity-75'>({balance || 0})</span></h6>
                        <div className='d-flex gap-1'>
                            {!showEdit && <Button onClick={() => setShow(false)} variant='light'>Back</Button>}
                            {showEdit ? <span className={['cursor fw-bold', balance > 0 ? 'text-success' : 'text-danger'].join(' ')}>{`$${balance}`}</span> :
                                <Button className='fw-medium shimmer'
                                    onClick={() => setShowEdit(true)}
                                    variant='success'>
                                    Add to balance <PlusCircleFill />
                                </Button>}
                        </div>
                    </div>
                </div>
            </Collapse>
            <Collapse in={showEdit}>
                <div>
                    <div className='align-items-center d-flex justify-content-between mb-2'>
                        <h6 className='mb-0'>{jumper?.firstName} {jumper?.lastName}'s Account Balance</h6>
                        <Card />
                    </div>
                    <div className='d-flex gap-1'>
                        <div className='align-items-center d-flex flex-fill gap-2'>
                            <span className='fs-3 fw-bold text-success'>$</span>
                            <Form.Control isInvalid={block} max={10000} min={0} placeholder='Add to balance...' 
                                onChange={(e) => setAmount(e?.target?.value)} type='number' value={amount} />
                        </div>
                        <div>
                            <Button onClick={() => setShowEdit(false)} variant='outline-secondary'>Cancel</Button>
                        </div>
                        <div>
                            <Button disabled={block} onClick={() => saveClicked()}>Save</Button>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>;
    }

    return <>
        <div>
            <div className={['align-items-center border-bottom d-flex justify-content-between mb-1 pb-2', jumper?.flagged ? 'opacity-50' : ''].join(' ')} onClick={() => !jumper?.flagged && setShow(true)}>
                <small className='cursor fw-bold'>Balance: </small>
                <span>{balance}</span>
            </div>
        </div>
        <Modal centered show={show}>
            {addToBalanceJSX()}
        </Modal>
    </>;
}
export default JumperBalance;