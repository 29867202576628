import { useCallback, useMemo, useState } from 'react';

import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';

import { PinAngleFill } from 'react-bootstrap-icons';
import PlacesAutocomplete from './PlacesAutocomplete';

const Maps = (props) => {
    const [ libraries ] = useState(['places']);
    const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyAZNiyxrXmftyRky4Fmu8A-9qO0LLcXNe8', libraries });
    if (!isLoaded) return <></>;
    return <Map {...props} />;
}
export default Maps;

function Map({ mapRef, selected, setSelected }) {
    const center = useMemo(() => ({ lat: selected?.lat ? selected.lat : 45.68, lng: selected?.lng ? selected.lng : -111.04 }), [selected]);
    const [map, setMap] = useState(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const onLoad = useCallback((m) => {
        m.setOptions({ minZoom: 4, maxZoom: 18, zoom: 12 });
        setMap(m)
    }, []);

    //-----Pure JSX Functions-----//

    const markerJSX = () => {
        return <MarkerF icon={<PinAngleFill />}
            label=''
            onClick={(e) => null}
            position={{ lat: selected.lat, lng: selected?.lng ? selected.lng : selected.long }}>
        </MarkerF>;
    }

    return <>
        <PlacesAutocomplete address={selected} mapRef={mapRef} setSelected={(e) => setSelected(e)} selected={selected} />
        <GoogleMap center={center} mapContainerStyle={{ borderRadius: '10px', border: '2px solid black', width: '100%', height: '39vh' }}
            mapContainerClassName='map-container' onClick={() => popupInfo ? setPopupInfo(null) : null}
            onLoad={onLoad} options={{ gestureHandling: "greedy" }}
            zoom={4}>
            {selected && markerJSX()}
        </GoogleMap>
    </>;
}