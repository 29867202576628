import { Calendar } from 'react-calendar';

import '../styles/whiteCalendar.css';

const OnJumprunCalendar = ({ activeTab, selectedDate, setActiveTab, setSelectedDate, thisMonthsStartDate }) => {

    //-----Pure JSX Functions-----//

    return <Calendar activeStartDate={thisMonthsStartDate}
        calendarType='Hebrew'
        className='whiteScreen hide-scrollbar mb-3'
        onChange={(e) => { setSelectedDate(e); activeTab !== 'Daily Manifest' && setActiveTab('Daily Manifest') }}
        showNeighboringMonth={false}
        value={selectedDate}
        view='month' />;
}
export default OnJumprunCalendar;