import { useEffect, useRef, useState } from 'react';

import { Badge, Button, Card, Col, Collapse, Form, Row, Spinner } from 'react-bootstrap';
import { ArrowLeftCircleFill, CheckCircleFill, ExclamationCircleFill, PencilSquare } from 'react-bootstrap-icons';
import { Timestamp } from 'firebase/firestore';

import { IDropzoneData, ISignWaiverData } from '../models';
import MMDDYYYY from './MMDDYYYY';
import Utilities from '../utils';

interface IWaiverProps {
    didSaveNewDropzone?: (val: IDropzoneData) => void, 
    didSaveNewWaiver?: () => void,
    dropzone: IDropzoneData,
    isDZO: boolean,
    jumperType?: any,
    setDropzone: (val: IDropzoneData) => void,
    setJumperType?: (val: any) => void,
    setToastMessage?: (val: any) => void,
    setWaiver?: (val: ISignWaiverData) => void,
    signedBy?: ISignWaiverData,
    waiver?: ISignWaiverData;
}

const Waiver = ({ didSaveNewDropzone, didSaveNewWaiver, dropzone, isDZO, jumperType, setDropzone,  setJumperType, setToastMessage, setWaiver, signedBy, waiver } : IWaiverProps) => {
    const originalEditObject = {
        field: '',
        message: '',
        value: ''
    };
    const topRef = useRef(null);
    const utils = new Utilities();

    const [didConsent, setDidConsent] = useState(false);
    const [edit, setEdit] = useState<any>(originalEditObject);
    const [isLoading, setIsLoading] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        !isDZO && topRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [topRef]);

    useEffect(() => {
        checkIfWaiverIsValidForDZO();
    }, [dropzone, isLoading]);

    const checkIfWaiverIsValidForDZO = () => {
        const dzoAddedTheirSignature = (isDZO && dropzone?.waiver?.firstSignature?.length > 3 && 
            dropzone?.waiver?.secondSignature?.length > 3 && 
            dropzone?.waiver?.thirdSignature?.length > 3 &&
            dropzone?.waiver?.fourthSignature?.length > 3 &&
            isLoading);
        setValid(dzoAddedTheirSignature ? true : false);
    }

    const didClickAgree = () => {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            didSaveNewWaiver();
            setEdit(originalEditObject);
        }, 250);
    }

    const saveClicked = () => {
        setIsLoading(true);
        if(isDZO && didSaveNewDropzone) {
            if(setToastMessage) {
                setToastMessage({ message: 'Waiver Complete! Youre ready to start sending loads!', variant: 'primary' });
            }            
            topRef?.current?.scrollIntoView({ behavior: 'smooth' });
            didSaveNewDropzone({ ...dropzone, waiver: { ...dropzone?.waiver, dateSigned: new Date() }});
        }
        !isDZO && didSaveNewWaiver && didSaveNewWaiver();
    }

    const validateWaiverFilledOutCorrectly = () => {
        if(waiver?.firstName === '' || waiver?.lastName === '' || waiver?.phone?.length !== 10) return true;
        const diff = utils?.diffInMinsFromDate(new Date(`${waiver?.MM}-${waiver?.DD}-${waiver?.YYYY}`))
        const jumpersAgeInYears = (diff / 525600) * -1;
        if(waiver?.YYYY < 1900) return true;
        if(jumpersAgeInYears < 18) return true;
        if(!waiver?.weight || waiver?.weight < 0) return true;
        if(!waiver?.weightWithGear || waiver?.weightWithGear < 40 || waiver?.weightWithGear > 250) return true;
        if(waiver?.addressLine1 === '' || waiver?.city === '' || waiver?.state === '' || (!waiver?.zip || waiver.zip < 1)) return true;
        if(!waiver?.email || waiver.email === '') return true;
        if(!waiver?.confirmEmail || waiver.confirmEmail === '' || (waiver?.email !== waiver?.confirmEmail)) return true;
        if(!waiver?.emergencyContactName || waiver.emergencyContactName === '') return true;
        if(!waiver?.emergencyAddress || waiver.emergencyAddress === '') return true;
        if(!waiver?.emergencyCity || waiver.emergencyCity === '') return true;
        if(!waiver?.emergencyContactPhone || String(waiver.emergencyContactPhone)?.length !== 10) return true;
        if(!waiver?.emergencyContactName || waiver.emergencyContactName === '') return true;
        if(waiver?.releaseOfLiability === '') return true;
        if(waiver?.llc === '') return true;
        if(waiver?.assumptionOfRisk === '') return true;
        if(waiver?.agreementNotToSue === '') return true;
        if(waiver?.indemnity === '') return true;
        if(waiver?.applicableLaw === '') return true;
        if(waiver?.binding === '') return true;
        if(waiver?.limitation === '') return true;
        if(waiver?.severability === '') return true;
        if(waiver?.continuation === '') return true;
        if(waiver?.acknowledgement === '') return true;
        if(waiver?.physicalCondition === '') return true;
        if(waiver?.entire === '') return true;
        if(waiver?.photoVideo === '') return true;
        if(waiver?.equipment === '') return true;
        if(waiver?.noRefunds === '') return true;
        if(waiver?.rightToRefuse === '') return true;
        if(waiver?.fieldElevation === '') return true;
        if(!didConsent) return true;
        return false;
    }

    //-----Pure JSX Functions-----//

    const acknowledgementOfWaiverOfRightsJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            11. <span className='fw-bold'>Acknowledgement of Waiver of Rights</span>.
            I am Eighteen (18) years of age or older and mentally competent to enter into this Agreement. I
            understand that by signing this document I am giving up important legal rights, and it is my intent to do so.
        </h6>;
    }

    const agreementNotToSueJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            4. <span className='fw-bold'>Agreement Not to Sue</span>.
            I agree never to institute any lawsuit or cause of action against any of the Releasees, or to initiate or
            to assist in the prosecution of any claim for damages against the Releasees which I may have by reasons
            of injury to my person or property, or my death, arising from the activities covered by this Agreement,
            whether caused by negligence or fault, active or passive, of an of the Releasees, or from any other cause.
            I further agree that my heirs, executors, administrators, personal representative, or any on else
            claiming on my behalf, shall be bound by this agreement not to sue. I agree that the Releasees shall be entitled
            to recover attorney's fees and costs incurred in the defense of any lawsuit brought by me or anyone on my behalf.
        </h6>;
    }

    const disableDZOSave = () => {
        let count = -1;
        if (isLoading) count = count + 1;
        if (dropzone?.waiver?.firstSignature !== '') count = count + 1;
        if (dropzone?.waiver?.secondSignature !== '') count = count + 1;
        if (dropzone?.waiver?.thirdSignature !== '') count = count + 1;
        if (dropzone?.waiver?.fourthSignature !== '') count = count + 1;
        if(!dropzone?.waiver?.dateSigned) count = count - 1;
        return count;
    }

    const alertJSX = () => {
        let bg = 'danger';
        const dzoNumCompleted = disableDZOSave();
        if (dzoNumCompleted === 3) bg = 'primary';
        return isDZO && <div style={{
            position: 'fixed',
            top: '2rem',
            right: '2rem',
        }}>
            {isDZO ? <Badge bg={bg} className='align-items-center d-flex border-dark gap-2 px-3 py-2' pill>
                {dzoNumCompleted === 3 ? <CheckCircleFill /> : <ExclamationCircleFill />}
                <span><u>{dzoNumCompleted === 3 ? 'DZO APPROVED WAIVER' : 'DZO SIGNATURE NEEDED'}</u> { <>- {dzoNumCompleted + 1} of 4</>}</span>
            </Badge> : <Badge bg='danger' className='border-dark px-3 py-2' pill><u>SIGNATURES COMPLETED</u>: 0 of 4</Badge>}
        </div>;
    }

    const applicableLawJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            6. <span className='fw-bold'>Applicable Law/Waiver of Jury Trial/Venue</span>.
            I agree that this Agreement shall be governed by and construed in accordance with the
            laws of the State in which business is done, without giving effect to any choice or conflict of law
            provision or rule (whether the State or any other jurisdiction). I further agree that State law shall govern
            any dispute arising from the activities covered by this Agreement. SHOULD THIS AGREEMENT BE BREACHED AND SUIT IS
            BROUGHT BY AGAINST ANY OF THE RELEASEES, THEN EACH PARTY IRREVOCABLY AND UNCONDITIONALLY WAIVES, TO THE FULLEST EXTENT
            PERMITTED BY APPLICABLE LAW, AND RIGHT IT MAY HAVE TO A TRIAL BY JURY IN ANY LEGAL ACTION, PROCEEDING, CAUSE OF ACTION OR
            COUNTERCLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT, OR THE TRANSATIONS CONTEMPLATED HEREBY. Any legal suit,
            action or proceeding arising out of or relating to this Agreement or the transactions contemplated hereby shallbe instituted
            in the United States federal court or state court located in the State in which business is being done.
        </h6>;
    }

    const assumptionOfRiskJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            3. <span className='fw-bold'>Assumption of Risk</span>.
            I know and understand the scope, nature and extent of the risks involved in the activities covered by this
            Agreement and that some dangers cannot be foreseen. I understand that these risks include, but are not limited
            to: equipment malfunction or failure to function, defective design or manufacture of equipment, improper or
            negligent parachute packing or assembly, improper or negligent operation of the aircraft or other equipment,
            hard landings, changing weather conditions, landing area hazards, carelessness or negligence of skydivers,
            instructors, JumpMasters, pilots, or ground crew, improper or negligent instruction or supervision. I voluntarily,
            freely, and expressly choose to incur all risks associated with the activities covered by this Agreement,
            understanding that those risks may include bodily and personal injury, disfigurement, or death. I voluntarily
            and freely choose to incur such risks and take responsibility, including legal responsibility, therefore.
        </h6>;
    }

    const bindingArbitrationJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            6. <span className='fw-bold'>Binding Arbitration of Disputes</span>.
            Any controversy or claim arising out of or relating to this Agreement, or the breach thereof,
            shall be determined by final and binding arbitration administered by the American Arbitration Assocation ("AAA")
            under its Consumer Arbitration Rules and Mediation Procedures ("Consumer Rules"). There shall be one arbitrator
            agreed to by the parties within twenty (20) days of reciept by the respondent[s] of the request for arbitration
            or in default thereof appointed by the AAA in accordance with its Consumer Rules. The seat or place of arbitration shall
            be at the jurisdiction of the State. Excpept as may be required by law, neither a party nor the arbitrator[s] may disclose the
            existence, content or results of any arbitration without the prior written consent of both parties, unlesss to
            protect or pursue a legal right. The awar rendered by the arbitrator shall be final, non-reviewable, non-appealable,
            and binding on the parties and may be entered and enforced in any United States District Court or state court located in
            the state of Colorado. Judgment on the award shall be final and non-appealable.
        </h6>;
    }

    const continuationOfObligationsJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            10. <span className='fw-bold'>Continuation of Obligations</span>.
            I agree that the terms and conditions of this Agreement shall continue in full force and effect now
            and in the future at all times during which I participate, either directly or indirectly, in the activities
            covered by this Agreement, and shall be binding upon my heirs, executors, administrators, personal representatives,
            and/or anyone else claiming on by behalf. This Agreement supersedes and replaces and prior such Agreement between
            the Releasees and myself and shall remain in full force and effect until such time as I unequivocally revoke the same
            by submitting written notice of such revocation of {inputJSX(dropzone?.waiver?.thirdSignature, 'Your Name, DZ Name', 1, dropzone?.waiver?.thirdSignature, (e) => setDropzone({...dropzone, waiver: { ...dropzone.waiver, thirdSignature: e}}))}, via US Mail, Return Receipt Requested.
        </h6>;
    }

    const entireAgreementJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            13. <span className='fw-bold'>Entire Agreement</span>.
            I understand this Agreement contains the entire agreement between the parties to this Agreement and that the terms of
            this Agreement are contractual and not a mere recital.
        </h6>;
    }

    const equipmentJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            15. <span className='fw-bold'>Equipment</span>.
            I agree that if I lose or damage any of {dropzone?.name} equipment, I am responsible for the replacement cost
            of the item lost or damaged.
        </h6>;
    }

    const fieldElevationJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            18. <span className='fw-bold'>Field Elevation</span>.
            I understand that the ground varies by height, depending upon the location of the aircraft flight. I
            understand and agree that I am aware of the effect of this altitude upon my freefall, parachute deployment, canopy
            flight characteristics, and landing, and that if I am not so aware, that I am obligated to seek advice on those
            subjects from a USPA license skydiving instructor of my choosing.
        </h6>;
    }

    const formJSX = () => {
        const disableInput = (isDZO || signedBy !== null);
        return <div className='p-4 position-relative'>
            {isDZO && <div style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                left: '50%',
                width: '100%'
            }}>
                <h1 className={['text-center', disableDZOSave() === 4 ? 'text-secondary' : 'text-danger'].join(' ')}>CUSTOMER INPUT FORM</h1>
            </div>}
            <div style={{ opacity: isDZO ? '.25' : '1', height: isDZO ? '40vh' : 'unset', overflow: isDZO ? 'scroll' : 'unset' }}>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex justify-content-between'>
                        <h4 className='fw-medium mb-0'>Participant's Name & Phone:</h4>
                        <div>
                            <div className='d-flex gap-2 mb-2'>
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && waiver?.firstName === ''}
                                    onChange={(e: any) => setWaiver({ ...waiver, firstName: e?.target?.value })}
                                    placeholder='First Name' 
                                    type='text'
                                    value={waiver?.firstName} />
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && waiver?.lastName === ''}
                                    onChange={(e: any) => setWaiver({ ...waiver, lastName: e?.target?.value })}
                                    placeholder='Last Name' 
                                    type='text'
                                    value={waiver?.lastName} />
                            </div>
                            <Form.Control disabled={disableInput}
                                isInvalid={!isDZO && (waiver?.phone === '' || String(waiver?.phone).length !== 10 || isNaN(waiver?.phone))}
                                onChange={(e: any) => setWaiver({ ...waiver, phone: e?.target?.value })}
                                placeholder='Phone number' 
                                type='number' 
                                value={waiver?.phone} />
                        </div>
                    </div>
                </div>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex justify-content-between'>
                        <h4 className='fw-medium mb-0'>Participant's Date of Birth:</h4>
                        <div>
                            <MMDDYYYY disabled={disableInput}
                                newReservation={{ MM: waiver?.MM, DD: waiver?.DD, YYYY: waiver?.YYYY }}
                                setNewReservation={(e: any) => setWaiver({ ...waiver, ...e })}
                                size='lg' />
                        </div>
                    </div>
                </div>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex justify-content-between'>
                        <h4 className='flex-fill fw-medium mb-0'>Participant's Information:</h4>
                        <div className='flex-fill'>
                            <div className='align-items-center d-flex gap-2 mb-2'>
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (waiver?.weight < 1 || waiver?.weight > 300)}
                                    max='300'
                                    min='1'          
                                    onChange={(e: any) => setWaiver({ ...waiver, weight: e?.target?.value })}
                                    placeholder='Weight'
                                    type='number'
                                    value={waiver?.weight || ''} />
                                    <span className='fw-medium'>lbs</span>
                            </div>
                            <div className='align-items-center d-flex gap-2 mb-2'>
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (waiver?.weightWithGear < 1 || waiver?.weightWithGear > 300)}
                                    min='1'
                                    max='300'
                                    onChange={(e: any) => setWaiver({ ...waiver, weightWithGear: e?.target?.value })}
                                    placeholder='Weight with gear'
                                    type='number'
                                    value={waiver?.weightWithGear || ''} />
                                    <span className='fw-medium'>lbs</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex gap-4 justify-content-between'>
                        <h4 className='fw-medium mb-0 no-word-wrap'>Participant's Address:</h4>
                        <div>
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && waiver?.addressLine1 === ''}
                                onChange={(e: any) => setWaiver({ ...waiver, addressLine1: e?.target?.value })}
                                value={waiver?.addressLine1}
                                placeholder='Address Line 1'
                                type='text' />
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                onChange={(e: any) => setWaiver({ ...waiver, addressLine2: e?.target?.value })}
                                value={waiver?.addressLine2}
                                placeholder='Address Line 2'
                                type='text' />
                            <div className='d-flex gap-2'>
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && waiver?.city === ''}
                                    onChange={(e: any) => setWaiver({ ...waiver, city: e?.target?.value })}
                                    value={waiver?.city}
                                    placeholder='City' 
                                    type='text' />
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (waiver?.state === '' || waiver?.state?.length !== 2)}
                                    onChange={(e: any) => setWaiver({ ...waiver, state: e?.target?.value })}
                                    value={waiver?.state}
                                    placeholder='State' 
                                    type='text' />
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && !waiver?.zip} min='1'
                                    onChange={(e: any) => setWaiver({ ...waiver, zip: e?.target?.value })}
                                    value={waiver?.zip || ''}
                                    placeholder='ZIP/Postal code'
                                    type='number' />
                                <Form.Control disabled
                                 placeholder='USA' 
                                 type='text' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex gap-4 justify-content-between'>
                        <h4 className='flex-fill fw-medium mb-0 no-word-wrap'>Participant's Email:</h4>
                        <div className='flex-fill'>
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && waiver?.email === ''}
                                onChange={(e: any) => setWaiver({ ...waiver, email: e?.target?.value })}
                                value={waiver?.email}
                                placeholder='Email'
                                type='text' />
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && (waiver?.confirmEmail === '' || waiver?.email !== waiver?.confirmEmail)}
                                onChange={(e: any) => setWaiver({ ...waiver, confirmEmail: e?.target?.value })}
                                value={waiver?.confirmEmail}
                                placeholder='Confirm email' type='text' />
                        </div>
                    </div>
                </div>
                <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex gap-4 justify-content-between'>
                        <h4 className='flex-fill fw-medium mb-0 no-word-wrap'>Emergency Contact:</h4>
                        <div className='flex-fill'>
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && waiver?.emergencyContactName === ''}
                                onChange={(e: any) => setWaiver({ ...waiver, emergencyContactName: e?.target?.value })}
                                value={waiver?.emergencyContactName}
                                placeholder='Name' 
                                type='text' />
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && String(waiver?.emergencyContactPhone)?.length !== 10}
                                onChange={(e: any) => setWaiver({ ...waiver, emergencyContactPhone: e?.target?.value })}
                                value={waiver?.emergencyContactPhone || ''}
                                placeholder='Phone number'
                                type='number' />
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && waiver?.relationshipToYou === ''}
                                onChange={(e: any) => setWaiver({ ...waiver, relationshipToYou: e?.target?.value })}
                                value={waiver?.relationshipToYou}
                                placeholder='Relationship to you' />
                            <Form.Control className='mb-2'
                                disabled={disableInput}
                                isInvalid={!isDZO && waiver?.emergencyAddress === ''}
                                onChange={(e: any) => setWaiver({ ...waiver, emergencyAddress: e?.target?.value })}
                                value={waiver?.emergencyAddress}
                                placeholder='Address' type='text' />
                            <div className='d-flex gap-2'>
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (!waiver?.emergencyCity || waiver?.emergencyCity === '')}
                                    onChange={(e: any) => setWaiver({ ...waiver, emergencyCity: e?.target?.value })}
                                    value={waiver?.emergencyCity || ''}
                                    placeholder='City'
                                    type='text' />
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (!waiver?.emergencyState || waiver?.emergencyState === '')}
                                    onChange={(e: any) => setWaiver({ ...waiver, emergencyState: e?.target?.value })}
                                    value={waiver?.emergencyState || ''}
                                    placeholder='State'
                                    type='text' />
                                <Form.Control disabled={disableInput}
                                    isInvalid={!isDZO && (!waiver?.emergencyZip || waiver?.emergencyZip < 1)}
                                    onChange={(e: any) => setWaiver({ ...waiver, emergencyZip: e?.target?.value })}
                                    value={waiver?.emergencyZip || ''}
                                    placeholder='ZIP/Postal code'
                                    type='number' />
                                <Form.Control disabled
                                    placeholder='USA' />
                            </div>
                        </div>
                    </div>
                </div>
                {jumperType === 'Experienced' && <div className='border-bottom mb-4 pb-3'>
                    <div className='align-items-center d-flex gap-4 justify-content-between'>
                        <h4 className='flex-fill fw-medium mb-0 no-word-wrap'>Experienced Skydivers Only:</h4>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>Container</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, container: e?.target?.value })}
                                    value={waiver?.container}
                                    placeholder='...' 
                                    type='text' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>Main Canopy</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, mainCanopy: e?.target?.value })}
                                    value={waiver?.mainCanopy}
                                    placeholder='...'
                                    type='text' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>Wing Loading</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, wingLoading: e?.target?.value })}
                                    value={waiver?.wingLoading}
                                    placeholder='1.0' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>Reserve Canopy</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, reserveCanopy: e?.target?.value })}
                                    value={waiver?.reserveCanopy}
                                    placeholder='...' 
                                    type='text' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>Reserve Wing Loading</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, reserveWingLoading: e?.target?.value })}
                                    value={waiver?.reserveWingLoading}
                                    placeholder='1.1' 
                                    type='text' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-0'>Reserve Repack Date:</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, reserveRepackDate: e?.target?.value })}
                                    value={waiver?.reserveRepackDate}
                                    type='date' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium'>AAD</Form.Label>
                                <Form.Control disabled={disableInput}
                                    onChange={(e: any) => setWaiver({ ...waiver, AAD: e?.target?.value })}
                                    value={waiver?.AAD}
                                    placeholder='...' 
                                    type='text' />
                            </Form.Group>
                        </div>
                    </div>
                </div>}
                <div className={isDZO ? 'opacity-50' : ''}>
                    <h3 className={['text-center underline', !signedBy ? (didConsent ? '' : 'text-danger') : 'opacity-50'].join(' ')}>Electronic Signature Consent</h3>
                    <div>
                        <div className='d-flex justify-content-center py-3'>
                            <Form.Check checked={didConsent || signedBy !== null} 
                                className='fw-bold' 
                                disabled={disableInput} 
                                isInvalid={!didConsent && !signedBy} 
                                label='Click to consent'
                                onChange={(e) => setDidConsent(e?.currentTarget?.checked)} 
                                style={{ fontSize: '22px' }} 
                                type='checkbox' />
                        </div>
                        <h6 className='mb-5 mt-1 mx-2'>
                            By checking here, you are consenting to the use of your electronic signature in lieu of an original signature on paper.
                            You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may,
                            upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware
                            or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until
                            such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing
                            your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.
                        </h6>
                    </div>
                </div>
            </div>
        </div>;
    }

    const indemnityAgainstThirdPartyClaimsJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            5. <span className='fw-bold'>Indemnity Against Third Party Claims</span>.
            I agree to indemnify, save, and hold harmless Releasees from any and all losses, claims, actions,
            or proceedings of every kind and character, including attorney fees and expenses, which may be presented
            or initiated by any other person or organization and which arise directly or indirectly from my participation
            in the activities covered by this Agreement, whether resulting from the negligence or other fault, either
            active or passive, of any of the Releasees, or from any other cause.
        </h6>;
    }

    const initialJSX = (field, value) => {
        const hasSigned = value && value !== '';
        return <div className='d-flex justify-content-center gap-2 mt-4'>
            <Collapse dimension='width' in={(!isDZO && edit?.field === field)}>
                <div>
                    <div className='d-flex gap-1'>
                        <Form.Control autoFocus
                            disabled={signedBy !== null}
                            onChange={(e) => setEdit({ ...edit, message: e?.target?.value })}
                            placeholder='Your Initials..'
                            value={edit?.message}
                            style={{ minWidth: '150px' }}
                            type='text' />
                        <Button hidden={signedBy !== null}
                            onClick={() => {
                            let copy: any = waiver;
                            copy[field] = '';
                            setWaiver(copy);
                            setEdit(originalEditObject);
                        }} variant='outline-danger'>
                            Cancel
                        </Button>
                        <Button className='fw-bold'
                            hidden={signedBy !== null}
                            onClick={() => {
                                let copy: any = waiver;
                                copy[field] = edit?.message;
                                setEdit(originalEditObject);
                            }}>Save</Button>
                    </div>
                </div>
            </Collapse>
            {(valid || (edit?.field !== field)) && !isDZO && <Button className='align-items-center d-flex justify-content-center gap-2 fadeInFast'
                disabled={isLoading || signedBy !== null}
                onClick={() => setEdit({ message: hasSigned ? value : '', value, field })}
                variant={hasSigned && !signedBy ? 'success' : (!signedBy ? 'danger' : 'outline-secondary')}>
                {hasSigned ? <span><span className='fw-bold pe-2'>SIGNED -</span>{value}</span> : 'Sign initials'}
                {hasSigned ? <CheckCircleFill /> : <PencilSquare />}
            </Button>}
        </div>;
    }

    const inputJSX = (title, placeholder, index, hook, setHook) => {
        return isDZO ? <Form.Control autoFocus={!dropzone?.id && index === 1} 
            className={['me-1', isDZO && hook?.length === 0 ? 'shimmer' : ''].join(' ')}
            disabled={disableDZOSave() === 4} 
            isInvalid={hook?.length < 2}
            isValid={hook?.length >= 2}
            onChange={(e) => setHook(e?.target?.value)} 
            placeholder={placeholder}
            size='sm' 
            type='text' 
            style={{ width: '180px', display: 'inline' }} 
            value={hook} /> : <span className='fw-bold underline'>{title}</span>;
    }

    const limitationOfWarrantyJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            8. <span className='fw-bold'>Limitation of Warranty</span>.
            The Releasees warrant that the equipment provided for skydiving/parachuting activities has been previously
            used for skydiving/parachuting activities. This warranty is the only warranty made and is made in lieu of
            any other warranties, express or implied, including but not limited to the warranty of merchantability or
            fitness for a particular purpose.
        </h6>;
    }

    const llcJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            2. <span className='fw-bold'>LLC</span>,
            and their instructors, pilots, riggers, ground transportation drivers and motor vehicle owners, ground handlers,
            ground crew, flight crew, maintenance crew, officers, directors, agents, employees, members, managers,
            and independent contractors as well as the owners and manufacturers of the aircraft, as well as all skydiving
            instructors and jumpers, including tandem instructors, IAD instructors, and owners and manufacturers of any
            skydiving equipment they provide to me, the owners and operators of all land and airports utilized by the
            Releasees for the purpose of take-off, landing, parachuting, or other associated activities, and their affiliates,
            officers, agents, officials, employees, and indepedent contractors.
        </h6>;
    }

    const noRefundsJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            16. <span className='fw-bold'>No Refunds</span>.
            Except as expressly provided herein, all payments under this Agreement will be irrevocable and non-refundable.
            Credits are applicable for use of the Service and are not convertibel into cash or any type of refund.
        </h6>;
    }

    const photoAndVideoReleaseJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            14. <span className='fw-bold'>Photo and Video Release</span>.
            For valuable consideration received, I hereby grant {inputJSX(dropzone?.waiver?.fourthSignature, 'Your Name, DZ Name', 1, dropzone?.waiver?.fourthSignature, (e) => setDropzone({ ...dropzone, waiver: { ...dropzone.waiver, fourthSignature: e } }))} (hereinafter "Photographer") and its
            legal representatives and assigns, the irrevocable and unrestricted right to use and publish photographs or video images
            of me, or in which I may be included, for editorial, grade, advertising, and any other purpose in any manner and medium,
            to alter the same without restriction; and to copyright the same.
        </h6>;
    }

    const physicalConditionJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            12. <span className='fw-bold'>Physical Condition</span>.
            I acknowledge and agree that I will not participate in activities covered by this Agreement in circumstances where I
            am under the influence of alcohol, drugs or mental disease or where I have a physical limitation, ailment, or other condition
            which would render me less mentally or physically competent to participate in the activities covered by this Agreement.
        </h6>;
    }

    const releaseOfLiabilityJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            1. <span className='fw-bold'>Release of Liability</span>.
            I hereby release and discharge the following parties (hereinafter referred to as "Releasees"),
            from any and all liability, claims, demands, or causes of action whatsoever arising out of any
            damage, loss or injury to me or my property, or my death, while participating in any of the
            activities covered by this Agreement, whether resulting from the negligence or other fault, either
            active or passive, of any of the Releasees, or from any other cause: {inputJSX(dropzone?.waiver?.secondSignature, 'Your Name, DZ Name', 1, dropzone?.waiver?.secondSignature, (e) => setDropzone({ ...dropzone, waiver: { ...dropzone.waiver, secondSignature: e } }))}.
        </h6>;
    }

    const rightToRefuseServiceJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            17. <span className='fw-bold'>Right to Refuse Service</span>.
            Except as expressly provided herein, all payments under this Agreement will be
            irrevocable and non-refundable. Credits are applicable for use of the Service and are not convertible into cash or any type of refund.
        </h6>;
    }

    const severabilityOfProvisionsJSX = () => {
        return <h6 className='border-top mt-3 pt-3'>
            9. <span className='fw-bold'>Severability of Provisions</span>.
            I agree that this Agreement is intended to be as broad and inclusive as permitted by the laws of the
            State in which the business operates, and if any portions of this Agreement are found to be unenforcable or against public
            policy, that only those portions shall fail, and I agree to be bound to the remainder of the agreement.
        </h6>;
    }

    const waiverJSX = () => {
        return <Card className='border-secondary fadeInFast mt-2 shadow-lg'>
            <Card.Header className='fw-bold py-3'>
                <h4 className='mb-0 text-center underline'>Release of Rights & Assumption of Risk Agreement</h4>
            </Card.Header>
            <Card.Body>
                <h6 className={['fw-bold mb-4 mt-3 mx-4 px-4 text-center', valid ? 'text-success' : 'text-danger'].join(' ')}>
                    WARNING! YOU ARE A VOLUNTEER JUMPER. AVIATION, SKYDIVING, AND ALL RELATED ACTIVITIES ARE
                    DANGEROUS AND CAN CAUSE MAJOR PERMANENT INJURY PAIN, SUFFERING OR <u>DEATH</u>!<br/><br/> 
                </h6>
                <h6 className='mb-4'>
                    In consideration for being permitted to participate in skydiving activities, which
                    include but is not necessarily limited to skydiving/parachute jumping, solo jumping,
                    "fun jumping", student jumping, training, and tandem jumping with the incidental use of
                    any aircraft, ground transportation, and ground handling activities (hereinafter collectively referred
                    to as "activities covered by this Agreement") conducted by 
                    <span className='px-1'>
                        {inputJSX(dropzone?.waiver?.firstSignature, 'Your Name, DZ Name', 1, dropzone?.waiver?.firstSignature, (e) => setDropzone({...dropzone, waiver: { ...dropzone.waiver, firstSignature: e}}))} 
                    </span>
                     hereby agree as follows:
                </h6>
                <h6 className='fw-bold mb-2 text-center underline'>
                    THIS IS A RELEASE OF YOUR RIGHTS!<br /> READ CAREFULLY AND UNDERSTAND ITS PROVISIONS BEFORE SIGNING.
                </h6>
                {releaseOfLiabilityJSX()}
                {initialJSX('releaseOfLiability', waiver?.releaseOfLiability)}
                {llcJSX()}
                {initialJSX('llc', waiver?.llc)}
                {assumptionOfRiskJSX()}
                {initialJSX('assumptionOfRisk', waiver?.assumptionOfRisk)}
                {agreementNotToSueJSX()}
                {initialJSX('agreementNotToSue', waiver?.agreementNotToSue)}
                {indemnityAgainstThirdPartyClaimsJSX()}
                {initialJSX('indemnity', waiver?.indemnity)}
                {applicableLawJSX()}
                {initialJSX('applicableLaw', waiver?.applicableLaw)}
                {bindingArbitrationJSX()}
                {initialJSX('binding', waiver?.binding)}
                {limitationOfWarrantyJSX()}
                {initialJSX('limitation', waiver?.limitation)}
                {severabilityOfProvisionsJSX()}
                {initialJSX('severability', waiver?.severability)}
                {continuationOfObligationsJSX()}
                {initialJSX('continuation', waiver?.continuation)}
                {acknowledgementOfWaiverOfRightsJSX()}
                {initialJSX('acknowledgement', waiver?.acknowledgement)}
                {physicalConditionJSX()}
                {initialJSX('physicalCondition', waiver?.physicalCondition)}
                {entireAgreementJSX()}
                {initialJSX('entire', waiver?.entire)}
                {photoAndVideoReleaseJSX()}
                {initialJSX('photoVideo', waiver?.photoVideo)}
                {equipmentJSX()}
                {initialJSX('equipment', waiver?.equipment)}
                {noRefundsJSX()}
                {initialJSX('noRefunds', waiver?.noRefunds)}
                {rightToRefuseServiceJSX()}
                {initialJSX('rightToRefuse', waiver?.rightToRefuse)}
                {fieldElevationJSX()}
                {initialJSX('fieldElevation', waiver?.fieldElevation)}
                <h5 className='fw-bold my-4 text-center'>
                    I have read this entire Agreement. I fully understand the entire Agreement and acknowledge that I had the
                    opportunity to consult with an attorney of my choosing if I so desire, and I agree to be legally bound
                    to this Agreement.
                </h5>
                <div>
                    <small>* By way of acceptance of this agreement, the Participant has affixed his or her signature on this day: {new Date().toDateString()}</small>
                </div>
            </Card.Body>
            <Card.Footer>
                {formJSX()}
                <div className='p-3'>
                    {isDZO ? <Button className='align-items-center d-flex fw-bold gap-2 justify-content-center shimmer w-100'
                        disabled={(disableDZOSave() !== 4 && !isLoading || dropzone?.waiver?.dateSigned) ? false : true}
                        onClick={() => saveClicked()}
                        variant={(disableDZOSave() === 4 || dropzone?.waiver?.dateSigned) ? 'success' : 'danger'} 
                        size='lg'>
                        {!dropzone?.waiver?.dateSigned ? `Confirm Your Dropzone's Waiver ` : 'Waiver Signed By DZO'}<CheckCircleFill />
                    </Button> : <Button className='align-items-center d-flex gap-3 justify-content-center w-100'
                        disabled={validateWaiverFilledOutCorrectly() || isLoading}
                        onClick={() => didClickAgree()}
                        size='lg'
                        variant={!signedBy ? 'primary' : 'outline-secondary'}>
                        {!signedBy && <>Agree To This Document {isLoading && <Spinner animation='border' size='sm' />}</>}
                        {signedBy !== null && <>
                            Document Signed On {new Timestamp(waiver?.dateSigned?.seconds, waiver?.dateSigned?.nanoseconds)?.toDate()?.toDateString()}
                        </>}
                    </Button>}
                </div>
            </Card.Footer>
        </Card>;
    }

    return <>
        <div ref={topRef} />
        <div className='fadeIn pb-5 px-5'>
            {disableDZOSave() !== 4 && <div className='align-items-center d-flex'>
                {!isDZO && !signedBy && <div>
                    <ArrowLeftCircleFill className='cursor fs-2 text-danger' onClick={() => setJumperType && setJumperType(null)} />
                </div>}
                <div className='flex-fill'>
                    <h4 className='fadeInFast mb-4 mt-5 text-center'>
                        {isDZO ? <>
                            <span>This is a preview of the waiver that your customers will see. <br />
                                <span className={['fw-medium', !dropzone?.waiver?.dateSigned  ? 'text-danger' : 'text-success'].join(' ')}>Jumpers are matched to this waiver based off their PHONE NUMBER</span>
                                <br /><span hidden={!signedBy}>DZO Signature is needed in 4 places on this form.</span></span><br />
                            <span style={{ fontSize: '13px' }}><span className='cursor fw-bold underline'>CONTACT US</span> if your DZ needs a custom waiver.</span>
                        </> : <span className='underline'>Waiver for {dropzone?.name}</span>}
                    </h4>
                </div>
                {!isDZO && <div />}
            </div>}
            <Row>
                <Col className='d-none d-md-block' lg='1' />
                <Col>
                    {waiverJSX()}
                </Col>
                <Col className='d-none d-md-block' lg='1' />
            </Row>
            {alertJSX()}
        </div>
    </>;
}
export default Waiver;