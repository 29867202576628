import { useEffect, useState } from 'react';

import { addDoc, collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { Button, Collapse, Form, Spinner } from 'react-bootstrap';
import { Calendar2MonthFill, InfoCircleFill } from 'react-bootstrap-icons';
import { getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import ApiCalls from '../apiCalls';
import Utilities from '../utils';

const Account = ({ currentUser, loads, newSubscription, setNewSubscription, subscriptionNumMonths }) => {
    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();
    const utils = new Utilities();

    const [isLoading, setIsLoading] = useState(true);
    const [numMonths, setNumMonths] = useState<any>('');
    const [purchaseStep, setPurchaseStep] = useState(1);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        loadStripeLocally();
        checkForSubscription();
    }, []);

    useEffect(() => {
        if (newSubscription?.id && utils?.diffInMins(newSubscription?.endDate) > 0) setPurchaseStep(3);
    }, [newSubscription]);

    useEffect(() => {
        if (subscriptionNumMonths && currentUser) saveNewSubscription();
    }, [currentUser, subscriptionNumMonths]);

    const checkForSubscription = async () => {
        let data = await apiCalls.getSubscriptionByUID(currentUser.uid);
        data?.length > 0 && setNewSubscription(data[0]);
        setIsLoading(false);
    }

    const loadStripeLocally = async () => {
        setStripePromise(await loadStripe('pk_live_51KDjVeC3dgGpDwNwqFtgI0W6lnUqy5TxTuSBA56JinZ6lMTPSp8KeT4LCFuK4j3tyJbegEcMtm0FAcI6GPpcNKAS00sdeaKf61'));
    }

    const saveNewSubscription = async () => {
        let date = moment(new Date()).add(subscriptionNumMonths, 'months').toDate()
        await apiCalls.addSubscription({
            endDate: date,
            numMonths: subscriptionNumMonths,
            uid: currentUser?.uid || ''
        });
        checkForSubscription();
    }

    const sendToCheckout = async () => {
        if (!auth.currentUser) return;
        setIsLoading(true);
        const colRef = collection(doc(db, 'deposits', auth.currentUser.uid), 'checkout_sessions')
        addDoc(colRef, {
            line_items: [
                {
                    price_data: {
                        currency: 'usd',
                        product_data: { name: `Subscription ${auth.currentUser.uid} - ${numMonths} Months` },
                        unit_amount: `${99 * numMonths}00`
                    },
                    quantity: 1,
                },
            ],
            cancel_url: `https://onjumprun.com/onjumprun?subscriptionCancel=true`,
            success_url: `https://onjumprun.com/onjumprun?subscriptionPurchased=true&subscriptionNumMonths=${numMonths}`,
            mode: 'payment',
        }).then(async (d) => {
            onSnapshot(d, async (doc) => {
                const { error, sessionId } = doc.data();
                if (error) alert(`wah wah wah. An error occured: ${error.message}`);
                if (sessionId) await stripePromise.redirectToCheckout({ sessionId });
            });
        });
    }

    //-----Pure JSX Functions-----//

    const monthlySubscriptionsJSX = () => {
        const freeLoadsRemaining = (loads?.length >= 25 ? 0 : 25 - loads?.length);
        return <div className='align-items-center border-bottom border-top d-flex justify-content-between p-4'>
            <div className='flex-fill'>
                <h5 className='mb-3'>
                    <Calendar2MonthFill className='me-2 text-danger' />
                    <u>Monthly Subscription</u>
                </h5>
                {newSubscription?.id ? <p className='mb-1'>Thanks for getting OnJumprun!<br /> Contact rolandandrewforbes@gmail.com.<br /> if you have any problems with the site.</p> :
                    <p className='mb-1'>First 5 loads sent are free!<br /> After that it's $99/month.<br /> Only pay for the months your dropzone is open.</p>}
            </div>
            <div className='d-flex flex-fill justify-content-end'>
                {isLoading ? <Spinner animation='border' /> : <>
                    <Collapse dimension='width' in={purchaseStep === 1}>
                        <div>
                            <h4 className='mb-1 no-word-wrap text-end'>{freeLoadsRemaining} free load{freeLoadsRemaining === 1 ? '' : 's'} remaining!</h4>
                            <div>
                                <div className='d-flex justify-content-end no-word-wrap'>
                                    <small className='fw-medium shimmer text-end'>
                                        After that you'll need a subscription.
                                    </small>
                                </div>
                                <div>
                                    <Button className='fw-medium mt-4 no-word-wrap w-100' 
                                        onClick={() => setPurchaseStep(2)} 
                                        variant='warning'>
                                        Purchase Subscription
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <Collapse dimension='width' in={purchaseStep === 2}>
                        <div>
                            <h3 className='mb-1 no-word-wrap text-end'>Purchase Subscription</h3>
                            <div>
                                <div className='d-flex gap-3 justify-content-end no-word-wrap'>
                                    <small className='fw-medium shimmer text-end'>
                                        How many months <br /> would you like to purchase?
                                    </small>
                                    <Form.Control max='12' 
                                        min='1'
                                        onChange={(e) => setNumMonths(e?.target?.value)}
                                        placeholder='0'
                                        style={{ width: '75px' }}
                                        type='number'
                                        value={numMonths || ''} />
                                </div>
                                <div className='align-items-center d-flex gap-1 justify-content-evenly mt-2'>
                                    <div className='flex-fill'>
                                        <Button className='w-100' onClick={() => setPurchaseStep(1)} variant='light'>
                                            Back
                                        </Button>
                                    </div>
                                    <div className='flex-fill'>
                                        <Button className='fw-medium no-word-wrap w-100'
                                            disabled={numMonths === '' || isLoading}
                                            onClick={() => sendToCheckout()}
                                            variant={numMonths === '' ? 'danger' : 'primary'}>
                                            Next
                                            {isLoading && <Spinner animation='border' className='ms-2' size='sm' />}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <Collapse dimension='width' in={purchaseStep === 3}>
                        <div>
                            <h3 className='mb-1 no-word-wrap text-end'>Subscription Active!</h3>
                            <div>
                                <div className='d-flex gap-3 justify-content-end no-word-wrap'>
                                    <small className='fw-medium shimmer text-end'>
                                        Your subscription ends <br />{new Timestamp(newSubscription?.endDate?.seconds, newSubscription?.endDate?.nanoseconds).toDate().toDateString()}
                                    </small>
                                </div>
                                <div className='align-items-center d-flex gap-1 justify-content-evenly mt-2'>
                                    <div className='flex-fill'>
                                        <Button className='fw-medium no-word-wrap w-100' onClick={() => navigate('/contact')} variant='outline-danger'>
                                            Contact OnJumprun
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </>}
            </div>
        </div>;
    }

    const personalInformationJSX = () => {
        return <div className='border-bottom p-4'>
            <h5>
                <InfoCircleFill className='me-2 text-primary' />
                <u>Personal Information</u>
            </h5>
            <p className='fw-medium mb-0'>Phone number: {currentUser?.phoneNumber}</p>
            <p className='fw-medium mb-0'>Total Revenue: Coming soon...</p>
        </div>;
    }

    return <div>
        {monthlySubscriptionsJSX()}
        {personalInformationJSX()}
    </div>
}
export default Account;