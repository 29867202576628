import { useContext, useEffect, useState } from 'react';

import { ArrowRight, Check, CheckCircleFill, FlagFill, Lock, StopFill, XCircle } from 'react-bootstrap-icons';
import { Badge, Button, Card, Collapse, Form, Modal } from 'react-bootstrap';
import { Timestamp } from 'firebase/firestore';

import ApiCalls from '../apiCalls';
import { IDropzoneData, ISignWaiverData, ISoloJumperData, ITandemStudentData } from '../models';
import JumperBalance from './JumperBalance';
import JumperTypeDropdown from './JumperTypeDropdown';
import MMDDYYYY from './MMDDYYYY';
import moment from 'moment';
import OnJumprunContext from '../context';
import Utilities from '../utils';

interface ISideBarProps {
    currentUser: any,
    didUpdateJumperInfo: (data: ISoloJumperData) => void,
    dropzone: IDropzoneData,
    payments: any,
    selectedJumper: ISoloJumperData,
    selectedTandemStudent?: ITandemStudentData,
    setRefetchPayments: (val: boolean) => void,
    setSelectedJumper: (data: ISoloJumperData) => void,
    setSelectedTandemStudent?: (val: any) => void
}

const SideBar = ({ currentUser, didUpdateJumperInfo, dropzone, payments, selectedJumper, selectedTandemStudent, setRefetchPayments, setSelectedJumper, setSelectedTandemStudent }: ISideBarProps) => {
    const apiCalls = new ApiCalls();
    const utils = new Utilities();

    const { darkMode } = useContext(OnJumprunContext);

    const [edit, setEdit] = useState<any>(null);
    const [feedAmount, setFeedAmount] = useState<any>(0);
    const [feedTo, setFeedTo] = useState<any>(null);
    const [flagJumperClicked, setFlagJumperClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [newJumperData, setNewJumperData] = useState<any>(selectedJumper);
    const [waiver, setWaiver] = useState<ISignWaiverData>(new ISignWaiverData(dropzone?.id));

    useEffect(() => {
        if (newJumperData?.id) getSignedWaiverFromAPI(newJumperData.phone);
    }, [newJumperData]);

    useEffect(() => {
        if (selectedJumper?.id) setNewJumperData(selectedJumper);
        else if (selectedTandemStudent?.id) setNewJumperData(selectedTandemStudent);
        return () => { setNewJumperData(null) }
    }, [selectedJumper, selectedTandemStudent]);

    const confirmFlagJumper = () => {
        didUpdateJumperInfo({ ...newJumperData, flagged: true, flagActivity: true });
        setFlagJumperClicked(false);
    }

    const getNumberOfJumpsThisSeasonForThisJumper = () => {
        return 0;
    }

    const getSignedWaiverFromAPI = async (jumpersPhone: string) => {
        const data = await apiCalls.getSignedWaiverByDropzoneIdAndPhone(dropzone?.id, jumpersPhone || '');
        setWaiver(data?.length > 0 ? data[0] : new ISignWaiverData(dropzone?.id));
        setIsLoading(false);
    }

    const saveClicked = () => {
        let copy: any;
        copy = newJumperData;
        copy[edit?.field] = edit?.value;
        didUpdateJumperInfo(copy);
        setEdit(null);
    }

    const unflagJumper = () => {
        didUpdateJumperInfo({ ...newJumperData, flagged: false, flagActivity: true });
        setFlagJumperClicked(false);
    }

    //-----Pure JSX Functions-----//

    const flagJumperModalJSX = () => {
        return <Modal centered show={flagJumperClicked}>
            <Modal.Header>
                <Modal.Title className='text-center w-100'>
                    {newJumperData?.flagged ? 'Jumper Flagged' : 'Flag Jumper'}
                    <FlagFill className='ms-2 text-danger' />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-medium p-2 text-center'>
                    {newJumperData?.flagged ? <>THIS JUMPER IS CURRENTLY FLAGGED!<br />They cannot manifest for a load at your dropzone.</> :
                        <>Flagging a jumper will block them from getting on a load.<br />Are you sure you want to do this?</>}
                </p>
                <div className='d-flex gap-1 justify-content-end'>
                    <Button onClick={() => setFlagJumperClicked(false)} variant='outline-secondary'>Cancel</Button>
                    {newJumperData?.flagged ? <Button className='fw-bold'
                        onClick={() => unflagJumper()}
                        variant='success'>
                        Unflag Jumper
                    </Button> :
                        <Button className='fw-bold' onClick={() => confirmFlagJumper()} variant='danger'>
                            <u>Confirm Flag Jumper</u>
                        </Button>}
                </div>
            </Modal.Body>
        </Modal>
    }

    const mainContentJSX = () => {
        let readyToJump = (waiver?.id && ((newJumperData?.reserveCardCheckedBy !== '') || selectedTandemStudent?.id));
        if(newJumperData?.jumperType === 'Sport Jumper' && (moment(new Date()).diff(newJumperData?.reserveRepackDate, 'days') < 0 || moment(new Date()).diff(newJumperData?.reserveRepackDate, 'days') >= 180)) readyToJump = false;
        if(newJumperData?.jumperType === 'Sport Jumper' && moment(new Date()).diff(newJumperData?.uspaExp, 'days') > 0) readyToJump = false;

        return <Collapse dimension='width' in={(selectedJumper?.id || selectedTandemStudent?.id) ? true : false}>
            <div>
                <div className='ps-3'>
                    <Card body className={['cardNoMargin shadow-lg', darkMode ? 'border-light' : 'border-dark'].join(' ')} style={{ width: '25vw' }}>
                        <div style={{ padding: '1rem ' }}>
                            <p className='border-bottom d-flex justify-content-between mb-3 pb-2'>
                                <span>
                                    <span className='fw-bold pe-3 underline' style={{ fontSize: '20px' }}>{newJumperData?.firstName} {newJumperData?.lastName}</span>
                                </span>
                                <span className='d-flex gap-1'>
                                    <span>
                                        <Button onClick={() => setFlagJumperClicked(true)} size='sm' variant='outline-danger'>
                                            <FlagFill />
                                        </Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => { setSelectedJumper(null); setSelectedTandemStudent(null); setNewJumperData(null) }}
                                            size='sm'
                                            variant={darkMode ? 'outline-light' : 'outline-primary'}>
                                            <ArrowRight />
                                        </Button>
                                    </span>
                                </span>
                            </p>
                            {textInputJSX('firstName', newJumperData?.firstName, 'First Name', 'text')}
                            {textInputJSX('lastName', newJumperData?.lastName, 'Last Name', 'text')}
                            <div className={['d-flex justify-content-between', newJumperData?.jumperType === 'Tandem' ? 'mb-1' : ''].join(' ')}>
                                <small className='fw-bold'>Phone Number</small>
                                <small className='align-items-center d-flex fw-medium gap-1'>
                                    <span className='opacity-50'>
                                        <span className='opacity-25 pe-1'>({newJumperData?.countryCode})</span>
                                        {newJumperData?.phone}
                                    </span>
                                    <Lock className='opacity-75 text-danger' />
                                </small>
                            </div>
                            {newJumperData?.jumperType !== 'Tandem' && <div className='d-flex gap-2 justify-content-between py-1'>
                                <small className='cursor fw-bold'>Jumper Type</small>
                                <JumperTypeDropdown disabled={newJumperData?.jumperType === 'Tandem'} dontShowTandemItem={false} hideTandy={true}
                                    jumperType={newJumperData?.jumperType}
                                    setJumperType={(e) => {
                                        didUpdateJumperInfo({ ...newJumperData, jumperType: e });
                                        setNewJumperData({ ...newJumperData, jumperType: e });
                                    }} />
                            </div>}
                            {newJumperData && <MMDDYYYY bold={true}
                                disabled={true}
                                newReservation={newJumperData}
                                setNewReservation={setNewJumperData}
                                size='sm' />}
                            {newJumperData?.jumperType === 'Tandem' && textInputJSX('email', newJumperData?.email, 'Email', 'email')}
                            <div className='border-bottom mb-2 pb-2'>
                                {textInputJSX('weight', newJumperData?.weight, 'Weight', 'number')}
                            </div>
                            {textInputJSX('emergencyContact', newJumperData?.emergencyContact, 'Emergency Contact', 'text')}
                            {textInputJSX('emergencyPhone', newJumperData?.emergencyPhone, 'Emergency Phone', 'number',
                                'black', (!edit?.value || edit.value?.length === 10) ? false : true)}
                            <div className='border-bottom d-flex justify-content-between mb-2 pb-2'>
                                <small className={['cursor fw-bold', !waiver?.id || newJumperData?.flagged ? 'text-danger underline' : 'text-success'].join(' ')}>
                                    <u>WAIVER DATE: </u>
                                </small>
                                <small className={['cursor fw-medium', readyToJump ? 'text-success underline' : ''].join(' ')}
                                    onClick={() => waiver?.id && window?.open(`/${dropzone?.id}/signwaiver?signedBy=${newJumperData?.phone}`)}>
                                    {waiver?.id ? new Timestamp(waiver?.dateSigned?.seconds, waiver?.dateSigned?.nanoseconds).toDate().toDateString() :
                                        <span className='fw-bold text-danger underline'>NOT SIGNED</span>}
                                </small>
                            </div>
                            <JumperBalance balance={utils?.calculateJumperBalance(payments)}
                                currentUser={currentUser}
                                dropzone={dropzone}
                                jumper={newJumperData}
                                setRefetchPayments={setRefetchPayments} />
                            {newJumperData?.jumperType === 'Sport Jumper' && <div className='py-2 border-bottom'>
                                {textInputJSX('reserveCardCheckedBy', newJumperData?.reserveCardCheckedBy || '', 'Reserve Card Checked By', 'text', !newJumperData?.reserveCardCheckedBy ? 'red' : 'green')}
                            </div>}
                            {selectedTandemStudent?.id ? tandemsJSX() : soloJumperJSX()}
                        </div>
                        {!isLoading && <div className='pb-3 px-3'>
                            <Badge className='align-items-center d-flex gap-1 justify-content-center opacity-75 p-2 w-100'
                                bg={readyToJump ? 'success' : 'danger'}
                                style={{ fontSize: '14px' }}>
                                {readyToJump ? 'Ready to jump!' : newJumperData?.flagged ? 'JUMPER FLAGGED' : 'NOT READY TO JUMP'}
                                {readyToJump ? <CheckCircleFill /> : newJumperData?.flagged ? <StopFill /> : <XCircle />}
                            </Badge>
                        </div>}
                    </Card>
                </div>
            </div>
        </Collapse>;
    }

    const soloJumperJSX = () => {
        const showUSPA = (newJumperData?.jumperType === 'Sport Jumper');
        return <div className='pt-2'>
            {textInputJSX('licenses', newJumperData?.licenses, 'Licenses', 'text')}
            {textInputJSX('uspaNumber', newJumperData?.uspaNumber, 'USPA Membership #', 'number')}
            {textInputJSX('uspaExp', newJumperData?.uspaExp, 'USPA Expiration', 'date')}
            {showUSPA && textInputJSX('reserveRepackDate', newJumperData?.reserveRepackDate, 'Reserve Repack Date', 'date')}
            <div className={['align-items-center d-flex gap-2 justify-content-between', newJumperData?.flagged ? 'opacity-50' : ''].join(' ')}>
                <small className='fw-bold'>Jumps this season</small>
                <small className='fw-bold'>{getNumberOfJumpsThisSeasonForThisJumper()}</small>
            </div>
        </div>;
    }

    const tandemsJSX = () => {
        return  <>
            {/* <div className='align-items-center d-flex justify-content-between'>
                <small className='cursor fw-bold'>Group Id:</small>
                <small className='align-items-center d-flex gap-1 cursor fw-medium'> <Lock />37398</small>
            </div>
            <div className='align-items-center d-flex justify-content-between gap-2 mb-2'>
                <small className='cursor fw-bold'>Group Name: </small>
                <small className='align-items-center d-flex gap-1 cursor fw-medium'> <Lock />Damher Party</small>
            </div>
            <div className='border-bottom mb-2 pb-2'>
                <div className='align-items-center d-flex justify-content-between'>
                    <small className='fw-bold'>Feed other account: </small>
                    <small className='cursor fw-bold' hidden={!feedTo?.name}>Amount</small>
                </div>
                <div className={['align-items-center d-flex pb-1', feedTo?.name ? 'gap-2' : ''].join(' ')}>
                    <div className='flex-fill'>
                        <Dropdown>
                            <Dropdown.Toggle className='py-0 w-100' size='sm' variant={darkMode ? 'outline-light' : 'outline-dark'}>
                                {feedTo?.name || 'Select jumper'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu align='end' className='py-1 text-end' variant='dark' style={{ fontSize: '13px' }}>
                                {jumpers?.map(j => {
                                    return <Dropdown.Item key={`feed-person-${j?.name}`} onClick={() => setFeedTo(j)}>{j?.firstName} {j?.lastName}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div>
                        <Collapse dimension='width' in={feedTo?.name}>
                            <div>
                                <Form.Control className='py-0' onChange={(e) => setFeedAmount(e?.target?.value)} placeholder='$' size='sm' 
                                    style={{ minHeight: '24px', width: '70px' }} type='number' value={feedAmount} />
                            </div>
                        </Collapse>
                    </div>
                </div>
                <Collapse in={feedTo?.name}>
                    <div>
                        <div className='d-flex gap-1'>
                            <Button className='py-0 flex-fill' onClick={() => { setFeedTo(null); setFeedAmount(0); }} 
                                size='sm' variant={darkMode ? 'outline-light' : 'outline-secondary'}>
                                Cancel
                            </Button>
                            <Button className='align-items-center d-flex justify-content-center gap-1 py-0' disabled={!feedAmount || feedAmount == 0} 
                                size='sm' variant='primary'>
                                Confirm feed<Check />
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </div> */}
            <div className='align-items-center d-flex gap-2 justify-content-between'>
                <small className={['fw-bold', newJumperData?.flagged ? 'opacity-25 text-danger' : ''].join(' ')}>Still photos</small>
                <Form.Check checked={newJumperData?.stills || false}
                    disabled={newJumperData?.flagged}
                    isInvalid={newJumperData?.flagged}
                    onBlur={() => saveClicked()} onChange={(e: any) => {
                        setEdit({ field: 'stills', value: e?.currentTarget?.checked });
                        setNewJumperData({ ...newJumperData, stills: e?.currentTarget?.checked })
                    }}
                    style={{ height: '20px' }}
                    type='switch' />
            </div>
            <div className='align-items-center d-flex gap-2 justify-content-between'>
                <small className={['fw-bold', newJumperData?.flagged ? 'opacity-25 text-danger' : ''].join(' ')}>Handcam video</small>
                <Form.Check checked={newJumperData?.video || false}
                    disabled={newJumperData?.flagged}
                    isInvalid={newJumperData?.flagged}
                    onBlur={() => saveClicked()}
                    onChange={(e: any) => {
                        setEdit({ field: 'video', value: e?.currentTarget?.checked });
                        setNewJumperData({ ...newJumperData, video: e?.currentTarget?.checked })
                    }}
                    style={{ height: '20px' }}
                    type='switch' />
            </div>
            <div className='align-items-center d-flex gap-2 justify-content-between'>
                <small className={['fw-bold', newJumperData?.flagged ? 'opacity-25 text-danger' : ''].join(' ')}>Goggles</small>
                <Form.Check checked={newJumperData?.goggles || false}
                    disabled={newJumperData?.flagged}
                    isInvalid={newJumperData?.flagged}
                    onBlur={() => saveClicked()}
                    onChange={(e: any) => {
                        setEdit({ field: 'goggles', value: e?.currentTarget?.checked });
                        setNewJumperData({ ...newJumperData, goggles: e?.currentTarget?.checked })
                    }}
                    style={{ height: '20px' }}
                    type='switch' />
            </div>
        </>;
    }

    const textInputJSX = (field, value, title, inputType, color?, isInvalid?) => {
        const showInput = edit?.value === undefined || title !== edit?.title;
        const needsRepack = field === 'reserveRepackDate' ? ((moment(new Date()).diff(value, 'days') > 180) ? true : false) : false;
        const uspaExpired = field === 'uspaExp' ? moment(new Date()).diff(value, 'days') <= 0 ? false : true : false;

        return <div className='d-flex gap-1 justify-content-between'>
            <small className={['cursor fw-bold', (color === 'red' || (needsRepack || uspaExpired) || newJumperData?.flagged ) ? 'text-danger shimmer underline' : '', newJumperData?.flagged ? 'opacity-50' : ''].join(' ')}>
                {needsRepack && 'NEEDS REPACK:'}
                {uspaExpired && 'USPA EXPIRED:'}
                {!needsRepack && !uspaExpired && title}
            </small>
            <div>
                <Collapse in={edit === null || title !== edit?.title}>
                    <div>
                        <small className={[
                                'cursor fw-medium', 
                                showInput ? 'fadeInFast' : '', 
                                newJumperData?.flagged ? 'opacity-50 text-danger' : '', 
                                (needsRepack || uspaExpired) ? 'text-danger' : ''
                            ].join(' ')} 
                            hidden={edit !== null && title === edit?.title} 
                            onClick={() => !newJumperData?.flagged && setEdit({ field, value: value ?? '', title, inputType })}>
                            {value && value !== '' ? (inputType === 'date' ? value : value ): '--'}
                        </small>
                    </div>
                </Collapse>
                <Collapse in={!showInput}>
                    <div>
                        <Form.Control className='mb-1 py-0'
                            disabled={newJumperData?.flagged}
                            isInvalid={isInvalid}
                            onChange={(e) => setEdit({ ...edit, value: e?.target?.value })}
                            onKeyUp={(e) => e?.key === 'Enter' && saveClicked()}
                            size='sm'
                            type={edit?.inputType || 'text'}
                            value={edit?.inputType === 'date' ? moment(edit.value).format('YYYY-MM-DD') : (edit?.value || '')} />
                        <div className='d-flex gap-1 mb-2'>
                            <Button className='flex-fill py-0'
                                onClick={() => setEdit(null)} 
                                size='sm'
                                variant={darkMode ? 'dark' : 'secondary'}>
                                Cancel
                            </Button>
                            <Button className='align-items-center d-flex gap-1 py-0'
                                disabled={isInvalid}
                                onClick={() => saveClicked()}
                                size='sm'
                                variant={isInvalid ? 'danger' : 'primary'}>
                                Save <Check />
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>;
    }

    return <>
        {mainContentJSX()}
        {flagJumperModalJSX()}
    </>
}
export default SideBar;