
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

//@ts-ignore
import whiteSoloIcon from './whiteSoloIcon.png';

interface IHeaderProps {
    activePage: 'HOME' | 'USE THE APP' | 'CONTACT'
}

const Header = ({ activePage }: IHeaderProps) => {
    const navigate = useNavigate();

    //-----Pure JSX Functions-----//

    return <Row>
        <Col>
            <div className='align-items-center aTagNoDecoration border-bottom d-flex gap-4 justify-content-between px-4 py-2'>
                <div onClick={() => navigate('/')}>
                    <img alt='OnJumprun Mini Icon' className='cursor' src={whiteSoloIcon} style={{ height: '36px' }} />
                </div>
                <div className='d-flex gap-4 pe-3'>
                    <Link to='/'>
                        <small className={['cursor fw-bold whiteOnHover', activePage === 'HOME' ? 'shimmer text-light underline' : 'text-secondary'].join(' ')}>HOME</small>
                    </Link>
                    <Link to='/login'>
                        <small className={['cursor fw-bold whiteOnHover', activePage === 'USE THE APP' ? 'shimmer text-light underline' : 'text-secondary'].join(' ')}>USE THE APP</small>
                    </Link>
                    <Link to='/contact'>
                        <small className={['cursor fw-bold whiteOnHover', activePage === 'CONTACT' ? 'shimmer text-light underline' : 'text-secondary'].join(' ')}>CONTACT</small>
                    </Link>
                </div>
            </div>
        </Col>
    </Row>;
}
export default Header;