import { useEffect, useState } from 'react';

import { Button, Collapse, Form, Modal, Table } from 'react-bootstrap';
import { PersonAdd } from 'react-bootstrap-icons';

import ApiCalls from '../apiCalls';
import { IEmployeeData } from '../models';
import JumperTypeDropdown from './JumperTypeDropdown';
import MMDDYYYY from './MMDDYYYY';
import PhoneNumber from './PhoneNumber';
import Utilities from '../utils';

const Employees = ({ dropzoneId, employees, setRefetchEmployees, setShowEmployeeModal, setToastMessage, showEmployeeModal }) => {
    const apiCalls = new ApiCalls();
    const utils = new Utilities();

    const [newEmployee, setNewEmployee] = useState<IEmployeeData>(new IEmployeeData(dropzoneId));
    const [phoneTaken, setPhoneTaken] = useState(false);

    useEffect(() => {
        if (dropzoneId && !showEmployeeModal) setNewEmployee(new IEmployeeData(dropzoneId));
    }, [dropzoneId, showEmployeeModal]);

    const addNewEmployee = async () => {
        const newEmployeeData: IEmployeeData = {
            ...newEmployee,
            dropzoneId,
            email: newEmployee?.email || '',
            flagged: false,
            hasTandemRating: newEmployee?.hasTandemRating || false,
            id: newEmployee?.id || '',
            isSkydiver: newEmployee?.isSkydiver || false,
            licenses: '',
            jumperType: 'Employee',
            numberOfJumpsThisYear: 0,
            reserveRepackDate: newEmployee?.reserveRepackDate || '',
            tandemExpirationDate: newEmployee?.tandemExpirationDate || '',
            tandemLicenseNumber: newEmployee?.tandemLicenseNumber || '',
            totalJumps: 0,
            uid: '',
            uspaExp: newEmployee?.uspaExp || '',
            uspaNumber: newEmployee?.uspaNumber || 0,
            waiverDate: '',
            weight: newEmployee?.weight || 0,
            wingLoading: newEmployee?.wingLoading || '',
            yearsInSport: '',
        };
        await apiCalls.addEmployee(newEmployeeData);
        setRefetchEmployees(true);
    }

    const saveEmployeeClicked = () => {
        setToastMessage({ message: newEmployee?.id ? 'Employee Information Updated' : 'New Employee Added', variant: 'success' });
        newEmployee?.id ? updateEmployee() : addNewEmployee();
        setShowEmployeeModal(false);
    }

    const updateEmployee = async () => {
        await apiCalls.updateEmployee(newEmployee?.id, { ...newEmployee, id: newEmployee?.id || '', active: true, dropzoneId });
        setRefetchEmployees(true);
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        const isFatty = (newEmployee?.weight > 250);
        return <Modal centered show={showEmployeeModal}>
            <Modal.Header>
                <Modal.Title className='align-items-center d-flex gap-2 mb-0'>
                    {newEmployee?.id ? <span>Edit employee information</span> : <span>Add new employee</span>}
                    <PersonAdd />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='px-3'>
                    <div className='d-flex gap-2'>
                        <div className='flex-fill'>
                            <Form.Group className='pb-2'>
                                <Form.Label as='small' className='fw-medium'>First name</Form.Label>
                                <Form.Control isInvalid={utils?.stringHasNumber(newEmployee?.firstName)}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, firstName: e.target.value })} 
                                    type='text' 
                                    value={newEmployee?.firstName || ''} />
                            </Form.Group>
                        </div>
                        <div className='flex-fill'>
                            <Form.Group className='pb-2'>
                                <Form.Label as='small' className='fw-medium'>Last name</Form.Label>
                                <Form.Control isInvalid={utils?.stringHasNumber(newEmployee?.lastName)}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, lastName: e.target.value })} 
                                    type='text' 
                                    value={newEmployee?.lastName || ''} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='align-items-center d-flex gap-2'>
                        <Form.Group className='pb-2'>
                            <Form.Label as='small' className='fw-medium mb-1'>Job Title</Form.Label>
                            <Form.Control isInvalid={newEmployee?.jobTitle === ''}
                                onChange={(e) => setNewEmployee({ ...newEmployee, jobTitle: e?.target?.value })}
                                placeholder='Ground crew, Manifest, etc..'
                                value={newEmployee?.jobTitle || ''} />
                        </Form.Group>
                        <Form.Group className='flex-fill'>
                            <PhoneNumber fontLight={true}
                                field='phone'
                                newJumperInfo={newEmployee}
                                phoneIsTaken={phoneTaken}
                                setNewJumperInfo={setNewEmployee}
                                setPhoneIsTaken={setPhoneTaken} />
                        </Form.Group>
                        <Collapse dimension='width' in={newEmployee?.isSkydiver}>
                            <div>
                                <Form.Group className='pb-2'>
                                    <Form.Label as='small' className={['fw-medium mb-2 no-word-wrap', isFatty ? 'text-danger' : ''].join(' ')}>
                                        Weight ({isFatty ? 'wtf bro' : 'lbs'})
                                    </Form.Label>
                                    <Form.Control isInvalid={isFatty || (!newEmployee?.weight && newEmployee?.isSkydiver)}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, weight: e?.target?.value as any })}
                                        placeholder='lbs'
                                        style={{ width: '101px' }}
                                        type='number'
                                        value={newEmployee?.weight || ''} />
                                </Form.Group>
                            </div>
                        </Collapse>
                    </div>
                    <div>
                        <MMDDYYYY newReservation={newEmployee} setNewReservation={setNewEmployee} />
                    </div>
                    <div className='d-flex gap-3 pb-2'>
                        <div className='flex-fill'>
                            <Form.Group>
                                <div>
                                    <Form.Label as='small' className='fw-medium mb-1'>Emergency Contact</Form.Label>
                                </div>
                                <Form.Control isInvalid={utils?.stringHasNumber(newEmployee?.emergencyContact)}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, emergencyContact: e?.target?.value })}
                                    type='text' 
                                    value={newEmployee?.emergencyContact || ''} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-1'>Emergency Phone #</Form.Label>
                                <Form.Control isInvalid={newEmployee?.emergencyPhone?.length > 0 && newEmployee?.emergencyPhone?.length !== 10}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, emergencyPhone: e?.target?.value })}
                                    type='number' 
                                    value={newEmployee?.emergencyPhone || ''} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='border-top d-flex justify-content-between py-2'>
                        <h6 className={['fw-medium mb-0', newEmployee?.isSkydiver ? 'underline' : 'opacity-75'].join(' ')}>
                            {newEmployee?.isSkydiver ? 'Employee Skydiver Information' : 'Employee is a Skydiver'}
                        </h6>
                        <Form.Check checked={newEmployee?.isSkydiver}
                            onChange={(e: any) => setNewEmployee({ ...newEmployee, isSkydiver: e?.currentTarget?.checked })}
                            type='switch' />
                    </div>
                    <Collapse in={newEmployee?.isSkydiver}>
                        <div>
                            {reserveRepackAndJumperTypeJSX(true)}
                            {uspaSectionJSX(true)}
                            <div className='align-items-center d-flex justify-content-between gap-2 py-2'>
                                <div className='align-items-center d-flex gap-2'>
                                    <Form.Check checked={newEmployee?.hasTandemRating} 
                                        onChange={(e) => setNewEmployee({ ...newEmployee, hasTandemRating: e?.currentTarget?.checked })}
                                        type='checkbox' />
                                    <h6 className={['mb-0', !newEmployee?.hasTandemRating ? 'opacity-75' : ''].join(' ')}>
                                        Has Tandem Rating
                                    </h6>
                                </div>
                                <Form.Group className={newEmployee?.hasTandemRating ? '' : 'opacity-50'}>
                                    <Form.Label as='small' className='fw-medium mb-2 no-word-wrap'>Tandem License Number</Form.Label>
                                    <Form.Control disabled={!newEmployee?.hasTandemRating} 
                                        isInvalid={newEmployee?.tandemLicenseNumber === '' && newEmployee?.hasTandemRating}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, tandemLicenseNumber: e?.target?.value })}
                                        placeholder='Your rating number...'
                                        size='sm'
                                        value={newEmployee?.tandemLicenseNumber} />
                                </Form.Group>
                                <Form.Group className={newEmployee?.hasTandemRating ? '' : 'opacity-50'}>
                                    <Form.Label as='small' className='fw-medium mb-2 no-word-wrap'>Exp Date</Form.Label>
                                    <Form.Control disabled={!newEmployee?.hasTandemRating} 
                                        onChange={(e) => setNewEmployee({ ...newEmployee, tandemExpirationDate: e?.target?.value })}
                                        placeholder='Your rating number...'
                                        size='sm'
                                        type='date'
                                        value={newEmployee?.tandemExpirationDate} />
                                </Form.Group>
                            </div>
                        </div>
                    </Collapse>
                </Form>
            </Modal.Body>
            <Modal.Footer className='p-2'>
                <div className='d-flex gap-1 justify-content-end w-100'>
                    <div>
                        <Button size='sm' variant='secondary' onClick={() => setShowEmployeeModal(false)}>
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button disabled={newEmployee?.firstName === '' || newEmployee?.lastName === '' || newEmployee?.phone?.length !== 10 || newEmployee?.jobTitle === ''
                            || (newEmployee?.hasTandemRating && (newEmployee?.tandemLicenseNumber === '' || !newEmployee.tandemExpirationDate))}
                            onClick={() => saveEmployeeClicked()}
                            size='sm'>
                            {newEmployee?.id ? 'Update' : 'Save'} Employee
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }

    const reserveRepackAndJumperTypeJSX = (showUSPASection) => {
        return <div>
            <div className='d-flex gap-2 justify-content-between pb-2'>
                <JumperTypeDropdown disabled={true}
                    dontShowTandemItem={true}
                    hideTandy={false}
                    jumperType='Employee'
                    setJumperType={(e) => setNewEmployee({ ...newEmployee, jumperType: e })} />
                <Form.Group className='flex-fill'>
                    <Form.Label as='small' className={['fw-medium mb-1', !showUSPASection ? 'opacity-25' : ''].join(' ')}>
                        Reserve Repack Date
                    </Form.Label>
                    <Form.Control disabled={!showUSPASection}
                        onChange={(e) => setNewEmployee({ ...newEmployee, reserveRepackDate: e?.target?.value })}
                        placeholder='MM/DD/YYYY' 
                        type='date' 
                        value={newEmployee?.reserveRepackDate} />
                </Form.Group>
            </div>
        </div>;
    }

    const uspaSectionJSX = (showUSPA) => {
        return <div className='d-flex gap-3 pb-2'>
            <Form.Group className='flex-fill'>
                <Form.Label as='small' className={['fw-medium mb-1', !showUSPA ? 'opacity-25' : ''].join(' ')}>
                    USPA Membership #
                </Form.Label>
                <Form.Control disabled={!showUSPA} 
                    onChange={(e) => setNewEmployee({ ...newEmployee, uspaNumber: e?.target?.value as any })}
                    placeholder='...' 
                    value={newEmployee?.uspaNumber} />
            </Form.Group>
            <Form.Group className='flex-fill'>
                <Form.Label as='small' className={['fw-medium mb-1', !showUSPA ? 'opacity-25' : ''].join(' ')}>
                    USPA Exp Date
                </Form.Label>
                <Form.Control disabled={!showUSPA} 
                    onChange={(e) => setNewEmployee({ ...newEmployee, uspaExp: e?.target?.value as any })}
                    placeholder='MM/DD/YYYY' 
                    type='date' 
                    value={newEmployee?.uspaExp} />
            </Form.Group>
        </div>;
    }

    return <div>
        <Table bordered hover striped>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Job Title</th>
                    <th>Instructor Ratings</th>
                </tr>
            </thead>
            <tbody>
                {employees?.length > 0 ? employees.map((e, i) => <tr key={`${dropzoneId}-employee-${i}`} onClick={() => { setNewEmployee(e); setShowEmployeeModal(true) }}>
                    <td>{i + 1}</td>
                    <td>{e?.firstName} {e?.lastName}</td>
                    <td>{e?.phone}</td>
                    <td>{e?.jobTitle}</td>
                    <td className={e?.hasTandemRating ? '' : 'opacity-25'}>{e?.hasTandemRating ? 'TI' : 'None'}</td>
                </tr>) : <tr>
                    <td className='fw-light p-5 text-center' colSpan={5}>no employees found</td>
                </tr>}
            </tbody>
        </Table>
        {modalJSX()}
    </div>;
}
export default Employees;