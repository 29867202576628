
import { Button, Modal, Spinner } from 'react-bootstrap';
import { EnvelopeCheck } from 'react-bootstrap-icons';

const Notification = ({ config, doAction, dropzones, isLoading, setShowModal, show, showModal }) => {

    const numNotifications = () => {
        let num = 0;
        if(config?.needsToAddDropzone && dropzones?.length < 1) num = num + 1;
        if(config?.needsWaiver) num = num + 1;
        return num;
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        return <Modal centered show={showModal}>
            <Modal.Header>
                <Modal.Title as='h5' className='d-flex justify-content-center w-100 underline'>Dropzone Setup Needed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-medium mb-0'>
                    Before you can start sending loads, your DZO needs to sign and confirm your dropzone's waiver.
                </p>
                <Button className='my-3 w-100'
                    disabled={!config?.needsWaiver}
                    onClick={() => { doAction('Waiver'); setShowModal(false) }}
                    variant={config?.needsWaiver ? 'danger' : 'success'}>
                    <EnvelopeCheck />
                    <small className='fw-bold ps-2'>
                        {config?.needsWaiver ? `GENERATE WAIVER` : 'Waiver Added!'}
                    </small>
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)} size='sm' variant='outline-secondary'>
                    Go back
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    return <div className='fadeInFast'>
       {show && !isLoading && <Button className='align-items-center d-flex gap-2 border-danger fw-bold text-danger' onClick={() => setShowModal(true)} size='sm' variant='light'>
            <Spinner animation='grow' size='sm' variant='danger' />
            <span className='shimmer underline'>To Do:</span> 
            {numNotifications()}
        </Button>}
        {modalJSX()}
    </div>;
}
export default Notification;