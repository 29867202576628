
import { Form } from 'react-bootstrap';
import { Lock } from 'react-bootstrap-icons';

import Utilities from '../utils';

interface IMMDDYYYYProps {
    bold?: boolean,
    clickedOn?: any[],
    disabled?: boolean,
    newReservation: any,
    setActiveInput?: (val: string) => void,
    setNewReservation: (val: any) => void,
    size?: 'lg' | 'sm'
}

const MMDDYYYY = ({ bold, clickedOn, disabled, newReservation, setActiveInput, setNewReservation, size }: IMMDDYYYYProps) => {
    const today = new Date();
    const utils = new Utilities();

    //-----Pure JSX Functions-----//

    const mainContentJSX = () => {
        const yearInvalid = (clickedOn?.find(c => c === 'YYYY') && utils?.checkIfYearIsInvalid(newReservation));
        const dayInvalid = (clickedOn?.find(c => c === 'DD') && (newReservation?.DD <= 0 || newReservation?.DD > 31 || isNaN(newReservation?.DD)) || yearInvalid);
        const monthInvalid = (clickedOn?.find(c => c === 'MM') && (newReservation?.MM <= 0 || newReservation?.MM > 12 || isNaN(newReservation?.MM) || !newReservation?.MM) || yearInvalid);

        return <>
            <div>
                <Form.Label className='align-items-center d-flex fw-medium justify-content-between mb-0 w-100' as='small'>
                    <span className={bold ? 'fw-bold' : ''}>DATE OF BIRTH</span>
                    {yearInvalid && <span className='fadeIn text-danger' style={{ fontSize: '10px' }}>MUST BE 18 YEARS OR OLDER</span>}
                    {!yearInvalid && disabled && <Lock className='text-danger' />}
                </Form.Label>
            </div>
            <div className='d-flex gap-2 mb-2'>
                <Form.Group className='flex-fill'>
                    <Form.Control className={disabled ? 'opacity-50' : ''}
                        disabled={disabled || false}
                        isInvalid={monthInvalid}
                        isValid={!monthInvalid && clickedOn?.find(f => f === 'MM')}
                        max='12'
                        min='1'
                        onChange={(e: any) => setNewReservation({ ...newReservation, MM: e?.target?.value })}
                        onFocus={() => setActiveInput && setActiveInput('MM')}
                        placeholder='MM'
                        size={size !== 'lg' ? size : null}
                        type='number'
                        value={newReservation?.MM || ''} />
                </Form.Group>
                <Form.Group className='flex-fill'>
                    <Form.Control className={disabled ? 'opacity-50' : ''}
                        disabled={disabled || false}
                        isInvalid={dayInvalid}
                        isValid={!dayInvalid && clickedOn?.find(f => f === 'DD')}
                        max='31'
                        min='1'
                        onChange={(e: any) => setNewReservation({ ...newReservation, DD: e?.target?.value })}
                        onFocus={() => setActiveInput && setActiveInput('DD')}
                        placeholder='DD'
                        size={size !== 'lg' ? size : null}
                        type='number'
                        value={newReservation?.DD || ''} />
                </Form.Group>
                <Form.Group className='flex-fill'>
                    <Form.Control className={disabled ? 'opacity-50' : ''}
                        disabled={disabled || false}
                        isInvalid={yearInvalid}
                        isValid={!yearInvalid && clickedOn?.find(f => f === 'YYYY')}
                        min={today?.getFullYear() - 110}
                        onChange={(e: any) => setNewReservation({ ...newReservation, YYYY: e?.target?.value })}
                        onFocus={() => setActiveInput && setActiveInput('YYYY')}
                        placeholder='YYYY'
                        size={size !== 'lg' ? size : null}
                        type='number'
                        value={newReservation?.YYYY || ''} />
                </Form.Group>
            </div>
        </>;
    }

    return mainContentJSX();
}
export default MMDDYYYY;