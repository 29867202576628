import { useEffect, useState } from 'react';

import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';

import ApiCalls from '../apiCalls';
import { IDropzoneData, ISignWaiverData } from '../models';
import Waiver from './Waiver';

const SignWaiver = ({ setToastMessage }) => {
    const apiCalls = new ApiCalls();
    const { dropzoneId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [activeState, setActiveState] = useState<'BLANK' | 'SIGNING'>('BLANK');
    const [dropzone, setDropzone] = useState(new IDropzoneData());
    const [jumperType, setJumperType] = useState<any>(null);
    const [signedBy, setSignedBy] = useState<any>(null);
    const [waiver, setWaiver] = useState(new ISignWaiverData(dropzoneId));

    useEffect(() => {
        getDropzoneDataFromAPI();
        getSignedWaiverFromAPI(searchParams?.get('signedBy'));
    }, []);

    useEffect(() => {
        if (jumperType) setActiveState('SIGNING');
        else setActiveState('BLANK');
    }, [jumperType]);

    const didSaveNewWaiver = async () => {
        await apiCalls.addSignWaiver(waiver);
        setToastMessage({ message: 'ALL SET! GO TELL THE PERSON AT THE FRONT DESK THAT YOUR WAIVER IS SIGNED.', variant: 'success' });
        setWaiver(new ISignWaiverData(dropzoneId));
        setActiveState('BLANK');
        setJumperType(null);
    }

    const getDropzoneDataFromAPI = async () => {
        const data = await apiCalls.getDropzoneByDropzoneId(dropzoneId);
        setDropzone(data as IDropzoneData);
    }

    const getSignedWaiverFromAPI = async (phone) => {
        const data = await apiCalls.getWaiverByPhoneNumber(phone);
        if(data?.length > 0) {
            setWaiver(data[0]);
            setActiveState('SIGNING');
            setSignedBy(data[0]);
        }
    }

    //-----Pure JSX Functions-----//

    const chooseTypeJSX = () => {
        return <div className='fadeIn fixed-center'>
            <h3 className='mb-0 text-center'>SKYDIVING WAIVER</h3>
            <p className='opacity-50 text-center'>Time to sign your life away</p>
            <div className='d-flex justify-content-center'>
                <ButtonGroup className='mb-2' size='lg'>
                    <Button className='fw-bold shimmer' onClick={() => setJumperType('First time')}>
                        First Time Skydivers
                    </Button>
                    <Button className='opacity-50' onClick={() => setJumperType('Experienced')} variant='outline-primary'>
                        Experienced Jumpers
                    </Button>
                </ButtonGroup>
            </div>
        </div>;
    }

    const loadingJSX = () => {
        return <div className='fixed-center'>
            <Spinner animation='border' />
        </div>;
    }

    const waiverJSX = () => {
        return <Waiver didSaveNewWaiver={didSaveNewWaiver}
            dropzone={dropzone}
            isDZO={false}
            jumperType={jumperType}
            setDropzone={setDropzone}
            setJumperType={setJumperType}
            setWaiver={setWaiver}
            signedBy={signedBy}
            waiver={waiver} />;
    }

    return <div>
        {dropzone?.id ? <>
            {activeState == 'BLANK' ? chooseTypeJSX() : waiverJSX()}
        </> : loadingJSX()}
    </div>;
}
export default SignWaiver;