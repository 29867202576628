import { useEffect, useState } from 'react';

import { addDoc, collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { Button, Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';

const Payments = ({ additionalJumpers, amount, disable, dropzoneId, newReservation, numParticipants, saveReservation, selectedTime }) => {
  const auth = getAuth();
  const db = getFirestore();

  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    loadStripeLocally();
  }, []);

  useEffect(() => {
    if(newReservation?.id) sendToCheckout();
  }, [newReservation?.id]);

  const loadStripeLocally = async () => {
    setStripePromise(await loadStripe('pk_live_51KDjVeC3dgGpDwNwqFtgI0W6lnUqy5TxTuSBA56JinZ6lMTPSp8KeT4LCFuK4j3tyJbegEcMtm0FAcI6GPpcNKAS00sdeaKf61'));
  }

  const sendToCheckout = async () => {
    if (!auth.currentUser) {
      setIsLoading(false);
      return;
    }
    const colRef = collection(doc(db, 'deposits', auth.currentUser.uid), 'checkout_sessions');
    addDoc(colRef, {
      line_items: [
        {
            price_data: {
                currency: 'usd',
                product_data: { name: 'Book Your Tandem Skydive' },
                unit_amount: `${amount}00`
            },
            quantity: 1,
        },
    ],
      cancel_url: `https://onjumprun.com/${dropzoneId}/booktandem?eName=${newReservation?.emergencyContact}&ePhone=${newReservation?.emergencyPhone}&weight=${newReservation?.weight}&paid=${true}&currentStep=4&lastName=${newReservation?.lastName}&firstName=${newReservation?.firstName}&amount=${amount}&email=${newReservation?.email}&phone=${newReservation?.phone}&MM=${newReservation?.MM}&DD=${newReservation?.DD}&YYYY=${newReservation?.YYYY}&date=${newReservation?.date}&amOrPM=${String(selectedTime).includes('pm') ? 'pm': 'am'}&numParticipants=${numParticipants}&id=${newReservation?.id}&cancelled=${true}`,
      success_url: `https://onjumprun.com/${dropzoneId}/booktandem?eName=${newReservation?.emergencyContact}&ePhone=${newReservation?.emergencyPhone}&weight=${newReservation?.weight}&paid=${true}&currentStep=4&lastName=${newReservation?.lastName}&firstName=${newReservation?.firstName}&amount=${amount}&email=${newReservation?.email}&phone=${newReservation?.phone}&MM=${newReservation?.MM}&DD=${newReservation?.DD}&YYYY=${newReservation?.YYYY}&date=${newReservation?.date}&amOrPM=${String(selectedTime).includes('pm') ? 'pm': 'am'}&numParticipants=${numParticipants}&id=${newReservation?.id}&additionalJumpers=${JSON.stringify(additionalJumpers)}`,
      mode: 'payment',
    }).then(async (d) => {
      onSnapshot(d, async (doc) => {
        const { error, sessionId } = doc.data();
        if (error) alert(`wah wah wah. An error occured: ${error.message}`);
        if (sessionId) {
          await stripePromise.redirectToCheckout({ sessionId });
          setIsLoading(false);
        }
      });
    });
  }

  //-----Pure JSX Functions-----//

  return <div>
    <Button className='align-items-center d-flex gap-2' disabled={disable || isLoading} onClick={() => { saveReservation(); setIsLoading(true); }} size='sm'>
      <span>Pay Deposit</span>
      {isLoading && <Spinner animation='border' size='sm' />}
    </Button>
  </div>;
}
export default Payments;
