import { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth';
import { LockFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import ApiCalls from './apiCalls';
import BookTandem from './components/BookTandem';
import ContactPage from './components/ContactPage';
import Context from './context';
import HomePage from './components/HomePage';
import { IDropzoneData } from './models';
import LoginPage from './components/LoginPage';
import Logo from './components/Logo';
import OnjumprunPage from './components/OnjumprunPage';
import PrivacyPage from './components/PrivacyPage';
import SignWaiver from './components/SignWaiver';
import Toastify from './components/Toast';
import './App.css';

firebase.initializeApp({
  apiKey: 'AIzaSyDZrAFYCZwzZnW28X2JxjO8twkHAXJ2US8',
  appId: '1:147361878402:web:51163152e3b4c75c3e3f9b',
  authDomain: 'freefall-solutions.firebaseapp.com',
  measurementId: 'G-DPNR8H9SF0',
  messagingSenderId: '147361878402',
  projectId: 'freefall-solutions',
  storageBucket: 'freefall-solutions.appspot.com'
});

const App = () => {
  const apiCalls = new ApiCalls();
  const auth = getAuth();

  const [activeTab, setActiveTab] = useState('Daily Manifest');
  const [blockMobile, setBlockMobile] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [darkMode, setDarkMode] = useState(false);
  const [dropzone, setDropzone] = useState<IDropzoneData | null>(new IDropzoneData());
  const [dropzones, setDropzones] = useState<IDropzoneData[]>([]);
  const [refetchDropzones, setRefetchDropzones] = useState(false);
  const [reset, setReset] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [toastMessage, setToastMessage] = useState<any>(null);

  useEffect(() => {
    if(window?.innerWidth < 1000) setBlockMobile(true);
  }, [activeTab])

  useEffect(() => {
    if(currentUser?.uid) getDropzonesByAuthId();
  }, [currentUser]);

  useEffect(() => {
    if(refetchDropzones) {
      setRefetchDropzones(false);
      getDropzonesByAuthId();
    }
  }, [refetchDropzones]);

  useEffect(() => {
     if(reset) setReset(false);
  }, [reset]);

  const confirmLogoutClicked = () => {
    setShowLogoutModal(false);
    setReset(true);
    auth?.signOut();
  }

  const darkModeClicked = () => {
    const isInDarkMode = document.documentElement.getAttribute('data-bs-theme') === 'dark';
    document.documentElement.setAttribute('data-bs-theme', isInDarkMode ? 'light' : 'dark');
    setDarkMode(isInDarkMode ? false : true);
  }

  const getDropzonesByAuthId = async () => {
    const data = await apiCalls.getDropzonesByAuthId(currentUser?.uid);
    if(data?.length > 0) setDropzone(data[0]);
    setDropzones(data);
  }

  //-----Pure JSX Functions-----//

  const blockMobileUserJSX = () => {
    return <div className='fixed-center' hidden={!blockMobile}>
      <div className='d-flex justify-content-center mb-3'>
        <Logo isMobile={true} />
      </div>
      <h6 className='fw-bold opacity-25 text-center mt-3'>DESKTOPS ONLY<br/> Mobile app coming soon</h6>
    </div>;
  }

  const logoutModalJSX = () => {
    return <Modal centered show={showLogoutModal}>
      <Modal.Header>
        <Modal.Title>Confirm logout?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='opacity-50 text-center'>Are you sure you want to logout? <br /> We're gonna miss that sweet face of yours.</Modal.Body>
      <Modal.Footer>
        <Button size='sm' variant={darkMode ? 'outline-light' : 'outline-secondary'} onClick={() => setShowLogoutModal(false)}>
          Cancel
        </Button>
        <Button className='align-items-center d-flex fw-bold gap-2 underline' onClick={() => confirmLogoutClicked()} size='sm' variant='danger'>
          Logout <LockFill />
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  const routesJSX = () => {
    return <BrowserRouter>
      <Routes>
        <Route path='/contact' element={<ContactPage setToastMessage={setToastMessage} />} />
        <Route path='/:dropzoneId/booktandem' element={<BookTandem />} />
        <Route path='/onjumprun' element={
          <HomePage auth={auth}
            blockMobile={blockMobile}
            currentUser={currentUser}
            darkModeClicked={darkModeClicked}
            didClickLogout={() => setShowLogoutModal(true)}
            dropzones={dropzones}
            reset={reset}
            setCurrentUser={setCurrentUser}
            setRefetchDropzones={setRefetchDropzones}
            setToastMessage={setToastMessage}
            toastMessage={toastMessage} />
        } />
        <Route path='/privacypolicy' element={<PrivacyPage />} />
        <Route path='/:dropzoneId/signwaiver' element={<SignWaiver setToastMessage={setToastMessage} />} />
        <Route element={<LoginPage setCurrentUser={setCurrentUser} />} path='/login' />
        <Route path='/*' element={<OnjumprunPage />} />
      </Routes>
    </BrowserRouter>;
  }

  return <Context.Provider value={{ activeTab, darkMode, dropzone, setActiveTab, setDarkMode, setDropzone, showNotificationModal, setShowNotificationModal }}>
    {blockMobileUserJSX()}
    <div className='overflow-x-none vh-100 vw-100' hidden={blockMobile} id='onJumprun'>
      {routesJSX()}
      {logoutModalJSX()}
      <Toastify message={toastMessage?.message} position='bottom-end' setMessage={setToastMessage} variant={toastMessage?.variant} />
    </div>
  </Context.Provider>;
}
export default App;