import { useState } from 'react';

import { Card, Collapse, Form } from 'react-bootstrap';
import { Calendar2Check, CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';
import moment from 'moment';

import { IDropzoneData } from '../models';

interface IDaysAndHoursOfOperationProps {
    darkMode,
    dropzone: IDropzoneData, 
    setDropzone: (val: IDropzoneData) => void,
}

const DaysAndHoursOfOperation = ({ darkMode, dropzone, setDropzone }: IDaysAndHoursOfOperationProps) => {
    const [show, setShow] = useState(false);

    const didSelectDayOfWeek = (day, val) => {
        let copy: any = { 
            Monday: dropzone?.hoursOfOperation?.Monday,
            Tuesday: dropzone?.hoursOfOperation?.Tuesday,
            Wednesday: dropzone?.hoursOfOperation?.Wednesday,
            Thursday: dropzone?.hoursOfOperation?.Thursday,
            Friday: dropzone?.hoursOfOperation?.Friday,
            Saturday: dropzone?.hoursOfOperation?.Saturday,
            Sunday: dropzone?.hoursOfOperation?.Sunday,
        };
        copy[day] = val;
        setDropzone({ ...dropzone, hoursOfOperation: { ...dropzone?.hoursOfOperation, ...copy } });
    }

    //-----Pure JSX Functions-----//

    const dayOfWeek = (day, val) => {
        return <div className='align-items-center cursor d-flex gap-2 position-relative'>
            <div>
                <Form.Check checked={val || false}
                    onChange={e => didSelectDayOfWeek(day, e?.currentTarget?.checked)}
                    style={{ position: 'absolute', top: '1px' }}
                    type='checkbox' />
            </div>
            <div>
                <small>{day}</small>
            </div>
        </div>;
    }

    return <Card className='mb-2'>
        <Card.Body className='p-0'>
            <div>
                <div className='align-items-center cursor d-flex justify-content-between p-3' onClick={() => setShow(!show)}>
                    <h5 className='align-items-center d-flex fw-bold gap-2 mb-0'>
                        <Calendar2Check /> Days & Hours of Operation
                    </h5>
                    {darkMode ? <CaretUpSquareFill /> : <CaretDownSquareFill />}
                </div>
                <Collapse in={show}>
                    <div>
                        <div className='d-flex gap-2 mb-2 mx-3 px-3'>
                            <Form.Group className='flex-fill'>
                                <Form.Label as='small' className='fw-medium mt-1'>Opening Day</Form.Label>
                                <Form.Control max={dropzone?.hoursOfOperation?.closingDay}
                                    onChange={e => setDropzone({
                                        ...dropzone, hoursOfOperation: {
                                            ...dropzone?.hoursOfOperation, openingDay: moment(e?.target?.value).format('YYYY-MM-DD')
                                        }
                                    })}
                                    type='date'
                                    value={dropzone?.hoursOfOperation?.openingDay as any || ''} />
                            </Form.Group>
                            <Form.Group className='flex-fill'>
                                <Form.Label as='small' className='fw-medium mt-1'>Closing Day</Form.Label>
                                <Form.Control min={dropzone?.hoursOfOperation?.openingDay}
                                    onChange={e => setDropzone({
                                        ...dropzone, hoursOfOperation: {
                                            ...dropzone?.hoursOfOperation, closingDay: moment(e?.target?.value).format('YYYY-MM-DD')
                                        }
                                    })}
                                    type='date'
                                    value={dropzone?.hoursOfOperation?.closingDay || ''} />
                            </Form.Group>
                        </div>
                        <div className='d-flex gap-3 m-3 pb-2' style={{ alignItems: 'stretch' }}>
                            <div>
                                <div className='mx-3 ps-4'>
                                    {dayOfWeek('Monday', dropzone?.hoursOfOperation?.Monday)}
                                    {dayOfWeek('Tuesday', dropzone?.hoursOfOperation?.Tuesday)}
                                    {dayOfWeek('Wednesday', dropzone?.hoursOfOperation?.Wednesday)}
                                    {dayOfWeek('Thursday', dropzone?.hoursOfOperation?.Thursday)}
                                    {dayOfWeek('Friday', dropzone?.hoursOfOperation?.Friday)}
                                    {dayOfWeek('Saturday', dropzone?.hoursOfOperation?.Saturday)}
                                    {dayOfWeek('Sunday', dropzone?.hoursOfOperation?.Sunday)}
                                </div>
                            </div>
                            <div className='pe-4'>
                                <Form.Group>
                                    <Form.Label as='small' className='d-flex fw-bold mb-0'>From</Form.Label>
                                    <Form.Control onChange={e => setDropzone({ ...dropzone, hoursOfOperation: {
                                        ...dropzone?.hoursOfOperation,
                                        from: e?.target?.value
                                    }})} 
                                        placeholder='8am'
                                        value={dropzone?.hoursOfOperation?.from || ''} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label as='small' className='fw-bold mb-0'>To</Form.Label>
                                    <Form.Control onChange={e => setDropzone({
                                        ...dropzone, hoursOfOperation: {
                                            ...dropzone?.hoursOfOperation,
                                            to: e?.target?.value
                                        }
                                    })}
                                        placeholder='3pm'
                                        value={dropzone?.hoursOfOperation?.to || ''} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Card.Body>
    </Card>;
}
export default DaysAndHoursOfOperation;