
import { Dropdown } from 'react-bootstrap';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { CountryEnum } from '../models';

interface ICountryDropdownProps {
    countryCode: CountryEnum,
    didSelectCountry: (val: CountryEnum) => void,
    disable?: boolean,

}

const CountryDropdown = ({ countryCode, didSelectCountry, disable }: ICountryDropdownProps) => {
    const convertCountryCodeToString = (code) => {
        switch(code) {
            case CountryEnum.AUSTRALIA: return 'Australia';
            case CountryEnum.BELGIUM: return 'BG';
            case CountryEnum.USA: return 'US';
            case CountryEnum.UK: return 'GB';
            case CountryEnum.DENMARK: return 'Denmark';
            case CountryEnum.MEXICO: return 'MX';
            case CountryEnum.NETHERLANDS: return 'NL';
            case CountryEnum.NORWAY: return 'NO';
            case CountryEnum.SPAIN: return 'ESP';
            case CountryEnum.SWEDEN: return 'SE';
            default: return 'Canada';
        }
    }

    //-----Pure JSX Functions-----//

    const dropdownItemJSX = (title: string, enumValue: CountryEnum, countryCode: string) => {
        return <Dropdown.Item as='small'
            className='align-items-center d-flex gap-3'
            onClick={() => didSelectCountry(enumValue)}>
            <span>{getUnicodeFlagIcon(countryCode)}</span>
            <span>{title}</span>
        </Dropdown.Item>;
    }

    return <Dropdown>
        <Dropdown.Toggle className='align-items-center d-flex gap-1' disabled={disable} id='country-dropdown' variant='outline-secondary'>
            {getUnicodeFlagIcon(convertCountryCodeToString(countryCode))}<small>{countryCode}</small>
        </Dropdown.Toggle>
        <Dropdown.Menu className='cursor'>
            {dropdownItemJSX('Australia', CountryEnum.AUSTRALIA, 'Australia')}
            {dropdownItemJSX('Belgium', CountryEnum.BELGIUM, 'BG')}
            {dropdownItemJSX('Canada', CountryEnum.CANADA, 'Canada')}
            {dropdownItemJSX('UK', CountryEnum.UK, 'GB')}
            {dropdownItemJSX('Denmark', CountryEnum.DENMARK, 'Denmark')}
            {dropdownItemJSX('Mexico', CountryEnum.MEXICO, 'MX')}
            {dropdownItemJSX('Netherlands', CountryEnum.NETHERLANDS, 'NL')}
            {dropdownItemJSX('Norway', CountryEnum.NORWAY, 'NO')}
            {dropdownItemJSX('Spain', CountryEnum.SPAIN, 'ESP')}
            {dropdownItemJSX('Sweden', CountryEnum.SWEDEN, 'SE')}
            {dropdownItemJSX('USA', CountryEnum.USA, 'US')}
        </Dropdown.Menu>
    </Dropdown>;
}
export default CountryDropdown;