import { useContext, useEffect, useRef, useState } from 'react';

import { Badge, Button, Card, Col, Collapse, Form, Row, Spinner } from 'react-bootstrap';
import { ArrowLeftCircleFill, Check2Circle, CheckCircleFill, EyeFill, GlobeAmericas, PencilSquare } from 'react-bootstrap-icons';
import { IDropzoneData } from '../models';
import { Link } from 'react-router-dom';

import ApiCalls from '../apiCalls';
import DropzoneControls from './DropzoneControls';
import Map from './Map';
import OnJumprunContext from '../context';

interface IDropzoneLocationsProps {
    changeDZAndGoToWaiver: () => void,
    didSaveNewDropzone: (val: any) => void,
    dropzone: IDropzoneData,
    dropzoneBeingEdited: IDropzoneData | null,
    dropzones: any[],
    setDropzone: (val: IDropzoneData) => void,
    setDropzoneBeingEdited: (val: IDropzoneData) => void
}

const DropzoneLocations = (props: IDropzoneLocationsProps) => {
    const apiCalls = new ApiCalls();
    const { darkMode, setShowNotificationModal } = useContext(OnJumprunContext);
    const { changeDZAndGoToWaiver, didSaveNewDropzone, dropzone, dropzoneBeingEdited, dropzones, setDropzone, setDropzoneBeingEdited } = props;
    const mapRef = useRef(null);

    const [addNew, setAddNew] = useState(dropzones?.length === 0 ? true : false);
    const [duplicates, setDuplicates] = useState([]);

    useEffect(() => {
        if(addNew) setDropzoneBeingEdited(new IDropzoneData());
    }, [addNew]);

    useEffect(() => {
        if(dropzoneBeingEdited?.name !== '') checkIfDropzoneNameIsAvailable();
    }, [dropzoneBeingEdited]);

    const cancelClicked = () => {
        addNew && setAddNew(false);
        setDropzoneBeingEdited(new IDropzoneData());
    }

    const checkIfDropzoneNameIsAvailable = async () => {
        const data = await apiCalls.getDropzonesByName(dropzoneBeingEdited?.name);
        setDuplicates(data);
    }

    const saveClicked = async () => {
        didSaveNewDropzone(dropzoneBeingEdited);
        setAddNew(false);
    }

    const updateClicked = () => {
        didSaveNewDropzone(dropzoneBeingEdited);
        setTimeout(() => {
            setDropzoneBeingEdited(null);
        }, 500);
    }

    //-----Pure JSX Functions-----//

    const addDropzoneButtonJSX = () => {
        return <div className='d-flex justify-content-end'>
            <Button className={['align-items-center d-flex gap-2 justify-content-center mt-2', (addNew || dropzoneBeingEdited?.id) ? 'opacity-0' : ''].join(' ')}
                disabled={(addNew) ? true : false} onClick={() => {
                    setAddNew(true);
                    setDropzoneBeingEdited(new IDropzoneData());
                }}
                variant={darkMode ? addNew ? 'outline-secondary' : 'outline-light' : addNew ? 'outline-secondary' : 'outline-dark'}>
                {addNew ? 'Adding new DZ...' : 'Add Another Dropzone'}<GlobeAmericas className='text-primary' />
            </Button>
        </div>;
    }

    const addOrEditDropzoneJSX = (l?) => {
        const dupeNameFound = duplicates?.length > 0 && duplicates?.findIndex((f, i) => f?.id !== dropzoneBeingEdited?.id && f?.lowercaseName === dropzoneBeingEdited?.name.toLowerCase()) > -1;
        const userIsEditing = (l && dropzoneBeingEdited?.id === l?.id);
        return <Collapse in={(!l && addNew) || userIsEditing}>
            <div>
                <Card className='border-secondary mb-3 shadow-lg'>
                    <Card.Body>
                        {dropzoneBeingEdited?.id && <Row>
                            <Col className='d-flex justify-content-between'>
                                <h4 className='fw-bold mb-4 underline'>{dropzoneBeingEdited?.name}</h4>
                                {loggedInOrSwitchJSX(dropzoneBeingEdited)}
                            </Col>
                        </Row>}
                        <Row>
                            <Col xs='12' lg='5'>
                                <div className='pb-5 position-relative'>
                                    <Form>
                                        <Form.Group className='mb-2'>
                                            <Form.Label as='p'
                                                autoFocus
                                                className={['align-items-center d-flex fw-bold justify-content-between mb-0 w-100', dupeNameFound ? 'text-danger' : ''].join(' ')}>
                                                <span>Dropzone Name <span className='text-danger'>*</span></span>
                                                {dupeNameFound ? <span>(already taken)</span> : <span className='fw-medium opacity-50 ps-2' style={{ fontSize: '12px' }}>(you can always change it later)</span>}
                                            </Form.Label>
                                            <Form.Control autoFocus
                                                className='border-secondary'
                                                isInvalid={dupeNameFound || dropzoneBeingEdited?.name?.length === 0}
                                                onChange={e => setDropzoneBeingEdited({ ...dropzoneBeingEdited, name: e?.target?.value })}
                                                onKeyUp={e => dropzoneBeingEdited?.name?.length > 0 && e?.key === 'Enter' && mapRef?.current?.focus()}
                                                placeholder='Location name..'
                                                type='text'
                                                value={dropzoneBeingEdited?.name || ''} />
                                        </Form.Group>
                                        <Map listOfListings={[]}
                                            mapRef={mapRef}
                                            setSelected={(e) => setDropzoneBeingEdited({ ...dropzoneBeingEdited, location: e })}
                                            selected={dropzoneBeingEdited?.location} />
                                    </Form>
                                </div>
                            </Col>
                            <Col>
                                <DropzoneControls changeDZAndGoToWaiver={changeDZAndGoToWaiver} dropzone={dropzoneBeingEdited} setDropzone={setDropzoneBeingEdited} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='align-items-center d-flex gap-2 mt-2'>
                                    <Button className='flex-fill' onClick={() => cancelClicked()} variant='outline-secondary'>
                                        Cancel
                                    </Button>
                                    <Button className='flex-fill fw-bold'
                                        disabled={dupeNameFound || !dropzoneBeingEdited || (dropzoneBeingEdited?.name === '' || !dropzoneBeingEdited?.location?.addressString)}
                                        onClick={() => dropzoneBeingEdited?.id ? updateClicked() : saveClicked()}
                                        variant={darkMode ? 'light' : 'primary'}>
                                        {dropzoneBeingEdited?.id ? 'Update Dropzone Info' : 'Save new dropzone'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Collapse>;
    }

    const linkJSX = (title: string) => {
        const waiverOrRes = (title === 'WAIVER LINK' ? 'signwaiver' : 'booktandem');
        return <p className='d-flex justify-content-between mb-1 w-100'>
            <small className={['opacity-75', darkMode ? 'text-warning' : 'text-dark'].join(' ')}>{title}</small>
            <Link className='opacity-75 d-flex justify-content-end' style={{ textDecoration: 'none'}} target='_blank' to={`../${dropzone?.id}/${waiverOrRes}`}>
                <small className='ps-3'>
                    {`onjumprun.com/${dropzone?.id}/${waiverOrRes}`}
                </small>
            </Link>
        </p>;
    }

    const listItemJSX = (l) => {
        const notDZBeingEdited = (dropzoneBeingEdited?.id && dropzoneBeingEdited?.id !== l?.id);
        return <Collapse in={dropzoneBeingEdited?.id !== l?.id}>
            <div>
                <Card className={['shadow-lg', notDZBeingEdited ? 'border-light opacity-25' : 'border-dark'].join(' ')}>
                    <Card.Body>
                        <div className='d-flex gap-3 justify-content-between'>
                            <div className='cursor position-relative'>
                                <h4 className={['mb-2', notDZBeingEdited ? 'fw-light' : 'fw-bold underline'].join(' ')} onClick={() => setDropzoneBeingEdited(l)}>
                                    {l?.name}
                                </h4>
                                {l?.id && l?.waiver?.dateSigned ? <>
                                    {linkJSX('Customer waiver link:')}
                                    {linkJSX('Tandem booking link:')}
                                </> : !dropzoneBeingEdited?.id && <Badge bg='danger' onClick={() => { setShowNotificationModal(true); setDropzone(l)}}>
                                    SETUP STILL NEEDED
                                </Badge>}
                            </div>
                            <div>
                                {loggedInOrSwitchJSX(l)}
                                <Button className='py-1 rounded w-100'
                                    disabled={addNew}
                                    onClick={() => setDropzoneBeingEdited(l)}
                                    size='sm'
                                    variant='dark'>
                                    <PencilSquare />
                                    <small className='ps-1'>Edit DZ</small>
                                </Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Collapse>;
    }

    const loggedInOrSwitchJSX = (l) => {
        return <div className='cursor pb-1'>
            {dropzone?.id === l?.id ? <Badge bg={l?.waiver?.dateSigned ? 'secondary' : 'danger'} className='d-flex gap-1 p-2 text-light'>
                <CheckCircleFill className='text-info' />
                Logged in
            </Badge> : <Badge bg={l?.waiver?.dateSigned ? 'info' : 'danger'}
                className={['d-flex gap-1 p-2', l?.waiver?.dateSigned ? 'text-dark' : 'text-light'].join(' ')}
                onClick={() => setDropzone(l)}>
                <EyeFill />
                Login to this DZ
            </Badge>}
        </div>;
    }

    const noDropzonesJSX = () => {
        return !addNew && <Card className={['border-secondary shadow-lg', addNew ? 'opacity-25' : ''].join(' ')}>
            <Card.Body>
                <div className='align-items-center d-flex gap-3 justify-content-between'>
                    <small className='fw-bold ps-3 text-danger underline'>- NO DROPZONES ADDED YET -</small>
                    <div>
                        <Button className='align-items-center d-flex fw-medium gap-2'
                            disabled={addNew}
                            onClick={() => setAddNew(true)}
                            variant={dropzones?.length === 0 ? 'danger' : 'outline-success'}>
                            Add New Dropzone<GlobeAmericas />
                        </Button>
                    </div>
                </div>
            </Card.Body>
        </Card>;
    }

    return <div>
        {dropzones?.length > 0 && dropzones.map((l, i) => <div className='mb-2' key={`onjumprun-dz-${l?.name}`}>
            {addOrEditDropzoneJSX(l)}
            {listItemJSX(l)}
        </div>)}
        <div>
            {addOrEditDropzoneJSX()}
            {dropzones?.length === 0 ? noDropzonesJSX() : addDropzoneButtonJSX()}
        </div>
    </div>;
}
export default DropzoneLocations;