import { useContext, useEffect, useState } from 'react';

import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill, PersonAdd, PersonFillAdd, PlusCircle } from 'react-bootstrap-icons';

import { IDropzoneData, ISoloJumperData } from '../models';
import JumperTypeDropdown from './JumperTypeDropdown';
import MMDDYYYY from './MMDDYYYY';
import OnJumprunContext from '../context';
import PhoneNumber from './PhoneNumber';
import Utilities from '../utils';

interface IAddNewJumperModalProps {
    autoOpen: boolean,
    didClickSave: () => void,
    dropzone: IDropzoneData,
    selectedJumper: ISoloJumperData,
    setAutoOpen: (val: boolean) => void,
    setSelectedJumper: (val: any) => void
}

const AddNewJumperModal = ({ autoOpen, didClickSave, dropzone, selectedJumper, setAutoOpen, setSelectedJumper }: IAddNewJumperModalProps) => {
    const { darkMode } = useContext(OnJumprunContext);
    const utils = new Utilities();

    const [activeInput, setActiveInput] = useState('First name');
    const [clickedOn, setClickedOn] = useState<any>([]);
    const [phoneTaken, setPhoneTaken] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (clickedOn?.findIndex(f => f === activeInput) === -1) setClickedOn([...clickedOn, activeInput]);
    }, [activeInput]);

    useEffect(() => {
        if (autoOpen) setShow(true);
    }, [autoOpen]);

    useEffect(() => {
        if (!show) {
            setSelectedJumper(new ISoloJumperData(dropzone?.id));
            setPhoneTaken(false);
            setClickedOn([]);
        }
    }, [show]);

    const didClickOpen = () => {
        setShow(true);
        setSelectedJumper(new ISoloJumperData(dropzone?.id));
    }

    const disableSaveButton = () => {
        if (selectedJumper?.emergencyContact == '') return true;
        if (selectedJumper?.firstName?.length < 3 || selectedJumper?.lastName?.length < 4) return true;
        if (selectedJumper?.MM == 0 || selectedJumper?.DD == 0 || selectedJumper?.YYYY == 0) return true;
        if (selectedJumper?.emergencyContact?.length <= 4 || utils?.stringHasNumber(selectedJumper?.emergencyContact)) return true;
        if (selectedJumper?.emergencyPhone === '' || selectedJumper?.emergencyPhone?.length !== 10) return true;
        return false;
    }

    const saveClicked = () => {
        didClickSave();
        setAutoOpen(false);
        setShow(false);
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        const disableSave = disableSaveButton();
        const eContactInvalid = ((activeInput === 'Emergency Contact' && selectedJumper?.emergencyContact?.length <= 4)) || (clickedOn?.find(f => f === 'Emergency Contact') && selectedJumper?.emergencyContact?.length < 4) || utils?.stringHasNumber(selectedJumper?.emergencyContact);
        const fNameInvalid = (activeInput === 'First name' && selectedJumper?.firstName?.length < 3) || utils?.stringHasNumber(selectedJumper?.firstName) || (selectedJumper?.firstName?.length < 3 && clickedOn?.find(f => f === 'First name'));
        const isFat = (selectedJumper?.weight < 50 || selectedJumper?.weight > 300);
        const lNameInvalid = (activeInput === 'Last name' && selectedJumper?.lastName?.length < 4) || utils?.stringHasNumber(selectedJumper?.lastName) || (selectedJumper?.lastName?.length < 4 && clickedOn?.find(f => f === 'Last name'));
        const showUSPA = selectedJumper?.jumperType !== 'Student';
        const weightInvalid = activeInput === 'Weight' && (isFat) || (isFat && clickedOn?.find(f => f === 'Weight'));

        return <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title className='align-items-center d-flex gap-2 mb-0 underline'>
                    <span>Add new jumper</span>
                    <PersonAdd className={`text-${darkMode ? 'warning' : 'primary'}`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='px-3'>
                    <div className='d-flex gap-2'>
                        <div className='flex-fill'>
                            <Form.Group className='pb-2'>
                                <Form.Label as='small' className='fw-bold'>FIRST NAME</Form.Label>
                                <Form.Control autoFocus
                                    isInvalid={fNameInvalid}
                                    isValid={!fNameInvalid && clickedOn?.find(f => f === 'First name')}
                                    onChange={(e) => setSelectedJumper({ ...selectedJumper, firstName: e.target.value })}
                                    onFocus={() => setActiveInput('First name')}
                                    type='text'
                                    value={selectedJumper?.firstName || ''} />
                            </Form.Group>
                        </div>
                        <div className='flex-fill'>
                            <Form.Group className='pb-2'>
                                <Form.Label as='small' className='fw-bold'>LAST NAME</Form.Label>
                                <Form.Control isInvalid={lNameInvalid}
                                    isValid={!lNameInvalid && clickedOn?.find(f => f === 'Last name')}
                                    onChange={(e) => setSelectedJumper({ ...selectedJumper, lastName: e.target.value })}
                                    onFocus={() => setActiveInput('Last name')}
                                    type='text'
                                    value={selectedJumper?.lastName || ''} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='align-items-center d-flex gap-2'>
                        <Form.Group className='flex-fill'>
                            <PhoneNumber activeInput={activeInput}
                                clickedOn={clickedOn}
                                fontLight={true}
                                field='phone'
                                newJumperInfo={selectedJumper}
                                phoneIsTaken={phoneTaken}
                                setActiveInput={setActiveInput}
                                setNewJumperInfo={setSelectedJumper}
                                setPhoneIsTaken={setPhoneTaken} />
                        </Form.Group>
                        <Form.Group className='pb-2'>
                            <Form.Label
                                as='small'
                                className={['fw-bold mb-2', selectedJumper?.weight > 300 ? 'text-danger' : ''].join(' ')}>
                                WEIGHT <span className='opacity-50' style={{ fontSize: '9px' }}>({selectedJumper?.weight > 300 ? 'TOO HEAVY' : 'LBS'})</span>
                            </Form.Label>
                            <Form.Control isInvalid={weightInvalid}
                                isValid={!weightInvalid && clickedOn?.find(f => f === 'Weight')}
                                onChange={(e) => setSelectedJumper({ ...selectedJumper, weight: e?.target?.value })}
                                onFocus={() => setActiveInput('Weight')}
                                placeholder='lbs'
                                style={{ width: '120px' }}
                                type='number'
                                value={selectedJumper?.weight || ''} />
                        </Form.Group>
                    </div>
                    <div>
                        <MMDDYYYY bold={true}
                            clickedOn={clickedOn}
                            newReservation={selectedJumper}
                            setActiveInput={setActiveInput}
                            setNewReservation={setSelectedJumper} />
                    </div>
                    <div className='align-items-center d-flex gap-2 pb-2'>
                        <div className='flex-fill'>
                            <Form.Group>
                                <div>
                                    <Form.Label as='small' className='fw-bold mb-1'>EMERGENCY CONTACT</Form.Label>
                                </div>
                                <Form.Control isInvalid={eContactInvalid}
                                    isValid={!eContactInvalid && clickedOn?.find(f => f === 'Emergency Contact')}
                                    onChange={(e) => setSelectedJumper({ ...selectedJumper, emergencyContact: e?.target?.value })}
                                    onFocus={() => setActiveInput('Emergency Contact')}
                                    type='text'
                                    value={selectedJumper?.emergencyContact || ''} />
                            </Form.Group>
                        </div>
                        <div>
                            <PhoneNumber activeInput={activeInput}
                                clickedOn={clickedOn}
                                fontLight={true}
                                field='emergencyPhone'
                                newJumperInfo={selectedJumper}
                                phoneIsTaken={phoneTaken}
                                setActiveInput={setActiveInput}
                                setNewJumperInfo={setSelectedJumper}
                                setPhoneIsTaken={setPhoneTaken} />
                        </div>
                    </div>
                    {reserveRepackAndJumperTypeJSX(showUSPA)}
                    {uspaSectionJSX(showUSPA)}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className='fw-light' variant={darkMode ? 'dark' : 'light'} onClick={() => {
                    setAutoOpen(false);
                    setShow(false);
                }}>
                    Cancel
                </Button>
                <Button className='align-items-center d-flex fw-medium'
                    disabled={disableSave}
                    onClick={() => saveClicked()}
                    variant={disableSave ? 'outline-secondary' : darkMode ? 'light' : 'primary'}>
                    {disableSave ? 'Missing information' : 'Save jumper info'}<PersonFillAdd className='ms-1' />
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    const reserveRepackAndJumperTypeJSX = (showUSPASection) => {
        return <div className='d-flex gap-2 justify-content-between pb-2'>
            <JumperTypeDropdown disabled={false}
                dontShowTandemItem={true}
                hideTandy={false}
                jumperType={selectedJumper?.jumperType}
                setJumperType={(e) => setSelectedJumper({ ...selectedJumper, jumperType: e })} />
            <Form.Group className='flex-fill'>
                <Form.Label as='small' className={['fw-medium mb-1', !showUSPASection ? 'opacity-25' : ''].join(' ')}>
                    Reserve Repack Date
                </Form.Label>
                <Form.Control disabled={!showUSPASection}
                    onChange={(e) => setSelectedJumper({ ...selectedJumper, reserveRepackDate: e?.target?.value })}
                    placeholder='MM/DD/YYYY'
                    type='date'
                    value={selectedJumper?.reserveRepackDate || ''} />
            </Form.Group>
        </div>;
    }

    const uspaSectionJSX = (showUSPASection) => {
        return <div className='d-flex gap-3 pb-2'>
            <Form.Group className='flex-fill'>
                <Form.Label as='small' className={['fw-medium mb-1', !showUSPASection ? 'opacity-25' : ''].join(' ')}>
                    USPA Membership #
                </Form.Label>
                <Form.Control disabled={!showUSPASection}
                    onChange={(e) => setSelectedJumper({ ...selectedJumper, uspaNumber: e?.target?.value })}
                    placeholder='...'
                    value={selectedJumper?.uspaNumber || ''} />
            </Form.Group>
            <Form.Group className='flex-fill'>
                <Form.Label as='small' className={['fw-medium mb-1', !showUSPASection ? 'opacity-25' : ''].join(' ')}>
                    USPA Exp Date
                </Form.Label>
                <Form.Control disabled={!showUSPASection}
                    onChange={(e) => setSelectedJumper({ ...selectedJumper, uspaExp: e?.target?.value })}
                    placeholder='MM/DD/YYYY'
                    type='date'
                    value={selectedJumper?.uspaExp || ''} />
            </Form.Group>
        </div>;
    }

    return <div className='align-items-center d-flex gap-3'>
        <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip' style={{ fontSize: '12px' }}>
            <p className='mb-0 p-2 text-center'>
                This list comes from fun jumpers who register in the OnJumprun iOS/Android App (launching Winter 2024)
            </p>
        </Tooltip>}>
            <h5 className='cursor mb-0'>
                <InfoCircleFill className={darkMode ? 'light' : 'dark'} />
            </h5>
        </OverlayTrigger>
        <Button className='align-items-center d-flex fw-bold gap-2'
            disabled={!dropzone?.id}
            hidden={autoOpen}
            onClick={() => didClickOpen()}
            variant={darkMode ? 'outline-light' : 'primary'}>
            <span>Add solo jumper</span> <PlusCircle />
        </Button>
        {modalJSX()}
    </div>;
}
export default AddNewJumperModal;