
import { getAuth } from 'firebase/auth';
import { addDoc, doc, collection, getDoc, getDocs, getFirestore, updateDoc, where, query } from 'firebase/firestore';

import { IAircraftData, IDropzoneData, IEmployeeData, IJumperData, ILoadData, IPilotData, ISignWaiverData, ISoloJumperData, ISoloPaymentData, ITandemStudentData } from './models';

class ApiCalls {
    auth = getAuth();
    db = getFirestore();

    async addAircraft(data: IAircraftData) {
        return addDoc(collection(this.db, 'aircraft'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    async addContactForm(data: { message: string, name: string }) {
        return addDoc(collection(this.db, 'contact_form'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addDropzone(data: IDropzoneData) {
        return addDoc(collection(this.db, 'dropzones'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    async addEmployee(data: IEmployeeData) {
        return addDoc(collection(this.db, 'employees'), { ...data, active: true }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addLoad(data: ILoadData) {
        return addDoc(collection(this.db, 'loads'), { 
            ...data, 
            date: new Date(data?.date.getTime() + data.call * 60000),
            dateCreated: new Date(),
        }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addPayment(data: ISoloPaymentData) {
        return addDoc(collection(this.db, 'payments'), { ...data, active: true }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addPilot(data: IPilotData) {
        return addDoc(collection(this.db, 'pilots'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addSignWaiver(data: ISignWaiverData) {
        return addDoc(collection(this.db, 'sign_waiver'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addSoloJumper(data: ISoloJumperData) {
        return addDoc(collection(this.db, 'solo_jumpers'), { ...data }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    addSubscription(data: any) {
        return addDoc(collection(this.db, 'subscriptions'), {
            ...data, active: true, dateCreated: new Date() 
        }).then((ref) => {
            return ref?.id;
        }).catch(() => {
            return false;
        });
    }

    addTandemStudent(data: ITandemStudentData) {
        return addDoc(collection(this.db, 'tandem_reservations'), {
            ...data, active: true, dateCreated: new Date() 
        }).then((ref) => {
            return ref?.id;
        }).catch(() => {
            return false;
        });
    }

    async checkIfPhoneNumberIsTaken(newPhoneNumber: string) {
        const q1 = query(collection(this.db, 'solo_jumpers'), where('phone', '==', newPhoneNumber));
        const querySnapshot1 = await getDocs(q1);

        const q2 = query(collection(this.db, 'solo_jumpers'), where('phone', '==', newPhoneNumber));
        const querySnapshot2 = await getDocs(q2);

        return querySnapshot1?.size == 0 && querySnapshot2?.size == 0 ? false : true;
    }

    async getAircraftByDropzoneId(id: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'aircraft'), where('dropzoneId', '==', id));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr.filter(a => a?.active);
        }
    }

    async getDropzoneByDropzoneId(dzId: string) {
        const document = doc(this.db, 'dropzones', dzId);
        const docSnap = await getDoc(document);
        return docSnap?.exists() ? { ...docSnap.data(), id: docSnap.id } : null;
    }

    async getDropzonesByAuthId(id: any) {
        const arr: IDropzoneData[] = [];
        if(!id || id == 0) return;
        const q1 = query(collection(this.db as any, 'dropzones'), where('ownerUID', '==', id));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc: any) => {
                let obj = new IDropzoneData();
                obj = doc.data();
                obj.id = doc.id;
                arr.push(obj);
            });
            return arr.sort((a, b) => a?.dateCreated > b?.dateCreated ? 1 : -1);
        }
    }

    async getDropzonesByName(name: string) {
        const arr: any[] = [];
        if(!name || name === '') return [];
        const q1 = query(collection(this.db, 'dropzones'), where('lowercaseName', '==', name.toLowerCase()));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr;
        }
    }

    async getDropzonesSignedWaivers(id: string) {
        const arr: ISignWaiverData[] = [];
        const q1 = query(collection(this.db, 'sign_waiver'), where('dropzoneId', '==', id));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data() as any, id: doc.id }));
            return arr;
        }
    }

    async getEmployeesByDropzoneId(id: string) {
        const arr: IEmployeeData[] = [];
        const q1 = query(collection(this.db, 'employees'), where('dropzoneId', '==', id));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data(), id: doc.id } as any));
            return arr.sort((a, b) => a?.lastName > b?.lastName ? 1 : -1);
        }
    }

    async getJumperByUID(uid: string, dzId: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'solo_jumpers'), where('id', '==', uid));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr.filter(a => a?.dropzoneId == dzId);
        }
    }

    async getJumpersByDropzoneId(id: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'solo_jumpers'), where('dropzoneId', '==', id));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            console.log('arr is', arr)
            return arr.sort((a, b) => a?.name > b?.name ? 1 : -1);
        }
    }

    async getLoadsByDropzoneId(id: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'loads'), where('dropzoneId', '==', id));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr.sort((a, b) => a?.dateCreated > b?.dateCreated ? 1 : -1);
        }
    }

    async getPaymentsByDropzoneId(dzId: string) {
        const arr: ISoloPaymentData[] = [];
        const q1 = query(collection(this.db, 'payments'), where('dropzoneId', '==', dzId));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data() as any, id: doc.id }));
            return arr;
        }
    }

    async getPilotsByDropzoneId(dzId: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'pilots'), where('dropzoneId', '==', dzId));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr.sort((a, b) => a?.name > b?.name ? 1 : -1);
        }
    }
    
    async getSignedWaiverByDropzoneIdAndPhone(id: string, phoneNumber) {
        const arr: ISignWaiverData[] = [];
        const q1 = query(collection(this.db, 'sign_waiver'), where('dropzoneId', '==', id), where('phone', '==', phoneNumber));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data() as any, id: doc.id }));
            return arr;
        }
    }

    async getSubscriptionByUID(uid: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'subscriptions'), where('uid', '==', uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr;
        }
    }

    async getTandemReservationsByDate(date: Date) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'tandem_reservations'), where('dateWithoutTime', '==', date));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr;
        }
    }

    async getTandemReservationsByDropzoneId(id: string) {
        const arr: ITandemStudentData[] = [];
        const q1 = query(collection(this.db, 'tandem_reservations'), where('dropzoneId', '==', id));
        const snapshot = await getDocs(q1);
        if (snapshot.size == 0) {
            return [];
        } else {
            snapshot.forEach((doc) => arr.push({ ...doc.data() as any, id: doc.id }));
            return arr.sort((a, b) => a?.dateCreated > b?.dateCreated ? 1 : -1);
        }
    }

    async getWaiverByPhoneNumber(phone: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db, 'sign_waiver'), where('phone', '==', phone));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
            return arr;
        }
    }

    sendConfirmationEmail(data: ITandemStudentData, dropzone: IDropzoneData, numParticipants: number) {
        return addDoc(collection(this.db, 'mail'), {
            to: ['rolandandrewforbes@gmail.com'],
            message: {
                subject: `Confirmation - ${dropzone?.name} Reservation`,
                text: 'Woot woot! Its actually happening! Youre all set to go skydiving! ',
                html: `<h2>Great news, you're going skydiving!<br/><br/>
                <b>Where: </b> ${dropzone?.location?.addressString}<br/>
                <b>When: </b> ${new Date(data?.date).getHours()}:${new Date(data?.date).getMinutes()} ${new Date(data?.date).toLocaleDateString()}<br/>
                <b>Group Size: </b> ${numParticipants}<br/>
                <b>Amount Paid: </b> $${data?.amountPaid}<br/></h2>`
            }
        }).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    async updateAircraft(id: string, data: any) {
        const l = doc(this.db, 'aircraft', id);
        await updateDoc(l, data);
        return true;
    }

    async updateDropzone(id: string, data: any) {
        const l = doc(this.db, 'dropzones', id);
        await updateDoc(l, data);
        return true;
    }

    async updateEmployee(id: string, data: any) {
        const l = doc(this.db, 'employees', id);
        await updateDoc(l, data);
        return true;
    }

    async updateJumper(id: string, data: IJumperData) {
        const j = doc(this.db, 'solo_jumpers', id);
        await updateDoc(j, data as any);
        return true;
    }

    async updateLoad(id: string, data) {
        const l = doc(this.db, 'loads', id);
        await updateDoc(l, data as any);
        return true;
    }

    updatePayment(id: string, data: ISoloPaymentData) {
        const a = doc(this.db, 'payments', id);
        updateDoc(a, data as any);
        return true;
    }   

    updatePilot(id: string, data: any) {
        const a = doc(this.db, 'pilots', id);
        updateDoc(a, data);
        return true;
    }

    async updateTandemReservation(data: ITandemStudentData) {
        const t = doc(this.db, 'tandem_reservations', data.id);
        await updateDoc(t, data as any);
        return true;
    }
}
export default ApiCalls;