import { useEffect, useState } from 'react';

import { Collapse, Toast, ToastContainer } from 'react-bootstrap';

const Toastify = ({ message, position, setMessage, variant }) => {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        showToastWhenMessageUpdated();
    }, [message]);

    useEffect(() => {
        closeToastAutomaticallyAfterAFewSeconds();
    }, [showToast]);

    const closeToastAutomaticallyAfterAFewSeconds = () => {
        if(showToast) {
            setTimeout(() => {
                setShowToast(false);
                setMessage(null);
            }, 7111);
        }
    }

    const showToastWhenMessageUpdated = () => {
        if(message && message !== '') setShowToast(true);
    }
   
    //-----Pure JSX Functions-----//

    return <ToastContainer className='p-3' position={position} style={{ zIndex: 1 }}>
        <Toast animation={Collapse} bg={variant} show={showToast}>
            <Toast.Body style={{ color: '#fff '}}>
                <>
                    <h5 className='mb-1'>Success</h5>
                    <p className='fw-medium mb-0'>{message}</p>
                </>
            </Toast.Body>
        </Toast>
    </ToastContainer>
}
export default Toastify;