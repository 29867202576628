import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import Logo from './Logo';
//@ts-ignore
import mac from './onjumprunmac.png';

const OnJumprunPage = ({ }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document?.documentElement?.setAttribute('data-bs-theme', 'dark');
    }, []);

    //-----Pure JSX Functions-----//

    const onjumprunLogoJSX = () => {
        return <div className='d-flex justify-content-center'>
            <h1 style={{ fontSize: '3rem' }}>
                <span className='fw-light swellMe'>on</span>
                <span className='fw-medium'>Jumprun</span>
            </h1>
        </div>;
    }

    return <>
        <div className='vh-95'>
            <Header activePage='HOME' />
            {isLoading ? <div className='fadeInSlow fixed-center'>
                <Logo isLoading={isLoading} xl={true} />
            </div> : <Row className='fadeIn'>
                <Col className='my-5 pb-5'>
                    {onjumprunLogoJSX()}
                </Col>
            </Row>}
            <Row className='fadeIn'>
                <Col className='align-items-center d-flex gap-5 justify-content-center px-5'>
                    {!isLoading && <div className='fadeIn'>
                        <h1 className='fw-light mb-0 no-word-wrap' style={{ fontSize: '4rem' }}>The DZO App</h1>
                        <h5 className='no-word-wrap' style={{ fontSize: '1.3rem', }}>
                            <span>A turnkey solution for skydive operators</span>
                            <br />
                            <span>Only <span className='shimmer'>$99</span>/month</span>
                        </h5>
                        <p className='fw-bold mb-4 no-word-wrap opacity-50'>
                            Book tandem reservations, manage fun jumpers, and <br /> run your  whole dropzone OnJumprun.com
                        </p>
                        <Button className='fw-bold opacity-75 px-3' onClick={() => navigate('/login')} variant='light'>
                            Start sending loads..
                        </Button>
                    </div>}
                    <div>
                        <img alt='onjumprun-desktop'
                            className={isLoading ? null : 'fadeIn'}
                            onLoad={() => setIsLoading(false)} src={mac}
                            style={{ maxWidth: '640px', display: isLoading ? 'none' : 'block', width: '100%' }} />
                    </div>
                </Col>
            </Row>
        </div>
        <Footer />
    </>;
};
export default OnJumprunPage;