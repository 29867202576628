
import { XCircle, XCircleFill } from 'react-bootstrap-icons';

const HoverXCircle = () => {

    //-----Pure JSX Functions-----//

    return <>
        <XCircle className='cursor hideOnHover text-danger' />
        <XCircleFill className='cursor showOnHover text-danger' />
    </>;
}
export default HoverXCircle;