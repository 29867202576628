import { useEffect } from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const PlacesAutocomplete = ({ address, mapRef, setSelected, selected }) => {
    const { clearSuggestions, value, setValue, suggestions: { status, data } } = usePlacesAutocomplete();

    useEffect(() => {
        if(address?.addressString) handleSelect(address);
    }, [address]);

    useEffect(() => {
        if(selected == '') setValue('');
    }, [selected]);

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        let addressString = '';
        if(results?.length > 0 && results[0]?.address_components) {
            results[0].address_components.forEach(r => {
                if(r?.types?.includes('street_number') || r?.types?.includes('route') || r?.types.includes('locality') ||
                r?.types?.includes('administrative_area_level_1') || r?.types?.includes('postal_code')) {
                    addressString = addressString + ' ' + r?.short_name
                }
            })
        }
        setSelected({ addressString, lat, lng, addressArray: results[0].address_components });
    };

    return <div className='d-flex gap-2 justify-content-between mb-2'>
        <div className='flex-fill'>
            <Dropdown>
                <Form.Group>
                    <Form.Label as='p' className='d-flex fw-bold mb-0'>
                        Address <span className='ps-1 text-danger'>*</span>
                    </Form.Label>
                    <Dropdown.Toggle className='d-flex fw-bold p-0 w-100' variant='outline-secondary'>
                        <Form.Control onChange={(e) => setValue(e.target.value)} 
                            placeholder='420 Blue Skies Blvd...'
                            style={{ overflow: 'hidden' }}
                            ref={mapRef}
                            value={value?.addressString ?? value} />
                    </Dropdown.Toggle>
                </Form.Group>
                {data?.length > 0 && <Dropdown.Menu  className='w-100' variant='dark'>
                    {status === 'OK' && data.map(({ place_id, description }) => <Dropdown.Item as='small'
                        className='cursor' 
                        key={place_id} 
                        onClick={() => place_id ? handleSelect(description) : null}>
                        {description}
                    </Dropdown.Item>)}
                </Dropdown.Menu>}
            </Dropdown>
        </div>
    </div>;
}
export default PlacesAutocomplete;
