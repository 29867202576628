
import { useContext } from 'react';

import { Dropdown, Form } from 'react-bootstrap';

import OnJumprunContext from '../context';

const JumperTypeDropdown = ({ disabled, dontShowTandemItem, hideTandy, jumperType, setJumperType }) => {
    const { darkMode } = useContext(OnJumprunContext);

    //-----Pure JSX Functions-----//

    return <div>
        <Form.Group>
            {dontShowTandemItem && <div>
                <Form.Label as='small' className='fw-medium mb-1'>Jumper type</Form.Label>
            </div>}
            <Dropdown>
                <Dropdown.Toggle className={['px-3', dontShowTandemItem ? '' : 'py-0'].join(' ')}
                    disabled={disabled || false}
                    variant={darkMode ? 'outline-light' : jumperType === 'Tandem' ? 'dark' : 'outline-dark'}
                    size='size'>
                    {jumperType || 'Select type'}
                </Dropdown.Toggle>
                <Dropdown.Menu variant='dark'>
                    <Dropdown.Item onClick={() => setJumperType('Student')}>Student</Dropdown.Item>
                    <Dropdown.Item onClick={() => setJumperType('Sport Jumper')}>Sport Jumper</Dropdown.Item>
                    <Dropdown.Item onClick={() => setJumperType('Instructor')}>Instructor</Dropdown.Item>
                    {!dontShowTandemItem && !hideTandy && <Dropdown.Item onClick={() => setJumperType('Tandem')}>Tandem</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    </div>;
}
export default JumperTypeDropdown;