import { useContext, useEffect, useState } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { AirplaneEngines, PlusCircle } from 'react-bootstrap-icons';

import { IPilotData } from '../models';
import OnJumprunContext from '../context';

const AddNewPilotModal = ({ autoOpen, didSavePilot, didUpdatePilotInfo, dropzone, selectedPilot, setAutoOpen, setSelectedPilot }) => {
    const { darkMode } = useContext(OnJumprunContext);

    const [licenseNumber, setLicenseNumber] = useState('');
    const [name, setName] = useState('');
    const [show, setShow] = useState(false);
    const [weight, setWeight] = useState(0);

    useEffect(() => {
        populateModal();
    }, [autoOpen]);

    useEffect(() => {
        if(!show) {
            setName('');
            setWeight(0);
            setLicenseNumber('');
            setSelectedPilot(null);
        }
    }, [show]);
    
    const disableSaveButton = () => {
        if(name === '') return true;
        if(isNaN(weight)) return true;
        if(licenseNumber === '') return true;
        return false;
    }

    const populateModal = () => {
        if(autoOpen) {
            setName(selectedPilot?.name);
            setWeight(selectedPilot?.weight);
            setLicenseNumber(selectedPilot?.licenseNumber);
            setShow(true);
        }
    }

    const saveClicked = (isActive?) => {
        if(selectedPilot) {
            didUpdatePilotInfo({ name, licenseNumber, weight, active: isActive !== undefined ? isActive : true, id: selectedPilot?.id });
        } else {
            const newPilotData: IPilotData = { active: true, dateAdded: new Date(), dropzoneId: dropzone?.id, name, licenseNumber, weight };
            didSavePilot(newPilotData);
        }
        setAutoOpen(false);
        setShow(false);
    }

    //-----Pure JSX Functions-----//

    const modalJSX = () => {
        const saveBtnDisabled = disableSaveButton();
        return <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title className='align-items-center d-flex gap-2 mb-0'>
                    {selectedPilot ? <span>Edit pilot information</span> : <span>Add new pilot</span>}
                    <AirplaneEngines />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form className='px-3'>
                    <div className='d-flex gap-3 pb-2'>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' autoFocus className='fw-medium mb-2'>Pilot Name</Form.Label>
                                <Form.Control onChange={(e) => setName(e.target.value)}
                                    size='sm'
                                    value={name} />
                            </Form.Group>
                        </div>
                        <div className='flex-fill'>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>License Number</Form.Label>
                                <Form.Control onChange={(e) => setLicenseNumber(e.target.value)}
                                    size='sm'
                                    value={licenseNumber} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label as='small' className='fw-medium mb-2'>Weight</Form.Label>
                                <Form.Control max={300}
                                    min={0}
                                    onChange={(e) => setWeight(e.target.value as any)}
                                    placeholder='lbs'
                                    style={{ width: '70px' }}
                                    size='sm'
                                    type='number'
                                    value={weight} />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className='p-2'>
                <div className='align-items-center d-flex gap-1 justify-content-between w-100'>
                    <div>
                        <Button hidden={!selectedPilot?.id} onClick={() => saveClicked(false)} size='sm' variant='danger'>
                            Delete pilot
                        </Button>
                    </div>
                    <div className='d-flex gap-1'>
                        <div>
                            <Button className='fw-light' onClick={() => setShow(false)} size='sm' variant={darkMode ? 'dark' : 'light'}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button className='align-items-center d-flex fw-medium'
                                disabled={saveBtnDisabled} 
                                onClick={() => saveClicked()}
                                size='sm'
                                variant={saveBtnDisabled ? 'danger' : darkMode ? 'light' : 'primary'}>
                                Save pilot<PlusCircle className='ms-1' />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }

    return <>
        <Button className='align-items-center d-flex fw-bold gap-2'
            disabled={!dropzone?.id} 
            onClick={() => setShow(true)}
            variant={darkMode ? 'outline-light' : 'primary'}>
            <span>Add pilot</span> <PlusCircle />
        </Button>
        {modalJSX()}
    </>;
}
export default AddNewPilotModal;